import React from 'react';
import {publicUrl} from '../../../common/helpers';
import {Button, Form, Input} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';

class TaxCode extends React.PureComponent {
  formEl = React.createRef();

  submit = () => {
    this.formEl.current.submit();
  };

  finish = (values) => {
    this.props.setStep('finish', values)();
  };

  render() {
    const {setStep, data} = this.props;
    return (
      <div className="step two-col tax-code">
        <div className="col col-img">
          <figure
            style={{backgroundImage: `url(${publicUrl('/assets/images/banner/finance.svg')})`}}
          />
        </div>
        <div className="col col-main">
          <div className="--title">Mã số thuế làm việc</div>
          <div className="--content">
            <div>
              Thiết lập mã số thuế làm việc giúp tự động phát hiện công ty của bạn trong file hóa đơn, tránh sai sót khi
              tạo hóa đơn đầu vào/ra.
            </div>
            <Form onFinish={this.finish} className="common-form" initialValues={data} ref={this.formEl}>
              <Form.Item
                label="Nhập mã số thuế làm việc của bạn"
                name="taxCode"
                style={{marginBottom: 10}}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập mã số thuế',
                  },
                ]}
              >
                <Input placeholder="Mã số thuế"/>
              </Form.Item>
              <Form.Item
                label="Nhập tên công ty"
                name="companyName"
                style={{marginBottom: 10}}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập tên công ty',
                  },
                ]}
              >
                <Input placeholder="Tên công ty"/>
              </Form.Item>
            </Form>
          </div>
          <div className="--footer two-button">
            <Button onClick={setStep('view-invoice')}>
              <LeftOutlined/> Quay lại
            </Button>
            <Button type="primary" onClick={this.submit}>
              Tiếp tục <RightOutlined/>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default TaxCode;
