import React from 'react';
import qs from 'qs';
import {Button, message, Result, Spin} from 'antd';
import {loginGoogleAPI} from '../../../api/google';
import './GoogleAuth.scss';
import {getErrorMessage} from '../../../common/helpers';

class GoogleAuth extends React.PureComponent {
  state = {
    error: null,
  };

  componentDidMount() {
    this.handleLogin();
  }

  handleLogin = () => {
    const params = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
    if (!params.code) {
      message.error(`Liên kết lỗi!`);
      return;
    }
    loginGoogleAPI(params).then(() => {
      message.info(`Liên kết thành công!`);
      localStorage.setItem('google-auth-result', '1');
      setTimeout(() => {
        window.close();
      }, 1000);
    }).catch((error) => {
      if (error?.response?.data?.metadata) {
        this.setState({
          error: error.response.data.metadata,
        });
      }
      localStorage.setItem('google-auth-result', '-1');
      message.error(getErrorMessage(error));
    });
  };

  handleClose = () => {
    window.close();
  };

  render() {
    const {error} = this.state;
    return (
      <div className="google-auth">
        {
          !error &&
          <Spin tip="Vui lòng chờ" className="spinner"/>
        }
        {
          error &&
          <Result
            status="error"
            title="Liên kết lỗi"
            subTitle={error?.message}
            extra={[
              <Button type="default" onClick={this.handleClose}>
                Đóng lại
              </Button>,
            ]}
          />
        }
      </div>
    );
  }
}

export default GoogleAuth;
