import React from 'react';
import PropTypes from 'prop-types';
import sanitizeHTML from 'sanitize-html';

const sanitize = (html) => {
  return sanitizeHTML(html, {
    allowedTags: sanitizeHTML.defaults.allowedTags.concat(['img']),
    allowedAttributes: {
      ...sanitizeHTML.defaults.allowedAttributes,
      '*': ['style'],
    }
  });
};

class HTMLView extends React.PureComponent {
  viewEl = React.createRef();

  componentDidMount() {
    if (this.viewEl.current) {
      this.viewEl.current.addEventListener('click', this.onClick);
    }
    this.setLinkTarget();
  }

  componentWillMount() {
    if (this.viewEl.current) {
      this.viewEl.current.removeEventListener('click', this.onClick);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.html !== this.props.html) {
      this.setLinkTarget();
    }
  }

  isExternal = (target) => {
    return !target.href.match(/^mailto:/) && (target.hostname !== window.location.hostname);
  };

  onClick = (event) => {
    const target = event.target;
    if (`${target.tagName}`.toUpperCase() === 'A') {
      const isExternal = this.isExternal(target);
      if (isExternal) {
        const r = window.confirm('Đây là một liên kết bên ngoài, chúng tôi không chịu trách nhiệm nếu bạn click vào link này. Bạn có chắc muốn tiếp tục?');
        if (!r) {
          event.preventDefault();
        }
      }
    }
  };

  setLinkTarget = () => {
    if (this.viewEl.current) {
      const links = this.viewEl.current.getElementsByTagName('A');
      if (links?.length) {
        for (let i = 0; i < links.length; i++) {
          if (this.isExternal(links[i])) {
            links[i].setAttribute('target', '_blank');
          }
        }
      }
    }
  };

  render() {
    const {html, tagName: Tag, className} = this.props;
    return (
      <Tag
        className={className}
        dangerouslySetInnerHTML={{__html: sanitize(html)}}
        ref={this.viewEl}
      >
      </Tag>
    )
  }
}

HTMLView.propTypes = {
  html: PropTypes.string,
  className: PropTypes.string,
  tagName: PropTypes.string
};

HTMLView.defaultProps = {
  className: 'html-view',
  tagName: 'div'
};

export default HTMLView;
