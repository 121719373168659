import React, {Component} from 'react';
import PropTypes from 'prop-types';
import JSZip from 'jszip';
import Axios from 'axios';
import {CloseCircleOutlined, InboxOutlined} from '@ant-design/icons';
import {Button, message, Modal, Result, Spin, Typography, Upload} from 'antd';
import {getDownloadFileURL, parseXMLFileAPI, uploadXMLFileAPI} from '../../../api/files';
import {byteTo, formatCurrency, getErrorMessage, getFileExt, getMimeTypeOfFile} from '../../../common/helpers';
import {checkDuplicateInvoicesAPI} from '../../../api/invoices';
import {ALLOWED_UPLOAD_FILE_EXTENSIONS, MAX_UPLOAD_FILE_SIZE, NUMBER_FORMAT} from '../../../common/constants/common';
import {detectInvoiceNumberFormat, formatInvoiceNumber} from './utils';
import {getEmailDetailAPI} from '../../../api/emails';

const {Paragraph, Text} = Typography;
const {Dragger} = Upload;

class UploadXMLFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      file: null,
    };
  }

  componentDidMount() {
    this.importFromEmail();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.emailId !== this.props.emailId) {
      this.importFromEmail();
    }
  }

  importFromEmail = () => {
    const {emailId} = this.props;
    if (!emailId) {
      return;
    }
    this.setState({
      isFetching: true,
    });
    this.importFromEmailAsync().then(() => {
      this.setState({
        isFetching: false,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isFetching: false,
      });
    });
  };

  importFromEmailAsync = async () => {
    const {emailId, onSuccess} = this.props;
    const {data: email} = await getEmailDetailAPI(emailId);
    if (!email?.attachments?.length) {
      return;
    }
    let xmlFile = email.attachments.find(item => (item.ext === 'xml' || item.ext === 'inv'));
    if (!xmlFile) {
      const zipFiles = email.attachments.filter(item => item.ext === 'zip');
      if (!zipFiles.length) {
        throw new Error(`Không tìm thấy file XML.`);
      }
      for (let i = 0; i < zipFiles.length; i++) {
        const {data: blob} = await Axios.get(getDownloadFileURL(zipFiles[i].download_token), {
          responseType: 'blob',
          timeout: 30000,
        }).catch(error => {
          if (error?.response?.status === 404) {
            return Promise.reject(new Error('File không tồn tại hoặc đã hết hạn.'));
          }
          return Promise.reject(error);
        });
        const files = await this.handleZipFile(blob);
        for (let j = 0; j < files.length; j++) {
          const file = files[j];
          if (file.ext === 'xml' || file.ext === 'inv') {
            xmlFile = await this.handleUploadXML(file, true);
            break;
          }
        }
        if (xmlFile) {
          break;
        }
      }
    }
    if (!xmlFile) {
      throw new Error(`Không tìm thấy file XML.`);
    }
    xmlFile.is_main = true;
    const data = await this.handleParse(xmlFile);
    if (data) {
      data.files = email.attachments.filter(item => item !== xmlFile).map(item => ({...item, uid: item.tmp_name}));
      onSuccess(xmlFile, data);
    } else {
      throw new Error(`Không đọc được file XML.`);
    }
  };

  validateFile = async (ext, file) => {
    if (ext && !ALLOWED_UPLOAD_FILE_EXTENSIONS.includes(ext)) {
      throw new Error(`Định dạng file không được hỗ trợ: ${file.name}.`);
    }
    if (file.size > MAX_UPLOAD_FILE_SIZE.value) {
      throw new Error(`Kích thước file vượt quá cho phép: ${file.name} (${byteTo(file.size, 'MB')}MB).`);
    }
  };

  handleFilesAsync = async (files) => {
    const results = [];
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      const ext = getFileExt(f.name);
      await this.validateFile(ext, f);
      if (ext === 'zip') {
        const children = await this.handleZipFile(f);
        results.push({
          file: f,
          ext,
          children,
        });
      } else {
        results.push({
          file: f,
          ext,
        });
      }
    }
    return results;
  };

  handleZipFile = async (file) => {
    const results = [];
    const res = await JSZip.loadAsync(file).then(r => {
      return r;
    }, error => {
      return Promise.reject(error);
    });
    if (res.files) {
      const filePaths = Object.keys(res.files);
      for (let i = 0; i < filePaths.length; i++) {
        const filePath = filePaths[i];
        if (filePath && !filePath.endsWith('/')) {
          const arr = filePath.split('/');
          const fileName = arr.pop();
          const ext = getFileExt(fileName);
          const fileContent = await res.files[filePath].async('blob');
          const mimeType = await getMimeTypeOfFile(fileContent);
          const f = {
            name: fileName,
            ext,
            size: fileContent.size,
            filePath,
            fileContent,
            type: mimeType,
          };
          if (ext === 'pdf') {
            const blob = new Blob([fileContent], {type: 'application/pdf'});
            f.previewUrl = URL.createObjectURL(blob);
          }
          // if (ext === 'xml') {
          //   const blob = new Blob([fileContent], {type: 'text/xml'});
          //   f.previewUrl = URL.createObjectURL(blob);
          // }
          results.push(f);
        }
      }
    }
    return results;
  };

  findXML = (files) => {
    let xmlFile = null, fromBlob = false;
    const otherFiles = [];
    for (let i = 0; i < files.length; i++) {
      const item = files[i];
      if (!xmlFile && (item.ext === 'xml' || item.ext === 'inv')) {
        xmlFile = item.file;
      } else {
        otherFiles.push(item.file);
      }
    }
    if (!xmlFile) {
      for (let i = 0; i < files.length; i++) {
        const item = files[i];
        if (item.children?.length) {
          for (let j = 0; j < item.children.length; j++) {
            const f = item.children[j];
            if (f.ext === 'xml' || f.ext === 'inv') {
              if (!xmlFile || xmlFile.name.includes('_view')) {
                xmlFile = f;
                fromBlob = true;
              }
            }
          }
        }
      }
    }
    return [xmlFile, fromBlob, otherFiles];
  };

  handleUploadXML = async (xmlFile, fromBlob) => {
    if (!xmlFile) {
      throw new Error('Không tìm thấy file xml.');
    }
    const formData = new FormData();
    if (fromBlob) {
      formData.append('files[]', xmlFile.fileContent, xmlFile.name);
    } else {
      formData.append('files[]', xmlFile);
    }
    const {data: {success, fail}} = await uploadXMLFileAPI(formData);

    if (!success.length) {
      if (fail?.length) {
        const messages = {};
        fail.forEach(item => {
          if (item.error) {
            messages[item.error] = item.error;
          }
        });
        if (Object.keys(messages).length > 0) {
          throw new Error(`Error: ${Object.keys(messages).join(',')}`);
        }
      }
      throw new Error('Lỗi không xác định');
    }

    return success[0];
  };

  handleUpload = async (args) => {
    const files = await this.handleFilesAsync([args.file]);
    args.onProgress({percent: 20});
    const [xmlFile1, fromBlob, otherFiles] = this.findXML(files);
    const xmlFile = await this.handleUploadXML(xmlFile1, fromBlob);
    xmlFile.is_main = 1;
    args.onProgress({percent: 80});
    const data = await this.handleParse(xmlFile);
    args.onProgress({percent: 99});
    if (data) {
      data.files = otherFiles;
    }
    return {
      file: xmlFile,
      data,
    };
  };

  handleParse = async (xmlFile) => {
    let {data: {result}} = await parseXMLFileAPI(xmlFile.tmp_name);

    if (result && result.no) {
      let {data: invoices} = await checkDuplicateInvoicesAPI(result.no * 1);
      const keys = ['seller_tax_code', 'buyer_tax_code'];
      keys.forEach(key => {
        if (result[key]) {
          invoices = invoices.filter(item => item[key] === result[key]);
        }
      });
      if (invoices.length > 0) {
        await this.handleConfirm(invoices);
      }
    }
    if (result) {
      await this.detectNumberFormat(result);
      this.updateItemVAT(result);
    }
    return result;
  };

  onRequest = (args) => {
    if (!args?.file) {
      return;
    }
    const {onSuccess} = this.props;
    this.setState({
      file: null,
    });
    this.handleUpload(args).then(result => {
      const {file, data} = result;
      if (data) {
        args.onSuccess(file);
        onSuccess(file, data);
      } else {
        this.setState({
          file: file,
        });
      }
    }).catch(error => {
      message.error(getErrorMessage(error));
      args.onError(error);
    });
  };

  handleConfirm = async (invoices) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: 'Xác nhận hóa đơn',
        width: 600,
        content: (
          <div className="duplicated-invoices-popup">
            <p>
              Chúng tôi phát hiện thấy bạn đã tải lên {invoices.length} hóa đơn tương tự, rất có thể bạn đang upload
              nhầm một hóa đơn đã có sẵn. Bạn có chắc muốn tiếp tục không?
            </p>
            <p>
              Dưới đây là thông tin {invoices.length > 1 ? 'những ' : ''}hóa đơn bị trùng:
            </p>
            <div className="duplicated-invoices">
              {
                invoices.map((invoice, index) => (
                  <ul key={index}>
                    <li>Số hóa đơn: <strong>{invoice.no}</strong></li>
                    <li>Người bán: <strong>{invoice.seller_name}</strong></li>
                    <li>Mã số thuế người bán: <strong>{invoice.seller_tax_code}</strong></li>
                    <li>Người mua: <strong>{invoice.buyer_name}</strong></li>
                    <li>Mã số thuế người mua: <strong>{invoice.buyer_tax_code}</strong></li>
                    <li>Tổng số tiền đã có VAT: <strong>{formatCurrency(invoice.total_amount_with_vat)}</strong></li>
                  </ul>
                ))
              }
            </div>
          </div>
        ),
        okText: 'Ok, tôi muốn tiếp tục',
        cancelText: 'Hủy bỏ',
        okButtonProps: {
          type: 'danger',
        },
        onOk: () => {
          resolve();
        },
        onCancel: () => {
          reject(new Error('Đã hủy'));
        },
      });
    });
  };

  detectNumberFormat = async (invoice) => {
    const [formats, count, examples] = detectInvoiceNumberFormat(invoice);
    // GOOD
    if (formats[NUMBER_FORMAT.COMMA_DOT] && !formats[NUMBER_FORMAT.DOT_COMMA]) {
      formatInvoiceNumber(invoice, NUMBER_FORMAT.COMMA_DOT);
      return;
    }
    if (formats[NUMBER_FORMAT.DOT_COMMA] && !formats[NUMBER_FORMAT.COMMA_DOT]) {
      formatInvoiceNumber(invoice, NUMBER_FORMAT.DOT_COMMA);
      return;
    }
    // Can be detect
    if (!count[','] && !count['.']) {
      formatInvoiceNumber(invoice, NUMBER_FORMAT.COMMA_DOT);
      return;
    }
    // BAD => select
    const numberFormat = await this.handleSelectNumberFormat(examples);
    formatInvoiceNumber(invoice, numberFormat);
  };

  updateItemVAT = (invoice) => {
    invoice.total_payment_amount = invoice.total_amount_with_vat;
    if (!invoice?.items?.length) {
      return;
    }
    let hasPercentage = false;
    const itemVatRates = {};
    invoice.items.forEach(item => {
      if (item.vat_rate) {
        hasPercentage = true;
        itemVatRates[item.vat_rate] = item.vat_rate;
      }
    });
    if (invoice.vat_rate && !hasPercentage) {
      invoice.items.forEach(item => {
        item.vat_rate = invoice.vat_rate;
        item.__auto_vat_rate = true;
        invoice.__auto_completed_fields = true;
      });
    }
    invoice.items.forEach(item => {
      if (!item.vat_amount && item.vat_rate && item.item_total_amount_without_vat) {
        if (item.vat_rate * 1 !== -1) {
          item.vat_amount = Math.round(item.vat_rate * item.item_total_amount_without_vat / 100);
          item.__auto_vat_amount = true;
          invoice.__auto_completed_fields = true;
        } else {
          item.vat_amount = 0;
          item.__auto_vat_amount = true;
          invoice.__auto_completed_fields = true;
        }
      }
    });
    if (!invoice.vat_rate && Object.values(itemVatRates).length === 1) {
      invoice.vat_rate = Object.values(itemVatRates)[0];
      invoice.__auto_vat_rate = true;
      invoice.__auto_completed_fields = true;
    }
    if (!invoice.currency) {
      invoice.currency = 'VND';
      invoice.exchange_rate = 1;
      invoice.__auto_currency = true;
      invoice.__auto_completed_fields = true;
    }
    // if (!invoice.signed_date && invoice.issued_date) {
    //   invoice.signed_date = invoice.issued_date;
    //   invoice.__auto_signed_date = true;
    //   invoice.__auto_completed_fields = true;
    // }
    if (invoice.signed_date && !invoice.issued_date) {
      invoice.issued_date = invoice.signed_date;
      invoice.__auto_issued_date = true;
      invoice.__auto_completed_fields = true;
    }

    if (invoice.seller_tax_code?.length === 13 && !invoice.seller_tax_code.includes('-')) {
      invoice.seller_tax_code = invoice.seller_tax_code.substr(0, 10) + '-' + invoice.seller_tax_code.substr(10, 3);
      invoice.__auto_seller_tax_code = true;
      invoice.__auto_completed_fields = true;
    }

    if (invoice.buyer_tax_code?.length === 13 && !invoice.buyer_tax_code.includes('-')) {
      invoice.buyer_tax_code = invoice.buyer_tax_code.substr(0, 10) + '-' + invoice.buyer_tax_code.substr(10, 3);
      invoice.__auto_buyer_tax_code = true;
      invoice.__auto_completed_fields = true;
    }
  };

  handleSelectNumberFormat = (examples) => {
    return new Promise((resolve) => {
      Modal.confirm({
        title: 'Chọn định dạng số đầu vào',
        width: 600,
        content: (
          <div className="select-number-format-popup">
            <p>
              Vui lòng chọn định dạng số đầu vào tương ứng với file xml của bạn. Dưới đây là một số ví dụ trong file:
            </p>
            <ul>
              {
                examples.map((item, index) => (
                  <li key={index}>{item.name}: {item.value}</li>
                ))
              }
            </ul>
          </div>
        ),
        okText: 'Dấu "." là dấu thập phân',
        cancelText: 'Dấu "," là dấu thập phân',
        okButtonProps: {
          type: 'primary',
        },
        cancelButtonProps: {
          type: 'primary',
        },
        onOk: () => {
          resolve(NUMBER_FORMAT.COMMA_DOT);
        },
        onCancel: () => {
          resolve(NUMBER_FORMAT.DOT_COMMA);
        },
      });
    });
  };

  handleRetry = () => {
    this.setState({
      file: null,
    });
  };

  handleContinue = () => {
    const {onSuccess} = this.props;
    const {file} = this.state;
    onSuccess(file, null);
  };

  render() {
    const {emailId} = this.props;
    const {file} = this.state;
    if (emailId) {
      return <Spin tip="Vui lòng chờ"/>
    }
    if (file) {
      return (
        <Result
          status="warning"
          title="Xác nhận file đã tải lên"
          extra={[
            <Button key="buy" onClick={this.handleRetry}>Thử lại</Button>,
            <Button type="primary" key="console" onClick={this.handleContinue}>
              Tiếp tục
            </Button>,
          ]}
        >
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                Chúng tôi không thể đọc được nội dung file hóa đơn, có thể do một số nguyên nhân sau:
              </Text>
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined style={{
                color: 'red',
                marginRight: 5
              }}/> Bạn đã upload nhầm file xml
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined style={{
                color: 'red',
                marginRight: 5
              }}/> File xml của bạn bị lỗi
            </Paragraph>
            <Paragraph>
              <CloseCircleOutlined style={{
                color: 'red',
                marginRight: 5
              }}/> File hóa đơn của bạn do 1 nhà cung cấp mới phát hành và chúng tôi chưa cập nhật được dữ liệu
            </Paragraph>
            <Paragraph>
              Bạn hãy kiểm tra lại file xml đã tải lên và thử lại. Nếu không, bạn vẫn có thể click vào nút "Tiếp
              tục" để tiếp tục tạo hóa đơn và nhập thủ công các thông tin.
            </Paragraph>
          </div>
        </Result>
      );
    }
    return (
      <Dragger
        accept=".xml,.inv,.zip"
        multiple={false}
        customRequest={this.onRequest}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined/>
        </p>
        <p className="ant-upload-text">Click để chọn hoặc kéo thả file XML vào đây</p>
        <p className="ant-upload-hint">
          Vui lòng tải lên file hóa đơn. Hãy yên tâm, file này sẽ được lưu trữ trong Google Drive của bạn.
        </p>
      </Dragger>
    );
  }
}

UploadXMLFile.propTypes = {
  emailId: PropTypes.any,
  onSuccess: PropTypes.func,
};

export default UploadXMLFile;
