import {NUMBER_FORMAT} from '../constants/common';

export const detectNumberFormat = (string, count = {}) => {
  const numberOfComma = (`${string}`.match(/,/g) || []).length;
  const numberOfDot = (`${string}`.match(/\./g) || []).length;
  const indexOfComma = `${string}`.indexOf(',');
  const indexOfDot = `${string}`.indexOf('.');
  if (numberOfComma > 0) {
    count[','] = 1;
  }
  if (numberOfDot > 0) {
    count['.'] = 1;
  }
  if (numberOfComma > 1) {
    return NUMBER_FORMAT.COMMA_DOT;
  }
  if (numberOfDot > 1) {
    return NUMBER_FORMAT.DOT_COMMA;
  }
  if (indexOfComma >= 0 && indexOfDot >= 0) {
    if (indexOfComma < indexOfDot) {
      return NUMBER_FORMAT.COMMA_DOT;
    } else {
      return NUMBER_FORMAT.DOT_COMMA;
    }
  }
  if (numberOfComma === 1) {
    const arr = `${string}`.split(',');
    const str = `${parseInt(arr[0])}`;
    if (str.length > 3) {
      return NUMBER_FORMAT.DOT_COMMA; // Ex: 100000,1
    } else if (arr.length > 1 && arr[1].length > 3) {
      return NUMBER_FORMAT.DOT_COMMA; // Ex: 10,00001
    } else {
      return false;
    }
  }
  if (numberOfDot === 1) {
    const arr = `${string}`.split('.');
    const str = `${parseInt(arr[0])}`;
    if (str.length > 3) {
      return NUMBER_FORMAT.COMMA_DOT; // Ex: 100000.1
    } else if (arr.length > 1 && arr[1].length > 3) {
      return NUMBER_FORMAT.COMMA_DOT; // Ex: 10.00001
    } else {
      return false;
    }
  }
  if (numberOfDot === 0 && numberOfComma === 0) {
    return false;
  }
  return 0;
};

export const formatNumber = (value, numberFormat) => {
  if (value === undefined || value === null || value === '') {
    return value;
  }
  if (value === '/') {
    return 0;
  }
  let v = `${value}`;
  if (v.includes('%')) {
    v = v.replace(/%/g, '');
  }
  if (numberFormat === NUMBER_FORMAT.DOT_COMMA) {
    v = v.replace(/\./g, '');
    v = v.replace(/,/g, '.');
    return v * 1;
  }
  v = v.replace(/,/g, '');
  return v * 1;
};
