import React from 'react';
import {InputNumber} from 'antd';
import {formatCurrencyValue, parseCurrencyValue} from '../../helpers';

const AntInputNumber = (props, ref) => (
  <InputNumber
    formatter={formatCurrencyValue}
    parser={parseCurrencyValue}
    {...props}
    ref={ref}
  />
);

export default React.forwardRef(AntInputNumber);
