import React from 'react';
import {Col, Form, Row, Select} from 'antd';
import AntInputNumber from '../../../../common/components/Form/AntInputNumber';
import WarningInput from '../../../../common/components/Form/WarningInput';

class AmountsInfo extends React.PureComponent {
  render() {
    const {currency} = this.props;
    return (
      <div className="form-section">
        <h3>Thông tin số tiền</h3>
        <Row gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}>
          <Col xs={{span: 24}} md={{span: 12}} lg={{span: 4}}>
            <Form.Item
              label="Tổng số tiền chưa có VAT"
              name="total_amount_without_vat"
              rules={[{
                required: true,
                message: 'Vui lòng nhập Tổng số tiền chưa có VAT!'
              }]}
            >
              <AntInputNumber/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 12}} lg={{span: 3}}>
            <Form.Item
              name="__auto_vat_rate"
              className="d-none"
            >
              <WarningInput/>
            </Form.Item>
            <Form.Item
              label="% VAT"
              name="vat_rate"
            >
              <AntInputNumber/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 12}} lg={{span: 4}}>
            <Form.Item
              label="Số tiền VAT"
              name="total_vat_amount"
              rules={[{
                required: true,
                message: 'Vui lòng nhập Số tiền VAT!'
              }]}
            >
              <AntInputNumber/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 12}} lg={{span: 4}}>
            <Form.Item
              label="Tổng số tiền đã có VAT"
              name="total_amount_with_vat"
              rules={[{
                required: true,
                message: 'Vui lòng nhập Tổng số tiền đã có VAT!'
              }]}
            >
              <AntInputNumber/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 12}} lg={{span: 4}}>
            <Form.Item
              label="Tổng số tiền thanh toán"
              name="total_payment_amount"
              rules={[{
                required: true,
                message: 'Vui lòng nhập Tổng số tiền thanh toán!'
              }]}
            >
              <AntInputNumber/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 12}} lg={{span: currency === 'VND' ? 4 : 2}}>
            <Form.Item
              name="__auto_currency"
              className="d-none"
            >
              <WarningInput/>
            </Form.Item>
            <Form.Item
              label="Đơn vị tiền tệ"
              name="currency"
              rules={[{
                required: true,
                message: 'Vui lòng chọn đơn vị tiền tệ!'
              }]}
            >
              <Select>
                <Select.Option value="VND">VND</Select.Option>
                <Select.Option value="USD">USD</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {
            currency !== 'VND' &&
            <Col xs={{span: 24}} md={{span: 12}} lg={{span: 2}}>
              <Form.Item
                name="__auto_exchange_rate"
                className="d-none"
              >
                <WarningInput/>
              </Form.Item>
              <Form.Item
                label="Tỷ giá"
                name="exchange_rate"
                rules={[{
                  required: true,
                  message: 'Vui lòng nhập tỷ giá!'
                }]}
              >
                <AntInputNumber/>
              </Form.Item>
            </Col>
          }
        </Row>
      </div>
    );
  }
}

export default AmountsInfo;
