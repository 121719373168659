import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Alert, Breadcrumb, Button, List, message, Modal, Skeleton} from 'antd';
import {deleteTaxCodeAPI} from '../../../api/tax_codes';
import {getErrorMessage} from '../../../common/helpers/index';
import HyperLink from '../../../common/components/HyperLink/HyperLink';
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons';
import './TaxCodes.scss';
import {getUserCompaniesAC} from '../../../redux/actions/user';
import UpsertTaxCode from './UpsertTaxCode';

const {confirm} = Modal;

class TaxCodes extends React.PureComponent {
  state = {
    selectedItem: null,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.props.dispatch(getUserCompaniesAC());
  };

  handleDelete = (item) => () => {
    confirm({
      title: 'Xác nhận xóa',
      icon: <ExclamationCircleOutlined/>,
      content: 'Bạn có chắc muốn xóa mã số thuế này? Toàn bộ hóa đơn liên quan sẽ bị xóa!',
      onOk: () => {
        return this.doDelete(item);
      },
    });
  };

  doDelete = async (item) => {
    try {
      await deleteTaxCodeAPI(item.id);
      message.info('Đã xóa');
      this.getData();
    } catch (e) {
      message.error(getErrorMessage(e));
    }
  };

  handleAdd = () => {
    this.setState({
      selectedItem: {
        tax_code: '',
      },
    });
  };

  handleEdit = (item) => () => {
    this.setState({
      selectedItem: item,
    });
  };

  onCancelEdit = () => {
    this.setState({
      selectedItem: null,
    });
  };

  onEditSuccess = () => {
    this.setState({
      selectedItem: null,
    });
    this.getData();
  };

  render() {
    const {isFetching, companies: data, isLoaded, limit} = this.props;
    const {selectedItem} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Thiết lập</Breadcrumb.Item>
          <Breadcrumb.Item>Mã số thuế làm việc</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content tax-codes">
          {
            isLoaded &&
            <Alert
              message={`Thêm mã số thuế làm việc để thao tác thuận tiện hơn. Bạn có thể thêm tối đa ${limit} mã số thuế.`}
              showIcon={true}
            />
          }
          {
            !isLoaded && <Skeleton/>
          }
          {
            !!data.length &&
            <Fragment>
              <h3>Các mã số thuế đã có</h3>
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={item => (
                  <List.Item actions={[
                    <HyperLink key="edit" onClick={this.handleEdit(item)}><EditOutlined/> Sửa</HyperLink>,
                    <HyperLink key="remove" onClick={this.handleDelete(item)}><DeleteOutlined/> Xóa</HyperLink>
                  ]}>
                    <List.Item.Meta
                      title={item.name}
                      description={item.tax_code}
                    />
                  </List.Item>
                )}
              />
            </Fragment>
          }
          {
            !isFetching && data.length < limit && !selectedItem &&
            <Button type="primary" onClick={this.handleAdd}><PlusOutlined/> Thêm mới</Button>
          }
          {
            !!selectedItem &&
            <UpsertTaxCode
              selectedItem={selectedItem}
              onSuccess={this.onEditSuccess}
              onCancel={this.onCancelEdit}
            />
          }
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  limit: state.user.info?.plan?.limits?.taxCode,
  companies: state.user.companies.list,
  isLoaded: state.user.companies.isLoaded,
  isFetching: state.user.companies.isFetching,
});

export default connect(mapStateToProps)(TaxCodes);
