import ReactGA from 'react-ga';

class GATrack {
  constructor() {
    if (process.env.REACT_APP_GA_TRACKING_CODE) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
      this.initialized = true;
    }
  }

  set = (args) => {
    if (this.initialized) {
      ReactGA.set(args);
    }
  };

  pageView = (path) => {
    if (this.initialized) {
      ReactGA.pageview(path);
    }
  };

  modalView = (modalName) => {
    if (this.initialized) {
      ReactGA.modalview(modalName);
    }
  };

  event = (args) => {
    if (this.initialized) {
      ReactGA.event(args);
    }
  };
}

const ga = new GATrack();

export default ga;
