import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, message, Modal, Select} from 'antd';
import {formatCurrency, getErrorMessage} from '../../../../common/helpers/index';
import {createOrderAPI} from '../../../../api/manage/orders';
import PLANS, {INVOICE_PLANS} from '../../../../common/constants/plans';
import {formatCurrencyValue} from '../../../../common/helpers';
import {TRANSACTION_TYPES} from '../../../../common/constants/common';

class UpgradePlanModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isSubmitting: false,
      isFetching: false,
      renderKey: 0,
      formData: {},
      userId: null,
    };
    this.defaultState = {...this.state};
    this.form = React.createRef();
  }

  handleOpen = (userId) => {
    this.setState({
      ...this.defaultState,
      isOpen: true,
      userId,
      renderKey: new Date().getTime(),
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  onValuesChange = (changedFields, allFields) => {
    this.setState({
      formData: allFields,
    });
  };

  handleSubmit = (formData) => {
    const {onSuccess} = this.props;
    const {userId} = this.state;
    this.setState({
      isSubmitting: true,
    });
    const postData = {
      ...formData,
      plan_id: PLANS.PREMIUM.id,
      user_id: userId,
    };
    if (formData.transaction_type === TRANSACTION_TYPES.GIFT.id) {
      delete postData.plan_id;
    }
    createOrderAPI(postData).then((res) => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Thành công!', 3);
      if (res?.data?.balance !== undefined) {
        setTimeout(() => {
          message.info(`Số dư tài khoản hiện tại: ${formatCurrency(res.data.balance, false)}đ`);
        }, 3300);
      }
      this.handleClose();
      if (onSuccess) {
        onSuccess();
      }
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  submit = () => {
    this.form.current.submit();
  };

  render() {
    const {isManager} = this.props;
    const {isOpen, isSubmitting, renderKey, formData} = this.state;
    return (
      <Modal
        title="Mua gói dịch vụ"
        visible={isOpen}
        width={500}
        okText="Tiếp tục"
        cancelText="Hủy bỏ"
        maskClosable={false}
        confirmLoading={isSubmitting}
        onOk={this.submit}
        onCancel={this.handleClose}
      >
        <Form
          className="common-form"
          ref={this.form}
          key={renderKey}
          onFinish={this.handleSubmit}
          onValuesChange={this.onValuesChange}
        >
          {
            isManager &&
            <Form.Item
              label="Loại giao dịch"
              name="transaction_type"
              rules={[{
                required: true,
                message: 'Vui lòng chọn loại giao dịch!'
              }]}
            >
              <Select>
                <Select.Option
                  value={TRANSACTION_TYPES.CHARGE.id}
                >
                  Mua hóa đơn
                </Select.Option>
                <Select.Option
                  value={TRANSACTION_TYPES.GIFT.id}
                >
                  Tặng hóa đơn
                </Select.Option>
              </Select>
            </Form.Item>
          }
          {
            formData.transaction_type === TRANSACTION_TYPES.GIFT.id &&
            <Form.Item
              label="Số hóa đơn"
              name="invoice_limit"
              rules={[{
                required: true,
                message: 'Vui lòng nhập số hóa đơn'
              }]}
            >
              <Input/>
            </Form.Item>
          }
          {
            formData.transaction_type !== TRANSACTION_TYPES.GIFT.id &&
            <Form.Item
              label="Chọn gói cước"
              name="invoice_limit"
              rules={[{
                required: true,
                message: 'Vui lòng chọn gói cước!'
              }]}
            >
              <Select>
                {
                  INVOICE_PLANS.map((item) => (
                    <Select.Option
                      key={item.invoice_limit}
                      value={item.invoice_limit}
                    >
                      {formatCurrencyValue(item.invoice_limit)} hóa đơn
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          }
          <Form.Item
            label="Ghi chú"
            name="note"
          >
            <Input.TextArea/>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

UpgradePlanModal.propTypes = {
  onSuccess: PropTypes.func,
  isManager: PropTypes.bool,
};

export default UpgradePlanModal;
