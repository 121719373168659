import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/emails`;

export const getEmailsAPI = (params = null) => {
  return Requester.get(`${API_URL}`, params);
};

export const getEmailDetailAPI = (id, params = null) => {
  return Requester.get(`${API_URL}/${id}`, params);
};
