import AuthWatcher from './auth';
import CompaniesWatcher from './companies';
import InfoWatcher from './info';
import SettingsWatcher from './settings';

export default [
  AuthWatcher(),
  CompaniesWatcher(),
  InfoWatcher(),
  SettingsWatcher(),
];
