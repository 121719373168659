import React, {Fragment} from 'react';
import {Alert, Avatar, Breadcrumb, Button, List, message, Modal, Result, Skeleton} from 'antd';
import {deleteStorageAccountAPI, getStorageAccountsAPI} from '../../../api/storage';
import {getErrorMessage} from '../../../common/helpers';
import {DeleteOutlined, ExclamationCircleOutlined, PlusOutlined} from '@ant-design/icons';
import UpsertStorageAccountModal from './UpsertStorageAccount/UpsertStorageAccountModal';
import HyperLink from '../../../common/components/HyperLink/HyperLink';
import {STORAGE_STATUS, STORAGE_TYPE, STORAGE_TYPE_BY_ID} from '../../../common/constants/common';
import './StorageSettings.scss';

const {confirm} = Modal;

const ListItem = ({item, handleDelete}) => {
  const storageType = STORAGE_TYPE_BY_ID[item.type];
  let name = storageType?.name;
  if (item.type !== STORAGE_TYPE.INTERNAL_SERVER.id) {
    name = `${storageType?.name} (${item.email})`;
  }
  let status = 'N/A';
  switch (item.status) {
    case STORAGE_STATUS.NOT_READY:
      status = <span className="text-blue">Đang khởi tạo</span>;
      break;
    case STORAGE_STATUS.READY:
      status = <span className="text-green">Đang sử dụng</span>;
      break;
    case STORAGE_STATUS.INVALID:
      status = <span className="text-red">Lỗi</span>;
      break;
    default:
      break;
  }
  const actions = item.type === STORAGE_TYPE.INTERNAL_SERVER.id ? undefined : [
    <HyperLink key="remove" onClick={handleDelete(item)}><DeleteOutlined/> Xóa</HyperLink>,
  ];
  return (
    <List.Item
      actions={actions}
    >
      <List.Item.Meta
        avatar={<Avatar src={storageType?.avatar}/>}
        title={name}
        description={
          <div>
            <strong>Trạng thái:</strong> {status} - <strong>Đã sử dụng:</strong> {item.disk_used}MB
          </div>
        }
      />
    </List.Item>
  );
};

class StorageSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      data: [],
    };
    this.upsertModal = React.createRef();
  }

  componentDidMount() {
    this.getAccounts();
  }

  getAccounts = () => {
    this.setState({
      isFetching: true,
    });
    getStorageAccountsAPI().then(res => {
      this.setState({
        isFetching: false,
        data: res.data || [],
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isFetching: false,
        data: [],
      });
    });
  };

  handleAdd = () => {
    const {data} = this.state;
    const hasInternalStorage = !!data.find(item => item.type === STORAGE_TYPE.INTERNAL_SERVER.id);
    this.upsertModal.current.handleOpen(null, {hasInternalStorage});
  };

  handleDelete = (item) => () => {
    confirm({
      title: 'Xác nhận xóa',
      icon: <ExclamationCircleOutlined/>,
      content: 'File hóa đơn sẽ không upload lên tài khoản này nữa, bạn có chắc muốn xóa không?',
      onOk: () => {
        return this.doDelete(item);
      },
    });
  };

  doDelete = async (item) => {
    try {
      await deleteStorageAccountAPI(item.id);
      message.info('Đã xóa');
      this.getAccounts();
    } catch (e) {
      message.error(getErrorMessage(e));
    }
  };

  render() {
    const {isFetching, data} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Thiết lập</Breadcrumb.Item>
          <Breadcrumb.Item>Phương thức lưu trữ hóa đơn</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content storage-settings">
          {
            isFetching && !data.length && <Skeleton/>
          }
          {
            !isFetching && !data.length &&
            <Result
              status="404"
              title="Chưa thêm phương thức nào"
              subTitle="Bạn sẽ không thể lưu trữ file hóa đơn nếu chưa thêm phương thức lưu trữ. Click vào nút dưới để tạo mới."
              extra={
                <Button type="primary" onClick={this.handleAdd}>
                  <PlusOutlined/> Thêm phương thức lưu trữ
                </Button>
              }
            />
          }
          {
            !!data.length &&
            <Alert
              type="info"
              showIcon={true}
              message={`Bạn ${data.length === 2 ? 'chỉ ' : ''} có thể có tối đa 2 phương thức lưu trữ hóa đơn.`}
            />
          }
          {
            !!data.length &&
            <List
              className="list-accounts"
              itemLayout="horizontal"
              dataSource={data}
              renderItem={item => (
                <ListItem
                  item={item}
                  handleDelete={this.handleDelete}
                />
              )}
            />
          }
          {
            !!data.length && data.length < 2 &&
            <div className="action-buttons">
              <Button type="primary" onClick={this.handleAdd}><PlusOutlined/> Thêm phương thức lưu trữ</Button>
            </div>
          }
        </div>
        <UpsertStorageAccountModal ref={this.upsertModal} onSuccess={this.getAccounts}/>
      </Fragment>
    );
  }
}

export default StorageSettings;
