import React, {Fragment} from 'react';
import {Alert, Breadcrumb, Button, Checkbox, Form, Input, List, message, Modal, Skeleton} from 'antd';
import {copyToClipboard, getErrorMessage} from '../../../common/helpers/index';
import HyperLink from '../../../common/components/HyperLink/HyperLink';
import {CopyOutlined, DeleteOutlined, ExclamationCircleOutlined, } from '@ant-design/icons';
import {addAPIKeyAPI, deleteAPIKeyAPI, getAPIKeysAPI} from '../../../api/apiKeys';

const {confirm} = Modal;

const APIKey = ({apiKey}) => {
  const key = apiKey || '';
  const displayKey = key.substr(0, 8) + '...' + key.substr(key.length - 8);
  const handleCopy = () => {
    copyToClipboard(key);
    message.info('Đã sao chép');
  };
  return (
    <Fragment>
      <span className="mr-5">{displayKey}</span>
      <CopyOutlined className="clickable" onClick={handleCopy} title="Sao chép"/>
    </Fragment>
  )
};

class APIKeySettings extends React.PureComponent {
  state = {
    data: [],
    isFetching: false,
    isSubmitting: false,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    getAPIKeysAPI().then(res => {
      this.setState({
        data: res?.data || [],
        isFetching: false,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        data: [],
        isFetching: false,
      });
    });
  };

  handleDelete = (item) => () => {
    confirm({
      title: 'Xác nhận xóa',
      icon: <ExclamationCircleOutlined/>,
      content: 'Các ứng dụng đang sử dụng sẽ không thể tiếp tục truy cập nữa. Bạn có chắc muốn xóa API Key này?',
      onOk: () => {
        return this.doDelete(item);
      },
    });
  };

  doDelete = async (item) => {
    try {
      await deleteAPIKeyAPI(item.id);
      message.info('Đã xóa');
      this.getData();
    } catch (e) {
      message.error(getErrorMessage(e));
    }
  };

  handleAdd = (values) => {
    this.setState({
      isSubmitting: true,
    });
    addAPIKeyAPI(values).then(() => {
      message.success('Đã lưu!');
      this.getData();
      this.setState({
        isSubmitting: false,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  render() {
    const {isFetching, isSubmitting, data} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Thiết lập</Breadcrumb.Item>
          <Breadcrumb.Item>API Key</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content tax-codes">
          <Alert
            type="warning"
            message="API Key giúp các ứng dụng bên thứ 3 có thể truy cập vào dữ liệu của bạn. Vui lòng chỉ sử dụng tính năng này nếu bạn biết bạn đang làm gì."
            showIcon={true}
            className="mb-10"
          />
          <Alert
            type="info"
            message="Bạn có thể thêm tối đa 5 API Key."
            showIcon={true}
          />
          {
            isFetching && !data.length && <Skeleton/>
          }
          {
            !!data.length &&
            <Fragment>
              <h3>Các API Key đã có</h3>
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={item => (
                  <List.Item actions={[
                    <HyperLink key="remove" onClick={this.handleDelete(item)}><DeleteOutlined/> Xóa</HyperLink>
                  ]}>
                    <List.Item.Meta
                      title={item.name}
                      description={
                        <APIKey apiKey={item.api_key}/>
                      }
                    />
                  </List.Item>
                )}
              />
            </Fragment>
          }
          {
            !isFetching && data.length < 5 &&
            <Form onFinish={this.handleAdd} className="common-form">
              <h3>Thêm API Key</h3>
              <Form.Item
                label="Tên API Key"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập một tên cho API Key',
                  },
                ]}
              >
                <Input/>
              </Form.Item>
              <Form.Item
                name="scope"
                label="Quyền truy cập"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn ít nhất một quyền cho API Key',
                  },
                ]}
              >
                <Checkbox.Group>
                  <Checkbox value="invoice.read.by.code">Tra cứu hóa đơn bằng mã hóa đơn</Checkbox>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={isSubmitting}>Thêm</Button>
              </Form.Item>
            </Form>
          }
        </div>
      </Fragment>
    );
  }
}

export default APIKeySettings;
