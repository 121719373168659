import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Input, message, Spin} from 'antd';
import {CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled, InfoCircleFilled} from '@ant-design/icons';
import {checkTBPHAPI} from '../../../../api/utils';
import {formatDate, getErrorMessage} from '../../../../common/helpers';

const TBPH_RESULT = {
  OK: 1,
  NOT_FOUND: 2,
  UNKNOWN: -1,
};

class CheckTBPH extends React.PureComponent {
  state = {
    isSubmitting: false,
    captchaImage: null,
    result: null,
    searchResult: null,
    captcha: '',
    retryCount: 0,
  };

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.taxCode !== this.props.taxCode
      || prevProps.pattern !== this.props.pattern
      || prevProps.serial !== this.props.serial
      || prevProps.no !== this.props.no
    ) {
      this.check();
    }
  }

  check = (captcha) => {
    const {taxCode, pattern, serial, no} = this.props;
    if (!taxCode || !pattern || !serial || !no) {
      return;
    }
    this.setState({
      isSubmitting: true,
      captchaImage: null,
    });
    checkTBPHAPI({
      tax_code: taxCode,
      pattern,
      serial,
      no,
      captcha,
    }).then((res) => {
      if (res?.data?.result !== TBPH_RESULT.OK && !res?.data?.captcha && this.state.retryCount < 3) {
        this.setState(prevState => ({
          retryCount: prevState.retryCount + 1,
        }), () => {
          setTimeout(this.check, 3000);
        });
        return;
      }
      this.setState({
        isSubmitting: false,
        result: res?.data?.result,
        searchResult: res?.data?.searchResult,
        captchaImage: res?.data?.captcha || null,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  onCaptchaChange = (e) => {
    this.setState({captcha: e.target.value}, () => {
      const {captcha} = this.state;
      if (captcha && captcha.length >= 6) {
        this.check(captcha);
      }
    });
  };

  render() {
    const {taxCode, pattern, serial, no, signedDate, issuedDate} = this.props;
    if (!taxCode || !pattern || !serial || !no) {
      return (
        <div className="check-invoice-item">
          <label className="check-item-label">Trạng thái phát hành hóa đơn: </label>
          {
            !taxCode &&
            <div className="result">
              <ExclamationCircleFilled className="text-gray"/> Chưa nhập mã số thuế người bán
            </div>
          }
          {
            !pattern &&
            <div className="result">
              <ExclamationCircleFilled className="text-gray"/> Chưa nhập Mẫu hóa đơn
            </div>
          }
          {
            !serial &&
            <div className="result">
              <ExclamationCircleFilled className="text-gray"/> Chưa nhập Ký hiệu hóa đơn
            </div>
          }
          {
            !no &&
            <div className="result">
              <ExclamationCircleFilled className="text-gray"/> Chưa nhập Số hóa đơn
            </div>
          }
        </div>
      );
    }
    const {isSubmitting, result, searchResult, captchaImage, captcha} = this.state;
    let isDateOk = undefined;
    if (searchResult && issuedDate) {
      isDateOk = moment(issuedDate).isSameOrAfter(searchResult?.invoice_info?.valid_from);
      if (signedDate) {
        isDateOk = isDateOk && moment(signedDate).isSameOrAfter(searchResult?.invoice_info?.valid_from);
      }
    }
    return (
      <div className="check-invoice-item">
        <label className="check-item-label">Trạng thái phát hành hóa đơn: </label>
        {
          isSubmitting && <Spin/>
        }
        {
          !isSubmitting && result &&
          <Fragment>
            <div className="result">
              {
                result === TBPH_RESULT.OK &&
                <Fragment>
                  <CheckCircleFilled className="text-green"/> Mẫu hóa đơn đã được thông báo phát hành (ngày bắt đầu sử
                  dụng: {formatDate(searchResult?.invoice_info?.valid_from)})
                </Fragment>
              }
              {
                result === TBPH_RESULT.NOT_FOUND &&
                <Fragment>
                  <CloseCircleFilled className="text-red"/> Không tìm thấy thông tin phát hành hóa đơn, vui lòng liên hệ
                  đơn vị đối tác để kiểm tra lại
                </Fragment>
              }
              {
                result === TBPH_RESULT.UNKNOWN &&
                <Fragment><ExclamationCircleFilled className="text-gray"/> Không kiểm tra được</Fragment>
              }
            </div>
            {
              result === TBPH_RESULT.OK &&
              <Fragment>
                {
                  searchResult?.company_info?.company_status &&
                  <div className="result">
                    <InfoCircleFilled className="text-blue"/> <span
                    dangerouslySetInnerHTML={{__html: searchResult.company_info.company_status}}/>
                  </div>
                }
                {
                  isDateOk === false &&
                  <div className="result">
                    <CloseCircleFilled className="text-red"/> Ngày ký/ngày lập hóa đơn trước ngày phát hành:
                    <div className="explain">
                      <span className="font-500">Ngày ký</span>: {formatDate(signedDate)}<br/>
                      <span className="font-500">Ngày lập</span>: {formatDate(issuedDate)}<br/>
                      <span
                        className="font-500">Ngày phát hành</span>: {formatDate(searchResult?.invoice_info?.valid_from)}
                    </div>
                  </div>
                }
              </Fragment>
            }
          </Fragment>
        }
        {
          !isSubmitting && captchaImage &&
          <div className="captcha-input">
            <img src={captchaImage} alt="Captcha"/>
            <Input placeholder="Nhập mã xác nhận" value={captcha} onChange={this.onCaptchaChange}/>
          </div>
        }
      </div>
    );
  }
}

CheckTBPH.propTypes = {
  taxCode: PropTypes.string,
  pattern: PropTypes.string,
  serial: PropTypes.string,
  no: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  issuedDate: PropTypes.string,
  signedDate: PropTypes.string,
};

export default CheckTBPH;
