import React from 'react';
import {publicUrl} from '../../../common/helpers';
import {Button} from 'antd';
import {CheckOutlined, LeftOutlined, RightOutlined} from '@ant-design/icons';

class Introduction extends React.PureComponent {
  render() {
    const {setStep} = this.props;
    return (
      <div className="step two-col introduction">
        <div className="col col-img">
          <figure style={{backgroundImage: `url(${publicUrl('/assets/images/banner/working.png')})`}}/>
        </div>
        <div className="col col-main">
          <div className="--title">Chúng tôi giúp bạn những gì?</div>
          <div className="--content">
            <div className="checks">
              <p><CheckOutlined/> Lưu trữ</p>
              <p><CheckOutlined/> Tra cứu</p>
              <p><CheckOutlined/> Thống kê</p>
            </div>
            <p>Chỉ với vài nhấp chuột, hóa đơn của bạn đã được lưu trữ trên Google Drive của bạn và/hoặc máy chủ của
              chúng tôi, luôn luôn an toàn và dễ dàng truy cập từ bất kì nơi nào.</p>
          </div>
          <div className="--footer two-button">
            <Button onClick={setStep('welcome')}>
              <LeftOutlined/> Quay lại
            </Button>
            <Button type="primary" onClick={setStep('storage')}>
              Tiếp tục <RightOutlined/>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Introduction;
