import React from 'react';
import {registerAPI} from '../../../api/auth';
import {ContactsOutlined, LockOutlined, MailOutlined, PhoneOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Checkbox, Form, Input, message} from 'antd';
import {Link} from 'react-router-dom';
import {getErrorMessage} from '../../../common/helpers';
import {history} from '../../../history';
import HyperLink from '../../../common/components/HyperLink/HyperLink';
import TosModal from './TOSModal';

class Register extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
    this.TOSModal = React.createRef();
  }

  checkAgreement = (rule, value) => {
    if (!value) {
      return Promise.reject(false);
    }
    return Promise.resolve(true);
  };

  showTOS = () => {
    this.TOSModal.current.handleOpen();
  };

  handleSubmit = values => {
    this.setState({
      isSubmitting: true,
    });
    this.handleRegister(values).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đăng ký thành công! Bạn có thể đăng nhập!');
      history.push('/login');
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  handleRegister = async (formData) => {
    const {data} = await registerAPI(formData);
    return data;
  };

  render() {
    const {isSubmitting} = this.state;
    return (
      <Form onFinish={this.handleSubmit} className="login-form common-form">
        <Form.Item
          label="Họ tên"
          name="name"
          rules={[{
            required: true,
            message: 'Vui lòng nhập họ tên của bạn!'
          }]}
        >
          <Input
            prefix={<ContactsOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            placeholder="Họ tên bạn"
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{
            required: true,
            message: 'Vui lòng nhập email của bạn!'
          }]}
        >
          <Input
            prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            placeholder="Email của bạn"
          />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phone"
          rules={[{
            required: true,
            message: 'Vui lòng nhập số điện thoại của bạn!'
          }]}
        >
          <Input
            prefix={<PhoneOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            placeholder="Số điện thoại của bạn"
          />
        </Form.Item>
        <Form.Item
          label="Tên truy cập"
          name="username"
          rules={[{
            required: true,
            message: 'Vui lòng nhập tên truy cập!'
          }, {
            pattern: /^[a-zA-Z0-9]+$/,
            message: 'Tên truy cập chỉ chứa chữ cái và số'
          }, {
            type: "string",
            min: 6,
            message: 'Tên truy cập tối thiểu 6 ký tự',
          }]}
        >
          <Input
            prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            placeholder="Tên truy cập"
          />
        </Form.Item>
        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[{
            required: true,
            message: 'Vui lòng nhập mật khẩu!'
          }, {
            type: "string",
            min: 6,
            message: 'Mật khẩu tối thiểu 6 ký tự',
          }]}
        >
          <Input
            prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            type="password"
            placeholder="Mật khẩu"
          />
        </Form.Item>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[{
            validator: this.checkAgreement,
            message: 'Vui lòng đồng ý điều khoản sử dụng!'
          }]}
        >
          <Checkbox>
            Tôi đã đọc và đồng ý với <HyperLink onClick={this.showTOS}>điều khoản sử dụng</HyperLink>
          </Checkbox>
        </Form.Item>
        <Form.Item className="form-actions">
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={isSubmitting}
          >
            Đăng ký
          </Button>
          <div className="other-option">
            Bạn đã có tài khoản? <Link to={'/login'}>Đăng nhập.</Link>
          </div>
        </Form.Item>
        <TosModal ref={this.TOSModal}/>
      </Form>
    );
  }
}

export default Register;
