import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, message} from 'antd';
import {addTicketCommentAPI} from '../../../api/tickets';
import {getErrorMessage} from '../../../common/helpers';

const {TextArea} = Input;

class NewComment extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
    this.formEl = React.createRef();
  }

  submit = (values) => {
    const {ticketId, onSuccess} = this.props;
    this.setState({
      isSubmitting: true,
    });
    addTicketCommentAPI(ticketId, values).then(() => {
      this.setState({
        isSubmitting: false,
      });
      onSuccess();
      message.success('Đã gửi!');
      if (this.formEl.current) {
        this.formEl.current.resetFields();
      }
    }).catch(error => {
      this.setState({
        isSubmitting: false,
      });
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {isSubmitting} = this.state;
    return (
      <Form onFinish={this.submit} ref={this.formEl}>
        <Form.Item
          name="content"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập nội dung',
            },
            {
              max: 1000,
              message: 'Nội dung tối đa 1000 ký tự',
            },
          ]}
        >
          <TextArea rows={4}/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            Gửi
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

NewComment.propTypes = {
  ticketId: PropTypes.any,
  onSuccess: PropTypes.func,
};

export default NewComment;
