import React, {Fragment} from 'react';
import {Breadcrumb, Button, Form, Input, message, Select} from 'antd';
import {getErrorMessage} from '../../../common/helpers';
import {history} from '../../../history';
import {createTicketAPI} from '../../../api/tickets';

const initialValues = {
  priority: 0,
};

class NewTicket extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  handleSubmit = async (data) => {
    await createTicketAPI(data);
  };

  submit = (values) => {
    this.setState({
      isSubmitting: true,
    });
    this.handleSubmit(values).then(() => {
      message.success('Đã gửi yêu cầu');
      history.push('/support/tickets');
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  render() {
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Hỗ trợ</Breadcrumb.Item>
          <Breadcrumb.Item>Gửi yêu cầu</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content new-ticket">
          <Form onFinish={this.submit} className="common-form" initialValues={initialValues}>
            <Form.Item
              label="Tiêu đề"
              name="subject"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tiêu đề',
                },
                {
                  min: 10,
                  message: 'Tiêu đề tối thiểu 10 ký tự',
                },
                {
                  max: 255,
                  message: 'Tiêu đề tối đa 255 ký tự',
                },
              ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label="Nội dung"
              name="content"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập nội dung',
                },
                {
                  min: 10,
                  message: 'Nội dung tối thiểu 10 ký tự',
                },
                {
                  max: 1000,
                  message: 'Nội dung tối đa 1000 ký tự',
                },
              ]}
            >
              <Input.TextArea rows={10}/>
            </Form.Item>
            <Form.Item
              label="Mức độ nghiêm trọng"
              name="priority"
              style={{maxWidth: 180}}
            >
              <Select>
                <Select.Option value={0}>Thấp</Select.Option>
                <Select.Option value={5}>Trung bình</Select.Option>
                <Select.Option value={10}>Cao</Select.Option>
                <Select.Option value={15}>Nghiêm trọng</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item style={{marginTop: 16}}>
              <Button type="primary" htmlType="submit">
                Gửi yêu cầu
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Fragment>
    );
  }
}

NewTicket.propTypes = {};

export default NewTicket;
