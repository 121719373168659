import React from 'react';
import PropTypes from 'prop-types';
import CheckTBPH from './CheckTBPH';
import CheckSignedDate from './CheckSignedDate';
import CheckCertInfo from './CheckCertInfo';
import CheckXMLSignature from './CheckXMLSignature';
import {Col, Row} from 'antd';
import './CheckInvoice.scss';

class CheckInvoice extends React.PureComponent {
  render() {
    const {invoice, cert, xmlFile, checkResult, setCheckResult} = this.props;
    return (
      <div className="form-section">
        <h3>Kiểm tra hóa đơn</h3>
        <Row gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}>
          <Col xs={{span: 24}} md={{span: 12}}>
            <div className="check-invoice">
              <CheckTBPH
                taxCode={invoice.seller_tax_code}
                pattern={invoice.pattern}
                serial={invoice.serial}
                no={invoice.no}
                issuedDate={invoice.issued_date}
                signedDate={invoice.signed_date}
                setCheckResult={setCheckResult}
              />
              <CheckXMLSignature
                savedResult={checkResult?.xml_signature}
                xmlFile={xmlFile}
                setCheckResult={setCheckResult}
              />
              <CheckSignedDate
                issuedDate={invoice.issued_date}
                signedDate={invoice.signed_date}
                rawSignedDate={invoice.__raw_signed_date}
                cert={cert}
              />
              <CheckCertInfo
                cert={cert}
                signedDate={invoice.signed_date}
                rawSignedDate={invoice.__raw_signed_date}
                sellerName={invoice.seller_name}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

CheckInvoice.propTypes = {
  invoice: PropTypes.object,
  cert: PropTypes.object,
  setCheckResult: PropTypes.func,
  checkResult: PropTypes.object,
};

export default CheckInvoice;
