import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}`;

export const getCurrentUserAPI = () => {
  return Requester.get(`${API_URL}/users/me`);
};

export const updateCurrentUserAPI = (data) => {
  return Requester.put(`${API_URL}/users/me`, data);
};

export const updatePasswordAPI = (data) => {
  return Requester.put(`${API_URL}/users/me/password`, data);
};

export const getCurrentUserPlanAPI = () => {
  return Requester.get(`${API_URL}/users/me/plan`);
};

export const getUsersByIdsAPI = (userIds) => {
  return Requester.post(`${API_URL}/users/multiple`, userIds);
};
