import React, {useRef, useEffect} from 'react';

const WarningInput = ({value}) => {
  const divEl = useRef(null);
  useEffect(() => {
    if (divEl?.current) {
      const formItem = divEl.current.closest('.ant-form-item');
      if (formItem) {
        if (value) {
          formItem.classList.add('has-warning');
        } else {
          formItem.classList.remove('has-warning');
        }
      }
    }
  }, [value]);
  return <div ref={divEl}/>;
};

export default WarningInput;
