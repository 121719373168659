import React, {Fragment} from 'react';
import {InboxOutlined} from '@ant-design/icons';
import {Breadcrumb, Button, Input, message, Skeleton, Tabs, Upload} from 'antd';
import xml2js from 'xml2js';
import {getErrorMessage} from '../../../../common/helpers';
import {addInvoiceTemplateAPI, getInvoicePropertiesAPI, testInvoiceTemplateAPI} from '../../../../api/templates';
import InvoiceForm from './InvoiceForm';
import InvoicePreview from './InvoicePreview';
import XMLViewModal from '../../../../common/components/XMLView/XMLViewModal';
import {history} from '../../../../history';

const {Dragger} = Upload;

const xmlParser = new xml2js.Parser({
  attrkey: '__attr',
  explicitArray: false,
});

async function parseUserDefines(value) {
  try {
    const res = await xmlParser.parseStringPromise(`<userDefines>${value}</userDefines>`);
    if (res && res.userDefines) {
      return res.userDefines;
    }
  } catch (e) {

  }
  return value;
}

async function flattenJson(json, path = '', result = {}) {
  if (!json || typeof json !== 'object') {
    return result;
  }
  if (Array.isArray(json)) {
    result[path] = json;
    for (let i = 0; i < json.length; i++) {
      let value = json[i];
      const newPath = path ? `${path}[${i}]` : `[${i}]`;
      if (typeof value === 'object') {
        await flattenJson(value, newPath, result);
      } else {
        result[newPath] = value;
      }
    }
  } else {
    const keys = Object.keys(json);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      let value = json[key];
      if ('inv:userDefines' === key && value && typeof value === 'string') {
        value = await parseUserDefines(value);
        json[key] = value;
      }
      const newPath = path ? path + '.' + key : key;
      if (typeof value === 'object') {
        await flattenJson(value, newPath, result);
      } else {
        result[newPath] = value;
      }
    }
  }
  return result;
}

class NewTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isSubmitting: false,
      data: null,
      tree: null,
      invoice: null,
      invoiceProperties: [],
      productProperties: [],
      unmapInvoiceProperties: [],
      unmapProductProperties: [],
      formData: {
        invoiceProperties: {},
        productProperties: {},
        productKey: '',
      },
      xmlContent: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    getInvoicePropertiesAPI().then(res => {
      this.setState({
        isLoaded: true,
        invoiceProperties: res.data.invoiceProperties,
        productProperties: res.data.productProperties,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
    });
  };

  setFormData = (values) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        ...values,
      }
    }));
  };

  handleInputTextChange = (field) => (event) => {
    this.setFormData({
      [field]: event.target.value,
    });
  };

  beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = e => {
      const xmlContent = e.target.result;
      this.handleParseFileAsync(xmlContent).then(state => {
        this.setState(state);
      }).catch(error => {
        message.error(getErrorMessage(error));
      });
    };
    reader.readAsText(file);
    return false;
  };

  handleParseFileAsync = async (xmlContent) => {
    const {invoiceProperties, productProperties} = this.state;

    const jsonContent = await xmlParser.parseStringPromise(xmlContent);
    if (!jsonContent) {
      throw new Error('Không tìm thấy nội dung!');
    }
    const data = await flattenJson(jsonContent);
    const {data: invoice} = await testInvoiceTemplateAPI({xmlContent});
    const unmapInvoiceProperties = [];
    const unmapProductProperties = [];
    const mappedProductProperties = {};
    if (invoice.items) {
      invoice.items.forEach(item => {
        Object.keys(item).forEach(key => {
          mappedProductProperties[key] = key;
        });
        if ((item.item_total_amount_without_vat || item.vat_amount) && !item.total_amount_with_vat) {
          item.total_amount_with_vat = ((item.item_total_amount_without_vat || 0) * 1) + ((item.vat_amount || 0) * 1);
        }
      });
    }
    invoiceProperties.forEach(property => {
      if (!invoice[property.alias]) {
        unmapInvoiceProperties.push(property);
      }
    });
    productProperties.forEach(property => {
      if (!mappedProductProperties[property.alias]) {
        unmapProductProperties.push(property);
      }
    });
    return {
      data,
      tree: jsonContent,
      invoice,
      unmapInvoiceProperties,
      unmapProductProperties,
      xmlContent,
    };
  };

  handleSubmit = () => {
    const {formData, xmlContent} = this.state;
    if (!formData.name) {
      message.error('Nhập tên mẫu');
      return;
    }
    const postData = JSON.parse(JSON.stringify(formData));
    if (!postData.productKey && !Object.keys(postData.invoiceProperties).length && !Object.keys(postData.productProperties).length) {
      message.error('Không có dữ liệu cần lưu');
      return;
    }
    if (Object.keys(postData.productProperties).length) {
      if (!postData.productKey) {
        message.error('Vui lòng nhập dữ liệu cho mục Sản phẩm/dịch vụ');
        return;
      }
      let isInvalid = false;
      Object.keys(postData.productProperties).forEach(alias => {
        const {productKey} = postData;
        const pKey = postData.productProperties[alias];
        const key = `${pKey}`.startsWith('[') ? `${productKey}${pKey}` : `${productKey}.${pKey}`;
        if (!key.startsWith(postData.productKey)) {
          isInvalid = true;
          return;
        }
        if (key.includes('].')) {
          const arr = key.split(/\[.+]\./);
          if (arr[0] !== postData.productKey || arr.length < 2) {
            isInvalid = true;
          } else {
            postData.productProperties[alias] = arr[1];
          }
        } else {
          const arr = key.split(`${postData.productKey}.`);
          if (arr.length < 2) {
            isInvalid = true;
          } else {
            postData.productProperties[alias] = arr[1];
          }
        }
      });
    }
    this.setState({isSubmitting: true});
    addInvoiceTemplateAPI({
      template: postData,
      xmlContent: xmlContent,
    }).then(() => {
      message.success('Đã lưu!');
      history.push('/manage/templates');
    }).catch(error => {
      this.setState({isSubmitting: false});
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {data, formData, tree, isLoaded, isSubmitting, invoice, unmapInvoiceProperties, unmapProductProperties} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Nhập mẫu hóa đơn</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content templates">
          {
            !isLoaded &&
            <Skeleton/>
          }
          {
            isLoaded && !invoice &&
            <Dragger
              accept=".xml,.inv,.zip"
              multiple={false}
              beforeUpload={this.beforeUpload}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined/>
              </p>
              <p className="ant-upload-text">Click để chọn hoặc kéo thả file XML vào đây</p>
            </Dragger>
          }
          {
            invoice &&
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Thông tin còn thiếu" key="1">
                <div className="form-input mb-10">
                  <label>Tên mẫu</label>
                  <Input
                    value={formData.name}
                    onChange={this.handleInputTextChange('name')}
                  />
                </div>
                <InvoiceForm
                  data={data}
                  formData={formData}
                  setFormData={this.setFormData}
                  unmapInvoiceProperties={unmapInvoiceProperties}
                  unmapProductProperties={unmapProductProperties}
                />
                <XMLViewModal jsonTree={tree}/>
                <Button
                  type="primary"
                  loading={isSubmitting}
                  onClick={this.handleSubmit}
                >
                  Lưu lại
                </Button>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Xem trước hóa đơn" key="2">
                <InvoicePreview invoice={invoice}/>
              </Tabs.TabPane>
            </Tabs>
          }
        </div>
      </Fragment>
    );
  }
}

export default NewTemplate;
