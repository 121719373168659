import React from 'react';
import PropTypes from 'prop-types';
import {CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, SyncOutlined} from '@ant-design/icons';
import {TIME_IN_MILISECOND} from '../../../../common/constants/time';
import ListFiles from '../../../../common/components/FileUpload/ListFiles';
import {getDownloadFileURL, getStorageFileByFileIdsAPI} from '../../../../api/storage';
import {Popover} from 'antd';
import {STORAGE_FILE_SYNC_STATUS} from '../../../../common/constants/common';

const EnumStatus = {
  FETCHING: -1,
  MULTIPLE_VALUE: -2,
  ...STORAGE_FILE_SYNC_STATUS,
};

class FileStatus extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      files: null,
      error: null,
      status: EnumStatus.FETCHING,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.getFile();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  getFile = () => {
    const {files} = this.props;
    if (!files || !files.length) {
      this.setState({
        isFetching: false,
        files: [],
        status: EnumStatus.FETCHING,
      });
      return;
    }
    this.setState({
      isFetching: true,
      files: files,
    });
    getStorageFileByFileIdsAPI(files.map(item => item.id)).then(res => {
      const state = {
        files: JSON.parse(JSON.stringify(files)),
        error: null,
        isFetching: false,
        status: EnumStatus.FETCHING,
      };
      const storageFiles = {};
      let statuses = {};

      state.files.forEach(invoiceFile => {
        storageFiles[invoiceFile.id] = {};
      });
      (res.data || []).forEach(item => {
        storageFiles[item.invoice_file_id][item.id] = item;
        statuses[item.status] = item.status;
        if (!item.download_token) {
          return;
        }
        item.url = getDownloadFileURL(item.download_token);
        item.viewUrl = item.url + '&view=true';
      });

      state.files.forEach(invoiceFile => {
        invoiceFile.storageFiles = Object.values(storageFiles[invoiceFile.id]);
        invoiceFile.storageFiles.forEach(item => {
          if (item.url) {
            invoiceFile.url = item.url;
          }
          if (item.viewUrl) {
            invoiceFile.viewUrl = item.viewUrl;
          }
        });
      });

      statuses = Object.values(statuses);

      state.status = statuses.length === 1 ? statuses[0] : EnumStatus.MULTIPLE_VALUE;

      this.setState(state);

      const isStillUploading = statuses.includes(STORAGE_FILE_SYNC_STATUS.NOT_UPLOADED_YET);

      if (isStillUploading) {
        this.timer = setTimeout(this.getFile, 5 * TIME_IN_MILISECOND.ONE_SECOND + Math.random() * TIME_IN_MILISECOND.ONE_SECOND * 5);
      } else {
        this.timer = setTimeout(this.getFile, 50 * TIME_IN_MILISECOND.ONE_MINUTE + Math.random() * TIME_IN_MILISECOND.ONE_MINUTE);
      }
    }).catch(error => {
      this.setState({
        error,
        isFetching: false,
      });
    });
  };

  render() {
    const {files: propFiles, showListFile} = this.props;
    const {files, error, status} = this.state;
    if (!propFiles || !propFiles.length) {
      return '-';
    }
    if (error || !files || !files.length || status === EnumStatus.FETCHING) {
      return null;
    }
    const listFiles = <ListFiles files={files}/>;
    if (showListFile) {
      if (status === EnumStatus.NOT_UPLOADED_YET) {
        return (
          <div className="text-center" title="Đang tải lên">
            <SyncOutlined spin={true} className="text-gray"/> Uploading
          </div>
        );
      }
      return listFiles;
    }
    if (status === EnumStatus.NOT_UPLOADED_YET) {
      return (
        <Popover content={listFiles}>
          <div className="text-center" title="Đang tải lên">
            <SyncOutlined spin={true} className="text-gray"/> Đang lưu
          </div>
        </Popover>
      );
    }
    if (status === EnumStatus.MULTIPLE_VALUE) {
      return (
        <Popover content={listFiles}>
          <div className="text-center" title="Nhiều giá trị">
            <InfoCircleOutlined className="text-blue"/>
          </div>
        </Popover>
      );
    }
    if (status === EnumStatus.ERROR) {
      return (
        <Popover content={listFiles}>
          <div className="text-center" title="Tải lên bị lỗi">
            <CloseCircleOutlined className="text-red"/> Lỗi
          </div>
        </Popover>
      );
    }
    return (
      <Popover content={listFiles}>
        <div className="text-center" title="Đã tải lên">
          <CheckCircleOutlined className="text-green"/> Đã lưu
        </div>
      </Popover>
    );
  }
}

FileStatus.propTypes = {
  files: PropTypes.array,
  showListFile: PropTypes.bool,
};

FileStatus.defaultProps = {
  showListFile: false,
};

export default FileStatus;
