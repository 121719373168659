import React from 'react';
import {getNotificationsAPI} from '../../../api/utils';

class HeaderNotification extends React.PureComponent {
  state = {
    isLoaded: false,
    notifications: [],
  };

  componentDidMount() {
    this.getNotifications();
  }

  getNotifications = () => {
    getNotificationsAPI().then(res => {
      this.setState({
        notifications: res.data || [],
      });
    }).catch(() => {

    });
  };

  render() {
    const {notifications} = this.state;
    const msg = notifications.map(item => item.data).join(' - ');
    return (
      <div className="header-notification">
        {
          msg &&
          <marquee><strong>Thông báo:</strong> {msg}</marquee>
        }
      </div>
    );
  }
}

export default HeaderNotification;
