import {GET_USER_COMPANIES_AC, GET_USER_COMPANIES_SUCCESS_AC, SELECT_COMPANY_AC} from '../../actions/user';
import createReducer from '../createReducer';

const initialState = {
  isLoaded: false,
  isFetching: false,
  list: [],
  byTaxCode: {},
  selectedCompany: null,
};

const handler = {
  [GET_USER_COMPANIES_AC]: (state) => {
    return {
      ...state,
      isFetching: true,
    };
  },
  [GET_USER_COMPANIES_SUCCESS_AC]: (state, action) => {
    if (action.companies) {
      const {companies} = action;
      let selectedCompany = null;
      if (state.selectedCompany) {
        selectedCompany = companies.find(item => item.id === state.selectedCompany.id) || null;
      }
      if (!selectedCompany && companies.length === 1) {
        selectedCompany = companies[0];
      }
      const byTaxCode = companies.reduce(function (result, item) {
        result[item.tax_code] = item;
        return result;
      }, {});
      return {
        ...state,
        isLoaded: true,
        isFetching: false,
        list: companies,
        byTaxCode,
        selectedCompany,
      };
    }
    return state;
  },
  [SELECT_COMPANY_AC]: (state, action) => {
    const selectedCompany = state.list.find(item => item.id === action.companyId) || null;
    return {
      ...state,
      selectedCompany,
    };
  },
};

export default createReducer(initialState, handler);
