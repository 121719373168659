import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Breadcrumb, Result, Skeleton} from 'antd';
import {INVOICE_TYPE} from '../../../common/constants/common';
import {Link} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';
import Invoices from './Invoices';
import {history} from '../../../history';
import {getCurrentUserAC} from '../../../redux/actions/user';

const InvoiceContainer = ({companies, isTaxCodesLoaded, selectedCompany, ...others}) => {
  const {match: {params}} = others;
  let type = INVOICE_TYPE.PURCHASE;
  if (params.type !== 'sale' && params.type !== 'purchase') {
    history.replace('/invoices/purchase');
    return null;
  }
  if (params.type === 'sale') {
    type = INVOICE_TYPE.SALE;
  }
  return (
    <Fragment>
      <Breadcrumb className="main-breadcrumb">
        <Breadcrumb.Item>{type === INVOICE_TYPE.PURCHASE ? 'Hóa đơn đầu vào' : 'Hóa đơn đầu ra'}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="main-content invoices">
        {
          !isTaxCodesLoaded &&
          <Skeleton/>
        }
        {
          isTaxCodesLoaded && !companies.length &&
          <Result
            status="404"
            title="Chưa thêm mã số thuế làm việc"
            subTitle="Bạn chưa thêm mã số thuế làm việc. Click vào nút dưới đây để thêm một mã số thuế."
            extra={
              <Link
                className="ant-btn ant-btn-primary"
                to={`/settings/tax-codes`}
              >
                <PlusOutlined/> Thêm mã số thuế
              </Link>
            }
          />
        }
        {
          isTaxCodesLoaded && selectedCompany &&
          <Invoices
            companyId={selectedCompany.id}
            type={type}
            invoiceTypeName={params.type}
            {...others}
          />
        }
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  companies: state.user.companies.list,
  selectedCompany: state.user.companies.selectedCompany,
  isTaxCodesLoaded: state.user.companies.isLoaded,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => {
    dispatch(getCurrentUserAC());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(InvoiceContainer));
