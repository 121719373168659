import {call, put, takeEvery} from 'redux-saga/effects';
import {GET_USER_COMPANIES_AC, getUserCompaniesSuccessAC} from '../../actions/user';
import {getTaxCodesAPI} from '../../../api/tax_codes';

function* getUserCompanies() {
  try {
    const {data} = yield call(getTaxCodesAPI);
    yield put(getUserCompaniesSuccessAC(data));
  } catch (e) {
  }
}

export default function* () {
  yield takeEvery(GET_USER_COMPANIES_AC, getUserCompanies);
}
