import React from 'react';
import {resetPasswordAPI} from '../../../api/auth';
import {Button, Form, Input, message} from 'antd';
import {getErrorMessage} from '../../../common/helpers';
import qs from 'qs';
import {history} from '../../../history';

class ResetPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  componentDidMount() {
    this.getToken();
  }

  getToken = () => {
    const params = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
    if (!params.token) {
      history.replace('/login');
      return null;
    }
    return params.token;
  };

  validateConfirmPassword = ({getFieldValue}) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject('Mật khẩu không khớp!');
    },
  });

  handleSubmit = (formData) => {
    this.setState({
      isSubmitting: true,
    });
    resetPasswordAPI({
      ...formData,
      token: this.getToken(),
    }).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đặt mật khẩu thành công! Bạn có thể đăng nhập!');
      history.replace('/login');
    }).catch(error => {
      const errorMessage = getErrorMessage(error);
      if (errorMessage === 'Token not existed') {
        message.error('Liên kết đặt mật khẩu đã hết hạn hoặc không tồn tại!');
      } else {
        message.error(errorMessage);
      }
      this.setState({
        isSubmitting: false,
      });
    });
  };

  render() {
    const {isSubmitting} = this.state;
    return (
      <Form onFinish={this.handleSubmit} className="login-form common-form">
        <Form.Item
          label="Mật khẩu mới"
          hasFeedback={true}
          name="password"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu!',
            },
            {
              min: 6,
              message: 'Mật khẩu ít nhất 6 ký tự!',
            },
            {
              max: 100,
              message: 'Mật khẩu tối đa 100 ký tự!',
            },
          ]}
        >
          <Input.Password/>
        </Form.Item>
        <Form.Item
          label="Nhập lại mật khẩu mới"
          name="confirm"
          hasFeedback={true}
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu!',
            },
            this.validateConfirmPassword,
          ]}
        >
          <Input.Password/>
        </Form.Item>
        <Form.Item className="form-actions">
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={isSubmitting}
          >
            Đặt lại mật khẩu
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default ResetPassword;
