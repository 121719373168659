import React, {Fragment} from 'react';
import {publicUrl} from '../../../common/helpers';
import {Button} from 'antd';
import {CaretRightOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined, SmileOutlined} from '@ant-design/icons';

class CreateInvoice extends React.PureComponent {
  state = {
    page: 1,
  };

  prevPage = () => {
    this.setState({page: 1});
  };

  nextPage = () => {
    this.setState({page: 2});
  };

  render() {
    const {setStep} = this.props;
    const {page} = this.state;
    return (
      <div className="step two-col create-invoice">
        <div className="col col-img">
          {
            page === 1 &&
            <figure
              className="size-60"
              style={{backgroundImage: `url(${publicUrl('/assets/images/banner/add-file.svg')})`}}
            />
          }
          {
            page === 2 &&
            <figure
              className="fit pos-left round"
              style={{backgroundImage: `url(${publicUrl('/assets/images/banner/upload-invoice-1.jpg')})`}}
            />
          }
        </div>
        <div className="col col-main">
          <div className="--title">Tạo/tải lên hóa đơn</div>
          <div className="--content">
            {
              page === 1 &&
              <Fragment>
                <p>
                  Đầu tiên, đi tới menu <strong>Hóa đơn</strong> <DoubleRightOutlined/> <strong>Hóa đơn đầu
                  vào</strong> (hoặc Hóa đơn đầu ra):
                </p>
                <p><CaretRightOutlined/> Nhấn vào nút <strong>Tạo hóa đơn</strong></p>
                <p><CaretRightOutlined/> Chọn file XML và tải lên</p>
                <p><CaretRightOutlined/> Kiểm tra thông tin và bấm nút <strong>Lưu hóa đơn</strong></p>
                <p>Thật đơn giản phải không?</p>
              </Fragment>
            }
            {
              page === 2 &&
              <Fragment>
                <p>
                  Chúng tôi sẽ đọc thông tin hóa đơn và nhập liệu giúp bạn, nhưng đôi khi chúng tôi cũng có thể không
                  đọc được một vài file <SmileOutlined/>.
                </p>
                <p>
                  Nếu gặp trường hợp đó, bạn vẫn tạo hóa đơn bình thường và tự nhập các thông tin thủ công. Chúng tôi sẽ
                  cố gắng cập nhật thêm nhiều định dạng file hóa đơn mới để tự động hóa được nhiều hơn.
                </p>
              </Fragment>
            }
          </div>
          <div className="--footer two-button">
            {
              page === 1 &&
              <Fragment>
                <Button onClick={setStep('storage')}>
                  <LeftOutlined/> Quay lại
                </Button>
                <Button type="primary" onClick={this.nextPage}>
                  Tiếp tục <RightOutlined/>
                </Button>
              </Fragment>
            }
            {
              page === 2 &&
              <Fragment>
                <Button onClick={this.prevPage}>
                  <LeftOutlined/> Quay lại
                </Button>
                <Button type="primary" onClick={setStep('view-invoice')}>
                  Ok, tôi hiểu <RightOutlined/>
                </Button>
              </Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default CreateInvoice;
