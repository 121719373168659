export const TIME = {
  ONE_SECOND: 1,
  ONE_MINUTE: 60,
  ONE_HOUR: 3600,
  ONE_DAY: 86400,
  ONE_MONTH: 86400 * 30,
  ONE_YEAR: 86400 * 365,
};

export const TIME_IN_MILISECOND = {
  ONE_SECOND: 1000,
  ONE_MINUTE: 60 * 1000,
  ONE_HOUR: 3600 * 1000,
  ONE_DAY: 86400 * 1000,
  ONE_MONTH: 86400 * 30 * 1000,
  ONE_YEAR: 86400 * 365 * 1000,
};
