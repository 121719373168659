import Requester from '../../common/network/Requester';
const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/manage`;

export const getOrdersAPI = (params = null) => {
  return Requester.get(`${API_URL}/orders`, params);
};

export const getOrderDetailAPI = (id, params = null) => {
  return Requester.get(`${API_URL}/orders/${id}`, params);
};

export const createOrderAPI = (data) => {
  return Requester.post(`${API_URL}/orders`, data);
};
