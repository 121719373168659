import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/tickets`;

export const getTicketsAPI = (params = null) => {
  return Requester.get(`${API_URL}`, params);
};

export const createTicketAPI = (data) => {
  return Requester.post(`${API_URL}`, data);
};

export const getTicketDetailAPI = (id) => {
  return Requester.get(`${API_URL}/${id}`);
};

export const updateTicketAPI = (id, data) => {
  return Requester.put(`${API_URL}/${id}`, data);
};

export const getTicketCommentsAPI = (id, params = null) => {
  return Requester.get(`${API_URL}/${id}/comments`, params);
};

export const addTicketCommentAPI = (id, data) => {
  return Requester.post(`${API_URL}/${id}/comments`, data);
};
