import React from 'react';
import {Button, Modal} from 'antd';

class TosModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({
      isOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const {isOpen} = this.state;
    return (
      <Modal
        title="Quy định sử dụng"
        visible={isOpen}
        onOk={this.handleClose}
        onCancel={this.handleClose}
        footer={[
          <Button key="submit" type="default" onClick={this.handleClose}>
            Đóng lại
          </Button>,
        ]}
      >
        <div className="tos">
          <h2>I. Quy định sử dụng</h2>
          <p>
            1.1. Chúng tôi không chịu trách nhiệm và không đảm bảo về việc mất mát, rò rỉ hay hư hỏng đối với dữ liệu
            lưu trên tài khoản người dùng. Người sử dụng có quyền quản lý và trách nhiệm thực hiện việc duy trì và bảo
            quản dữ liệu của mình.
          </p>
          <p>
            1.2. Chúng tôi không chịu trách nhiệm và không bảo đảm về tính chính xác của những thông tin do người dùng
            đăng tải trên hệ thống.
          </p>
          <p>
            1.3. Không dùng dịch vụ vào bất kỳ mục đích/hình thức nào vi phạm pháp luật Việt Nam.
          </p>
          <p>
            1.4. Người dùng không được cố ý làm cạn kiệt tài nguyên hệ thống, quá tải bộ vi xử lý và bộ nhớ dưới bất kỳ
            hình thức nào. Đối với các trường hợp người dùng gây thiệt hại cho chúng tôi, chúng tôi có quyền khóa/xóa
            tài khoản ngay lập tức cũng như đơn phương chấm dứt hợp đồng.
          </p>
          <h2>II. Trách nhiệm của người dùng</h2>
          <p>
            2.1. Người dùng cần lưu giữ một cách an toàn các thông tin, tài liệu, mật khẩu hay những thông tin khác liên
            quan đến tài khoản của dịch và lập tức thông báo cho chúng tôi khi bạn phát hiện các hình thức truy cập trái
            phép sử dụng tài khoản của bạn hoặc các sơ hở về bảo mật, bao gồm việc mất mát, đánh cắp hoặc để lộ thông
            tin về mật khẩu và các thông tin khác.
          </p>
          <p>
            2.2. Người dùng sẽ phải tự chịu trách nhiệm trước pháp luật với nội dung đăng tải trên tài khoản của mình và
            sẽ phải chấp nhận mọi hình thức xử lý từ phía cơ quan chức năng (nếu có) nếu như nội dung vi phạm luật pháp
            nước Cộng hòa Xã hội Chủ nghĩa Việt Nam, hoặc khi bị tranh chấp, khiếu nại. Tuân thủ thực hiện và chịu trách
            nhiệm theo các quy định của Nhà nước và quốc tế về sử dụng dịch vụ Internet, bí mật quốc gia, an ninh, văn
            hoá, quyền sở hữu trí tuệ, bí mật doanh nghiệp.
          </p>
          <p>
            2.3. Người dùng có trách nhiệm tự bảo quản dữ liệu của mình. Chúng tôi sẽ không chịu trách nhiệm đối với các
            trường hợp xóa nhầm dữ liệu, mất dữ liệu do phía người dùng, do quá trình bảo mật của người dùng gây ra.
          </p>
          <h2>III. Trách nhiệm của chúng tôi</h2>
          <p>
            3.1. Chúng tôi đảm bảo mức bảo mật ở mức cao nhất có thể nhằm đảm bảo an toàn dữ liệu cho người dùng ngoại
            trừ những lỗi bảo mật mới được phát hiện và chưa có bản vá lỗi từ nhà sản xuất phần cứng/phần mềm.
          </p>
          <p>
            3.2. Chúng tôi không đảm bảo tính toàn vẹn dữ liệu khi lưu trữ dữ liệu trên tài khoản Google Drive của người
            dùng.<br/>
            Trong trường hợp người dùng đồng ý lưu trữ dữ liệu trên máy chủ của chúng tôi, chúng tôi sẽ cố gắng đảm bảo
            lưu trữ dữ liệu an toàn nhất có thể. Tuy nhiên, trong trường hợp xảy ra sự cố ngoài ý muốn, chúng tôi sẽ cam
            kết khắc phục sự cố và khôi phục dữ liệu người dùng về bản backup gần nhất.
          </p>
          <p>
            3.3. Chúng tôi không chịu bất kỳ trách nhiệm cũng như ràng buộc pháp lý nào đối với tài khoản miễn phí và
            đăng ký tự do.
          </p>
          <h2>IV. Chấm dứt dịch vụ</h2>
          <p>
            4.1. Chúng tôi có quyền đơn phương chấm dứt dịch vụ của người dùng nếu người dùng vi phạm các quy định tại
            mục I.
          </p>
          <p>
            4.2. Trong trường hợp bất khả kháng và không thể tiếp tục duy trì dịch vụ, chúng tôi có quyền ngừng cung cấp
            dịch vụ cho tất cả người dùng.
          </p>
          <h2>V. Thay đổi điều khoản</h2>
          <p>
            Điều khoản sử dụng sẽ được thay đổi và cập nhật cho phù hợp với từng thời điểm và loại hình dịch vụ. Người
            dùng có trách nhiệm thường xuyên theo dõi email hoặc văn bản này để đảm bảo luôn thực hiện đúng theo các
            điều khoản.
          </p>
        </div>
      </Modal>
    );
  }
}

export default TosModal;