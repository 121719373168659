import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/users/me/tax-codes`;

export const getTaxCodesAPI = () => {
  return Requester.get(`${API_URL}/`);
};

export const deleteTaxCodeAPI = (id) => {
  return Requester.delete(`${API_URL}/${id}`);
};

export const addTaxCodeAPI = (data) => {
  return Requester.post(`${API_URL}/`, data);
};

export const updateTaxCodeAPI = (id, data) => {
  return Requester.put(`${API_URL}/${id}`, data);
};
