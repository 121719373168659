import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_PARSER_SERVICE_API_URL}`;

export const uploadFilesAPI = (files, params = null) => {
  const formData = new FormData();
  if (typeof files === 'object') {
    if (Array.isArray(files)) {
      files.forEach(file => {
        formData.append('files[]', file);
      });
    } else {
      formData.append('files[]', files);
    }
  }
  return Requester.post(`${API_URL}/upload`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
    params,
  });
};

export const uploadXMLFileAPI = (formData, params = null) => {
  return Requester.post(`${API_URL}/upload`, formData, {
    headers: {'Content-Type': 'multipart/form-data'},
    params,
  });
};

export const parseXMLFileAPI = (tmp_name) => {
  return Requester.get(`${API_URL}/xml`, {tmp_name});
};

export const getDownloadFileURL = (token) => {
  return `${API_URL}/download?token=${token}`;
};
