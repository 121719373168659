import React from 'react';
import {formatCurrencyValue} from '../../../../common/helpers';
import './InvoicePreview.scss';

const InvoicePreview = ({invoice}) => {
  const dates = (invoice.issued_date || '').split('-');
  return (
    <div className="invoice-preview">
      <div className="inv-name text-center">{invoice.name}</div>
      <div className="inv-description text-center">Bảng thể hiện của hóa đơn điện tử</div>
      <div className="inv-info text-right">
        Mẫu số: {invoice.pattern}<br/>
        Ký hiệu: {invoice.serial}<br/>
        Số: {invoice.no}
      </div>
      <div className="inv-date text-center">
        Ngày {dates[2]} tháng  {dates[1]} năm {dates[0]}
      </div>
      <div className="inv-seller">
        <div className="item-row"><span>Tên người bán</span>: {invoice.seller_name}</div>
        <div className="item-row"><span>Mã số thuế</span>: {invoice.seller_tax_code}</div>
        <div className="item-row"><span>Địa chỉ</span>: {invoice.seller_address}</div>
        <div className="item-row"><span>Điện thoại</span>: {invoice.seller_phone}</div>
      </div>
      <div className="inv-buyer">
        <div className="item-row"><span>Tên người mua</span>: {invoice.buyer_name}</div>
        <div className="item-row"><span>Mã số thuế</span>: {invoice.buyer_tax_code}</div>
        <div className="item-row"><span>Địa chỉ</span>: {invoice.buyer_address}</div>
        <div className="item-row"><span>Điện thoại</span>: {invoice.buyer_phone}</div>
      </div>
      <div className="inv-payment">
        <div className="item-row"><span>Đồng tiền thanh toán</span>: {invoice.currency}</div>
      </div>
      <div className="inv-table-container">
        <table className="inv-table">
          <thead>
          <tr>
            <th>STT</th>
            <th>Tên hàng hóa, dịch vụ</th>
            <th>Đơn vị tính</th>
            <th>Số lượng</th>
            <th>Đơn giá</th>
            <th>Thuế suất</th>
            <th>Thành tiền (chưa có thuế GTGT)</th>
            <th>Tiền thuế GTGT</th>
            <th>Thành tiền</th>
          </tr>
          <tr>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
            <th>6</th>
            <th>7=4x5</th>
            <th>8=7x6</th>
            <th>9=8+7</th>
          </tr>
          </thead>
          <tbody>
          {
            invoice.items.map((item, index) => (
              <tr key={index}>
                <td>{item.line_number}</td>
                <td>{item.item_name}</td>
                <td className="text-center">{item.unit_name}</td>
                <td className="text-right">{formatCurrencyValue(item.quantity)}</td>
                <td className="text-right">{formatCurrencyValue(item.unit_price)}</td>
                <td className="text-right">{formatCurrencyValue(item.vat_rate)}</td>
                <td className="text-right">{formatCurrencyValue(item.item_total_amount_without_vat)}</td>
                <td className="text-right">{formatCurrencyValue(item.vat_amount)}</td>
                <td className="text-right">{formatCurrencyValue(item.total_amount_with_vat)}</td>
              </tr>
            ))
          }
          <tr>
            <td colSpan="8" className="text-right">Tổng tiền chưa có thuế GTGT:</td>
            <td className="text-right">{formatCurrencyValue(invoice.total_amount_without_vat)}</td>
          </tr>
          <tr>
            <td colSpan="8" className="text-right">Tổng tiền thuế GTGT:</td>
            <td className="text-right">{formatCurrencyValue(invoice.total_vat_amount)}</td>
          </tr>
          <tr>
            <td colSpan="8" className="text-right">Tổng tiền thanh toán đã có thuế GTGT:</td>
            <td className="text-right">{formatCurrencyValue(invoice.total_amount_with_vat)}</td>
          </tr>
          <tr>
            <td colSpan="9" className="text-left">Tổng số tiền thành chữ: <strong>{invoice.total_amount_in_words}</strong></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className="inv-signatures">
        <div className="inv-seller-signature">
          <div className="--title">Người mua hàng</div>
        </div>
        <div className="inv-buyer-signature">
          <div className="--title">Người bán hàng</div>
        </div>
      </div>
      <div className="inv-note">
        (Cần kiểm tra, đối chiếu khi lập, nhận hóa đơn)
      </div>
    </div>
  );
};

export default InvoicePreview;
