import React, {Fragment} from 'react';
import {Breadcrumb, Button, Input, message} from 'antd';
import {getErrorMessage} from '../../../../common/helpers';
import {history} from '../../../../history';
import {addKeyIdAPI} from '../../../../api/keyIds';

class UpsertKeyId extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      formData: {
        name: '',
        keyId: '',
      },
    };
  }

  setFormData = (values) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        ...values,
      }
    }));
  };

  handleInputTextChange = (field) => (event) => {
    this.setFormData({
      [field]: event.target.value,
    });
  };

  handleSubmit = () => {
    const {formData} = this.state;
    if (!formData.name) {
      message.error('Nhập tên nhà cung cấp');
      return;
    }
    if (!formData.keyId) {
      message.error('Nhập KeyId');
      return;
    }
    const postData = {...formData};
    this.setState({isSubmitting: true});
    addKeyIdAPI(postData).then(() => {
      message.success('Đã lưu!');
      history.push('/manage/key-ids');
    }).catch(error => {
      this.setState({isSubmitting: false});
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {formData, isSubmitting} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Thêm KeyID</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content templates">
          <div className="revocation-form">
            <div className="form-input mb-10">
              <label>Tên nhà cung cấp</label>
              <Input
                value={formData.name}
                onChange={this.handleInputTextChange('name')}
              />
            </div>
            <div className="form-input mb-10">
              <label>KeyID</label>
              <Input
                value={formData.keyId}
                onChange={this.handleInputTextChange('keyId')}
              />
            </div>
            <Button
              type="primary"
              loading={isSubmitting}
              onClick={this.handleSubmit}
            >
              Lưu lại
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UpsertKeyId;
