import React, {Fragment} from 'react';
import {Col, Input, Row} from 'antd';
import FolderOpenOutlined from '@ant-design/icons/lib/icons/FolderOpenOutlined';
import Broadcaster from '../../../../common/helpers/broadcaster';

const handleOpenSelectNode = (key, isProductProperty, productKey, handleInputChange) => () => {
  Broadcaster.broadcast('OPEN_SELECT_XML_NODE', {
    isProductProperty: isProductProperty && key !== 'productKey',
    productKey,
    resolve: handleInputChange(key, isProductProperty),
  });
};

const FormInput = ({value, label, productKey, alias, isProductProperty, handleInputChange}) => {
  const disabled = isProductProperty && !productKey && alias !== 'productKey';
  return (
    <div className="form-input mb-10">
      <label>{label}</label>
      <Input
        value={value}
        readOnly={true}
        disabled={disabled}
        onClick={handleOpenSelectNode(alias, isProductProperty, productKey, handleInputChange)}
        addonAfter={
          <FolderOpenOutlined
            onClick={disabled ? undefined : handleOpenSelectNode(alias, isProductProperty, productKey, handleInputChange)}
          />
        }
      />
    </div>
  );
};

const FormGroup = ({title, formData, data, productKey, unmapProperties, handleInputChange, ...others}) => {
  if (!unmapProperties.length) {
    return null;
  }
  const {isProductProperty} = others;
  return (
    <Fragment>
      <h3>{title}</h3>
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
      }}>
        {
          isProductProperty &&
          <Col
            xs={{span: 12}} md={{span: 6}}
          >
            <FormInput
              label="Sản phẩm/dịch vụ"
              alias="productKey"
              productKey={productKey}
              handleInputChange={handleInputChange}
              value={productKey ? (Array.isArray(data[productKey]) ? data[productKey].length + ' sản phẩm' : '1 sản phẩm') : ''}
              {...others}
            />
          </Col>
        }
        {
          unmapProperties.map((item, index) => {
            let value = formData[item.alias] && data[formData[item.alias]] !== undefined ? data[formData[item.alias]] : '';
            if (isProductProperty && item.alias !== 'productKey') {
              if (!productKey || !formData[item.alias]) {
                value = '';
              } else {
                const key = `${formData[item.alias]}`.startsWith('[') ? `${productKey}${formData[item.alias]}` : `${productKey}.${formData[item.alias]}`;
                value = data[key] !== undefined ? data[key] : '';
              }
            }
            return (
              <Col
                xs={{span: 12}} md={{span: 6}}
                key={index}
              >
                <FormInput
                  label={item.name}
                  alias={item.alias}
                  productKey={productKey}
                  handleInputChange={handleInputChange}
                  value={value}
                  {...others}
                />
              </Col>
            );
          })
        }
      </Row>
    </Fragment>
  );
};

class InvoiceForm extends React.PureComponent {
  handlePreview = (values) => {
    console.log(values);
  };

  handleInputChange = (alias, isProductProperty) => (key) => {
    const {formData, setFormData} = this.props;
    if (!isProductProperty) {
      setFormData({
        invoiceProperties: {
          ...formData.invoiceProperties,
          [alias]: key,
        }
      });
    } else {
      if (alias !== 'productKey') {
        setFormData({
          productProperties: {
            ...formData.productProperties,
            [alias]: key,
          }
        });
      } else {
        setFormData({
          productKey: key,
        });
      }
    }
  };

  render() {
    const {data, formData, unmapInvoiceProperties, unmapProductProperties} = this.props;
    return (
      <div className="common-form invoice-form">
        <FormGroup
          title="Hóa đơn"
          data={data}
          formData={formData.invoiceProperties}
          unmapProperties={unmapInvoiceProperties}
          handleInputChange={this.handleInputChange}
        />
        <FormGroup
          title="Sản phẩm"
          data={data}
          formData={formData.productProperties}
          productKey={formData.productKey}
          unmapProperties={unmapProductProperties}
          handleInputChange={this.handleInputChange}
          isProductProperty={true}
        />
      </div>
    );
  }
}

export default InvoiceForm;
