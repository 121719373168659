import {publicUrl} from '../helpers';

export const ALLOWED_UPLOAD_FILE_EXTENSIONS = [
  'inv',
  'xml',
  'pdf',
  'doc',
  'docx',
  'zip',
  'jpg',
  'png',
  'gif',
  'txt',
  'htm',
  'html',
  'xslt',
];

export const MAX_UPLOAD_FILE_SIZE = {
  value: 10 * 1024 * 1024,
  textValue: '10MB',
};

export const INVOICE_TYPE = {
  PURCHASE: 0, // hoa don dau vao
  SALE: 1, // hoa don dau ra
};

export const PAYMENT_STATUS = {
  UNPAID: 0,
  PAID: 1,
};

export const GENDER = {
  MALE: 1,
  FEMALE: 0,
};

export const STORAGE_DELETE_RULE = {
  KEEP_FILES: 0,
  DELETE_FILES: 1,
};

export const STORAGE_STATUS = {
  NOT_READY: 0,
  READY: 10,
  INVALID: 20,
};

export const STORAGE_TYPE = {
  INTERNAL_SERVER: {
    id: 1,
    name: 'Server ' + process.env.REACT_APP_NAME,
    defaultDeleteRule: STORAGE_DELETE_RULE.DELETE_FILES,
    avatar: publicUrl('/assets/images/icon-cloud-server.svg'),
    description: 'Lưu trữ file hóa đơn trên máy chủ của ' + process.env.REACT_APP_NAME,
  },
  GOOGLE_DRIVE: {
    id: 2,
    name: 'Google Drive',
    defaultDeleteRule: STORAGE_DELETE_RULE.KEEP_FILES,
    avatar: publicUrl('/assets/images/google-drive-logo.png'),
    description: 'Lưu trữ file hóa đơn trên tài khoản Google Drive của bạn',
  },
};

export const STORAGE_FILE_SYNC_STATUS = {
  NOT_UPLOADED_YET: 0,
  UPLOADED: 10,
  ERROR: 20,
  NOT_FOUND: 30,
};

export const STORAGE_TYPE_BY_ID = Object.values(STORAGE_TYPE).reduce(function (result, item) {
  result[item.id] = item;
  return result;
}, {});

export const USER_STATUS = {
  ACTIVE: {
    id: 0,
    name: 'Hoạt động',
    colorClassName: 'text-green',
  },
  BLOCKED: {
    id: 5,
    name: 'Bị cấm',
    colorClassName: 'text-red',
  },
  DELETED: {
    id: 9,
    name: 'Đã xóa',
    colorClassName: 'text-gray',
  },
};

export const USER_STATUS_BY_ID = Object.values(USER_STATUS).reduce(function (result, item) {
  result[item.id] = item;
  return result;
}, {});

export const NUMBER_FORMAT = {
  DOT_COMMA: 1, // 1.000,1
  COMMA_DOT: 2, // 1,000.1
};

export const TRANSACTION_TYPES = {
  CHARGE: {
    id: 1,
    name: 'Nạp tiền',
    colorClassName: 'text-green',
  },
  WITHDRAW: {
    id: 2,
    name: 'Trừ tiền',
    colorClassName: 'text-red',
  },
  GIFT: {
    id: 3,
    name: 'Quà tặng',
    colorClassName: 'text-blue',
  },
};

export const TRANSACTION_TYPE_BY_ID = Object.values(TRANSACTION_TYPES).reduce(function (result, item) {
  result[item.id] = item;
  return result;
}, {});

export const ACTIVATE_CODE_TYPE = {
  Normal: {
    id: 1,
    name: 'Mã kích hoạt',
  },
  Gift: {
    id: 2,
    name: 'Quà tặng',
  },
  Trial: {
    id: 3,
    name: 'Dùng thử',
  },
};

export const ACTIVATE_CODE_TYPE_BY_ID = Object.values(ACTIVATE_CODE_TYPE).reduce(function (result, item) {
  result[item.id] = item;
  return result;
}, {});

export const ACTIVATE_CODE_STATUS = {
  NotUsed: {
    id: 1,
    name: 'Chưa sử dụng',
    tagColor: '#2db7f5',
  },
  PaymentPending: {
    id: 5,
    name: 'Chờ thanh toán',
    tagColor: '#ff4d4f',
  },
  ApprovedPending: {
    id: 6,
    name: 'Chờ kích hoạt',
    tagColor: '#ffc729',
  },
  Activated: {
    id: 10,
    name: 'Đã kích hoạt',
    tagColor: '#87d068',
  },
};

export const ACTIVATE_CODE_STATUS_BY_ID = Object.values(ACTIVATE_CODE_STATUS).reduce(function (result, item) {
  result[item.id] = item;
  return result;
}, {});


export const LICENSE_TYPE = {
  Normal: {
    id: 1,
    name: 'Chính thức',
  },
  Gift: {
    id: 2,
    name: 'Quà tặng',
  },
  Trial: {
    id: 3,
    name: 'Dùng thử',
  },
};

export const LICENSE_TYPE_BY_ID = Object.values(LICENSE_TYPE).reduce(function (result, item) {
  result[item.id] = item;
  return result;
}, {});


export const LICENSE_STATUS = {
  NotUsed: {
    id: 1,
    name: 'Chưa sử dụng',
    tagColor: '#2db7f5',
  },
  Used: {
    id: 2,
    name: 'Đã sử dụng',
    tagColor: '#87d068',
  },
  Revoked: {
    id: 3,
    name: 'Đã thu hồi',
    tagColor: '#ff4d4f',
  },
};

export const LICENSE_STATUS_BY_ID = Object.values(LICENSE_STATUS).reduce(function (result, item) {
  result[item.id] = item;
  return result;
}, {});
