import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_STORAGE_SERVICE_API_URL}`;

export const getStorageAccountsAPI = () => {
  return Requester.get(`${API_URL}/accounts/`);
};

export const getStorageAccountDetailAPI = (id) => {
  return Requester.get(`${API_URL}/accounts/${id}`);
};

export const deleteStorageAccountAPI = (id) => {
  return Requester.delete(`${API_URL}/accounts/${id}`);
};

export const addStorageAccountAPI = (data) => {
  return Requester.post(`${API_URL}/accounts/`, data);
};

export const updateStorageAccountAPI = (id, data) => {
  return Requester.post(`${API_URL}/accounts/${id}`, data);
};

export const getStorageFileByFileIdsAPI = (file_ids) => {
  return Requester.get(`${API_URL}/files/`, {file_ids});
};

export const getDownloadFileURL = (token) => {
  return `${API_URL}/download?token=${token}`;
};
