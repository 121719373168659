import React from 'react';
import {connect} from 'react-redux';
import {Button, Modal, Select} from 'antd';
import {selectCompanyAC} from '../../../redux/actions/user';
import './SelectTaxCode.scss';

const SelectTaxCodeModal = ({isOpen, handleChange, companies}) => {
  const [value, setValue] = React.useState(companies?.[0]?.id);

  React.useEffect(() => {
    if (!!companies?.length && !value) {
      setValue(companies[0].id);
    }
  }, [companies, value, setValue]);

  const handleSave = () => {
    handleChange(value);
  };

  if (companies.length === 1) {
    return null;
  }

  return (
    <Modal
      title="Chọn mã số thuế làm việc"
      visible={isOpen}
      width={350}
      footer={[
        <Button
          key="import"
          type="primary"
          onClick={handleSave}
          disabled={!value}
        >
          Tiếp tục
        </Button>,
      ]}
    >
      <div className="modal-content">
        {
          !!companies?.length &&
          <Select
            className="select-tax-code"
            bordered={false}
            value={value}
            onChange={setValue}
            style={{width: 300}}
            placeholder="Chọn mã số thuế làm việc"
          >
            {
              companies.map((item, index) => (
                <Select.Option value={item.id} key={index}>
                  <div className="tax-code-item">
                    <div className="com-name">{item.name}</div>
                    <div className="com-tax-code">{item.tax_code}</div>
                  </div>
                </Select.Option>
              ))
            }
          </Select>
        }
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  companies: state.user.companies.list,
  isOpen: state.user.companies.isLoaded && !!state.user.companies.list.length && !state.user.companies.selectedCompany,
});

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => {
    dispatch(selectCompanyAC(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectTaxCodeModal);
