import React from 'react';
import {connect} from 'react-redux';
import {getCurrentUserAC} from '../redux/actions/user';
import {Skeleton} from 'antd';

class CheckingUser extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(getCurrentUserAC(true));
  }

  render() {
    return (
      <Skeleton active={true}/>
    );
  }
}

export default connect()(CheckingUser);
