import React, {Fragment} from 'react';
import {InboxOutlined} from '@ant-design/icons';
import {Breadcrumb, Button, Input, message, Upload} from 'antd';
import {getErrorMessage} from '../../../../common/helpers';
import {history} from '../../../../history';
import {addRevocationAPI} from '../../../../api/revocations';

const {Dragger} = Upload;

class UploadRevocation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      data: null,
      fileContent: null,
      formData: {
        name: '',
      },
    };
  }

  setFormData = (values) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        ...values,
      }
    }));
  };

  handleInputTextChange = (field) => (event) => {
    this.setFormData({
      [field]: event.target.value,
    });
  };

  beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = e => {
      const fileContent = e.target.result;
      this.handleParseFileAsync(fileContent).then(state => {
        this.setState(state);
      }).catch(error => {
        message.error(getErrorMessage(error));
      });
    };
    reader.readAsText(file);
    return false;
  };

  handleParseFileAsync = async (fileContent) => {
    if (!fileContent) {
      throw new Error('No data');
    }
    const arr = `${fileContent}`.split('\n');
    const list = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].trim().startsWith('Serial Number') && arr[i + 1] && arr[i + 1].trim().startsWith('Revocation Date')) {
        const a1 = arr[i].trim().split(':');
        const a2 = arr[i + 1].trim().split(':');
        a1.splice(0, 1);
        a2.splice(0, 1);
        list.push({
          serialNumber: a1.join(':').trim(),
          date: a2.join(':').trim(),
        });
      }
    }
    return {
      fileContent,
      data: list,
    };
  };

  handleSubmit = () => {
    const {formData, fileContent, data} = this.state;
    if (!formData.name) {
      message.error('Nhập tên mẫu');
      return;
    }
    const postData = {
      template: {
        ...formData,
        list: data || [],
      },
      fileContent,
    };
    this.setState({isSubmitting: true});
    addRevocationAPI(postData).then(() => {
      message.success('Đã lưu!');
      history.push('/manage/revocations');
    }).catch(error => {
      this.setState({isSubmitting: false});
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {data, formData, isSubmitting} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Upload file chứa chữ ký số bị thu hồi</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content templates">
          {
            !data?.length &&
            <Dragger
              accept=".txt"
              multiple={false}
              beforeUpload={this.beforeUpload}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined/>
              </p>
              <p className="ant-upload-text">Click để chọn hoặc kéo thả file TXT vào đây</p>
            </Dragger>
          }
          {
            data && !data.length &&
            <p className="text-red">Không tìm thấy serial nào.</p>
          }
          {
            !!data?.length &&
            <div className="revocation-form">
              <div className="form-input mb-10">
                <label>Tên file</label>
                <Input
                  value={formData.name}
                  onChange={this.handleInputTextChange('name')}
                />
              </div>
              <Button
                type="primary"
                loading={isSubmitting}
                onClick={this.handleSubmit}
              >
                Lưu lại
              </Button>
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

export default UploadRevocation;
