import React, {Fragment} from 'react';
import {publicUrl} from '../../../common/helpers';
import {Button} from 'antd';
import {
  CheckOutlined,
  DoubleRightOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
  SmileOutlined
} from '@ant-design/icons';

class Storage extends React.PureComponent {
  state = {
    page: 1,
  };

  prevPage = () => {
    this.setState({page: 1});
  };

  nextPage = () => {
    this.setState({page: 2});
  };

  render() {
    const {setStep} = this.props;
    const {page} = this.state;
    return (
      <div className="step two-col storage">
        <div className="col col-img">
          {
            page === 1 &&
            <figure style={{backgroundImage: `url(${publicUrl('/assets/images/banner/send-to-google-drive.png')})`}}/>
          }
          {
            page === 2 &&
            <figure
              className="fit round"
              style={{backgroundImage: `url(${publicUrl('/assets/images/banner/storage.jpg')})`}}
            />
          }
        </div>
        <div className="col col-main">
          <div className="--title">Lưu trữ</div>
          <div className="--content">
            {
              page === 1 &&
              <Fragment>
                <p>
                  Đầu tiên, bạn cần thiết lập phương thức lưu trữ các file hóa đơn của mình. Chúng tôi cung cấp cho bạn
                  2 lựa chọn:
                </p>
                <p><CheckOutlined/> Lưu trữ trên Máy chủ của chúng tôi</p>
                <p><CheckOutlined/> Lưu trữ trên Google Drive của bạn</p>
                <p>Bạn có thể chỉ dùng 1 hoặc cả 2 phương thức lưu trữ nhé!</p>
              </Fragment>
            }
            {
              page === 2 &&
              <Fragment>
                <p>
                  Để đơn giản cho bạn, chúng tôi sẽ thiết lập mặc định <strong>Lưu trữ trên Máy chủ của chúng
                  tôi</strong> <SmileOutlined/>.
                </p>
                <p>
                  Bạn cũng có thể vào mục <strong>Thiết lập</strong> <DoubleRightOutlined/> <strong>Phương thức lưu
                  trữ</strong> để thêm phương thức lưu trữ Google Drive sau nhé.
                </p>
                <p>
                  <small>
                    <InfoCircleOutlined/> Nếu không thích lưu trữ trên máy chủ của chúng tôi, bạn cũng có thể xóa nó đi
                    trong mục Thiết lập nhé.
                  </small>
                </p>
              </Fragment>
            }
          </div>
          <div className="--footer two-button">
            {
              page === 1 &&
              <Fragment>
                <Button onClick={setStep('introduction')}>
                  <LeftOutlined/> Quay lại
                </Button>
                <Button type="primary" onClick={this.nextPage}>
                  Tiếp tục <RightOutlined/>
                </Button>
              </Fragment>
            }
            {
              page === 2 &&
              <Fragment>
                <Button onClick={this.prevPage}>
                  <LeftOutlined/> Quay lại
                </Button>
                <Button type="primary" onClick={setStep('create-invoice')}>
                  Ok, tôi hiểu <RightOutlined/>
                </Button>
              </Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Storage;
