import Requester from '../../common/network/Requester';
const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/manage`;

export const getUsersAPI = (params) => {
  return Requester.get(`${API_URL}/users`, params);
};

export const getUserDetailAPI = (id, params) => {
  return Requester.get(`${API_URL}/users/${id}`, params);
};

export const createUserAPI = (data) => {
  return Requester.post(`${API_URL}/users`, data);
};

export const updateUserAPI = (id, data) => {
  return Requester.put(`${API_URL}/users/${id}`, data);
};
