import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {forgotPasswordAPI, loginAPI} from '../../../api/auth';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Form, Input, message} from 'antd';
import HyperLink from '../../../common/components/HyperLink/HyperLink';
import {loginSuccessAC} from '../../../redux/actions/user';
import {Link} from 'react-router-dom';
import {getErrorMessage, getQueryParams} from '../../../common/helpers';

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isForgotPassword: false,
    };
  }

  handleSubmit = (values) => {
    if (this.state.isForgotPassword) {
      this.handleForgotPassword(values);
    } else {
      this.handleLogin(values);
    }
  };

  toggleForgotPassword = () => {
    this.setState(prevState => {
      return {
        isForgotPassword: !prevState.isForgotPassword,
      };
    });
  };

  handleForgotPassword = (formData) => {
    this.setState({
      isSubmitting: true,
    });
    forgotPasswordAPI(formData).then(() => {
      this.setState({
        isSubmitting: false,
        isForgotPassword: false,
      });
      message.success('Thành công! Vui lòng kiểm tra email để đặt lại mật khẩu!');
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  handleLogin = (formData) => {
    const {location: {search}} = this.props;
    this.setState({
      isSubmitting: true,
    });
    const queryParams = getQueryParams(search);
    const extraData = {};
    if (queryParams.clientId) {
      extraData.clientId = queryParams.clientId;
    }
    if (queryParams.machineId) {
      extraData.machineId = queryParams.machineId;
    }
    loginAPI({...formData, ...extraData}).then(res => {
      this.setState({
        isSubmitting: false,
      });
      this.props.dispatch(loginSuccessAC({
        ...res.data,
        ...extraData,
        remember: !!formData.remember,
      }));
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  render() {
    const {isSubmitting, isForgotPassword} = this.state;
    return (
      <Form
        className="login-form common-form"
        onFinish={this.handleSubmit}
      >
        <Form.Item
          label={isForgotPassword ? 'Tên truy cập hoặc email' : 'Tên truy cập'}
          name="username"
          rules={[{
            required: true,
            message: 'Vui lòng nhập tên truy cập!'
          }]}
        >
          <Input
            prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            placeholder="Tên truy cập hoặc email"
          />
        </Form.Item>
        {
          !isForgotPassword &&
          <Fragment>
            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{
                required: true,
                message: 'Vui lòng nhập mật khẩu!'
              }]}
            >
              <Input
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                type="password"
                placeholder="Mật khẩu"
              />
            </Form.Item>
            {/*<Form.Item name="remember" valuePropName="checked">*/}
            {/*<Checkbox>Ghi nhớ đăng nhập</Checkbox>*/}
            {/*</Form.Item>*/}
            <HyperLink className="login-form-forgot" onClick={this.toggleForgotPassword}>
              Quên mật khẩu?
            </HyperLink>
            <Form.Item className="form-actions">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={isSubmitting}
              >
                Đăng nhập
              </Button>
              <div className="other-option">
                Bạn chưa có tài khoản? <Link to={'/register'}>Đăng ký ngay!</Link>
              </div>
            </Form.Item>
          </Fragment>
        }
        {
          isForgotPassword &&
          <Fragment>
            <HyperLink onClick={this.toggleForgotPassword}>
              Đăng nhập
            </HyperLink>
            <Form.Item className="form-actions">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={isSubmitting}
              >
                Lấy mật khẩu
              </Button>
              <div className="other-option">
                Bạn chưa có tài khoản? <Link to={'/register'}>Đăng ký ngay!</Link>
              </div>
            </Form.Item>
          </Fragment>
        }
      </Form>
    );
  }
}

export default connect()(Login);
