import {detectNumberFormat, formatNumber} from '../../../common/helpers/numberFormat';

const numberKeys = [
  // invoice
  'no',
  'total_amount_without_vat',
  'total_vat_amount',
  'total_amount_with_vat',
  'total_payment_amount',
  // product
  'line_number',
  'unit_price',
  'quantity',
  'item_total_amount_without_vat',
  'vat_rate',
  'vat_amount',
  'promotion',
];

const keyToName = {
  'no': 'Số hóa đơn',
  'total_amount_without_vat': 'Tổng số tiền chưa có VAT',
  'total_vat_amount': 'Số tiền VAT',
  'total_amount_with_vat': 'Tổng số tiền đã có VAT',
  'total_payment_amount': 'Tổng số tiền thanh toán',
  'item.line_number': 'STT sản phẩm',
  'item.unit_price': 'Đơn giá sản phẩm',
  'item.quantity': 'Số lượng sản phẩm',
  'item.item_total_amount_without_vat': 'Tổng số tiền sản phẩm',
  'item.vat_rate': '%VAT sản phẩm',
  'item.vat_amount': 'Số tiền VAT của sản phẩm',
  'item.promotion': 'Số tiền khuyến mại của sản phẩm',
};

export const detectInvoiceNumberFormat = (invoice) => {
  const formats = {};
  const count = {};
  const examples = {};

  const detectObject = (obj, parentPath = '') => {
    Object.keys(obj).forEach(key => {
      if (numberKeys.includes(key)) {
        const value = obj[key];
        if (value !== undefined && value !== null && value !== '') {
          const f = detectNumberFormat(value, count);
          if (f !== false) {
            formats[f] = f;
          } else if (value && typeof value === 'string' && (value.indexOf('.') >= 0 || value.indexOf(',') >= 0)) {
            examples[`${parentPath}${key}`] = {
              key: `${parentPath}${key}`,
              name: keyToName[`${parentPath}${key}`],
              value,
            };
          }
        }
      }
    });
  };

  detectObject(invoice);

  if (invoice?.items?.length) {
    invoice.items.forEach(item => {
      if (item) {
        detectObject(item, 'item.');
      }
    });
  }

  return [formats, count, Object.values(examples)];
};

export const formatInvoiceNumber = (invoice, numberFormat) => {
  const format = (obj) => {
    Object.keys(obj).forEach(key => {
      if (numberKeys.includes(key)) {
        obj[key] = formatNumber(obj[key], numberFormat);
      }
    });
  };

  format(invoice);
  if (invoice?.items?.length) {
    invoice.items.forEach(item => {
      if (item) {
        format(item);
      }
    });
  }
};
