/**
 * Broadcaster service
 * @return {{on: on, off: off, broadcast: broadcast}}
 * @constructor
 */

const HocHTBroadcaster = () => {
  const getEvents = (target = 'self') => {
    let windowObj = window;

    if (target === 'parent' && window.self !== window.top) {
      windowObj = window.parent;
    }

    if (!windowObj._hocht_broadcasterEvents) {
      windowObj._hocht_broadcasterEvents = [];
    }

    return windowObj._hocht_broadcasterEvents;
  };

  const on = (key, callback = null, target = 'self') => {
    if (typeof callback !== 'function') {
      return;
    }
    const events = getEvents(target);
    // Prevent duplicate event
    for (let i = 0; i < events.length; i++) {
      if (events[i].key === key && events[i].callback === callback) {
        return;
      }
    }
    const event = {key, callback};
    events.push(event);
  };

  const off = (key, callback, target = 'self') => {
    if (typeof callback !== 'function') {
      return;
    }
    const events = getEvents(target);
    const n = events.length;
    for (let i = n - 1; i >= 0; i--) {
      if (events[i].key === key && events[i].callback === callback) {
        events.splice(i, 1);
      }
    }
  };

  const broadcast = (key, data = null, target = 'self') => {
    if (!key) {
      return false;
    }
    const events = getEvents(target);
    events.filter(event => event.key === key).forEach(item => item.callback(data));
  };

  return {
    on,
    off,
    broadcast
  };
};

/**
 * @type {{on, off, broadcast}}
 */
const Broadcaster = HocHTBroadcaster();

export default Broadcaster;
