import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, message, Modal} from 'antd';
import {getErrorMessage} from '../../../../common/helpers/index';
import {ContactsOutlined, LockOutlined, MailOutlined, PhoneOutlined, UserOutlined} from '@ant-design/icons';
import {createUserAPI} from '../../../../api/manage/users';

class CreateCustomerModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isSubmitting: false,
      isFetching: false,
      renderKey: 0,
      formData: {},
    };
    this.defaultState = {...this.state};
    this.form = React.createRef();
  }

  handleOpen = () => {
    this.setState({
      ...this.defaultState,
      isOpen: true,
      renderKey: new Date().getTime(),
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  onValuesChange = (changedFields, allFields) => {
    this.setState({
      formData: allFields,
    });
  };

  handleSubmit = (values) => {
    const {onSuccess} = this.props;
    this.setState({
      isSubmitting: true,
    });
    createUserAPI(values).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đã tạo tài khoản khách hàng!');
      this.handleClose();
      if (onSuccess) {
        onSuccess();
      }
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  submit = () => {
    this.form.current.submit();
  };

  render() {
    const {isOpen, renderKey} = this.state;
    return (
      <Modal
        title="Tạo tài khoản khách hàng"
        visible={isOpen}
        width={600}
        okText="Tạo tài khoản"
        cancelText="Hủy bỏ"
        maskClosable={false}
        onOk={this.submit}
        onCancel={this.handleClose}
      >
        <Form
          className="upsert-storage-form common-form"
          ref={this.form}
          key={renderKey}
          onFinish={this.handleSubmit}
          onValuesChange={this.onValuesChange}
        >
          <Form.Item
            label="Họ tên"
            name="name"
            rules={[{
              required: true,
              message: 'Vui lòng nhập họ tên!'
            }]}
          >
            <Input
              prefix={<ContactsOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{
              required: true,
              message: 'Vui lòng nhập email!'
            }]}
          >
            <Input
              prefix={<MailOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            />
          </Form.Item>
          <Form.Item
            label="Số điện thoại"
            name="phone"
          >
            <Input
              prefix={<PhoneOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            />
          </Form.Item>
          <Form.Item
            label="Tên truy cập"
            name="username"
            rules={[{
              required: true,
              message: 'Vui lòng nhập tên truy cập!'
            }]}
          >
            <Input
              prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
            />
          </Form.Item>
          <Form.Item
            label="Mật khẩu"
            name="password"
            rules={[{
              required: true,
              message: 'Vui lòng nhập mật khẩu!'
            }]}
          >
            <Input
              prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
              type="password"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

CreateCustomerModal.propTypes = {
  onSuccess: PropTypes.func,
};

export default CreateCustomerModal;
