import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {formatCurrency, formatCurrencyValue, formatDate, getErrorMessage} from '../../../common/helpers/index';
import {Breadcrumb, Empty, message, Modal, Pagination, Skeleton, Table, Tag} from 'antd';
import {deleteActivateCodeAPI, getActivateCodesAPI} from '../../../api/manage/activateCodes';
import ActivateCodeSearch from '../../../common/components/FilterAndSearch/ActivateCodeSearch';
import CreateActivateCodeModal from './CreateActivateCodeModal';
import {
  ACTIVATE_CODE_STATUS,
  ACTIVATE_CODE_STATUS_BY_ID,
  ACTIVATE_CODE_TYPE_BY_ID
} from '../../../common/constants/common';
import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import HyperLink from '../../../common/components/HyperLink/HyperLink';
import ActivateCodeModal from './ActivateCodeModal';

class ActivateCodes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchParams: {
        role_id: null,
      },
      pagination: {
        page_number: 1,
        page_size: 10,
        total: 0,
      },
      isLoading: true,
    };
    this.createActivateCodeModal = React.createRef();
    this.activateCodeModal = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  columns = [
    {
      title: 'Loại mã',
      dataIndex: 'type',
      align: 'center',
      width: 120,
      ellipsis: true,
      render: value => <span>{ACTIVATE_CODE_TYPE_BY_ID[value]?.name}</span>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      align: 'center',
      width: 150,
      ellipsis: true,
      render: (value) => {
        const status = ACTIVATE_CODE_STATUS_BY_ID[value];
        if (!status) {
          return null;
        }
        return (
          <Tag color={status.tagColor}>{status.name}</Tag>
        );
      },
    },
    {
      title: 'Mã kích hoạt',
      dataIndex: 'code',
      align: 'center',
      width: 120,
    },
    {
      title: 'Số hóa đơn',
      dataIndex: 'invoice_qty',
      align: 'right',
      width: 150,
      ellipsis: true,
      render: value => <span>{formatCurrencyValue(value)}</span>,
    },
    {
      title: 'Số tiền thanh toán',
      dataIndex: 'payment_amount',
      align: 'right',
      width: 180,
      ellipsis: true,
      render: value => <span>{formatCurrency(value)}</span>,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      align: 'center',
      ellipsis: true,
    },
    {
      title: 'Tạo bởi',
      dataIndex: ['created_by', 'name'],
      align: 'center',
      width: 180,
      ellipsis: true,
      render: (value, record) => (
        <div title={`ID: ${record?.created_by?.id}, Email: ${record?.created_by?.email}`}>{value}</div>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      align: 'center',
      render: value => <span>{formatDate(value, 'DD/MM/YYYY HH:mm:ss')}</span>,
      width: 180,
    },
    {
      title: 'Sử dụng bởi',
      dataIndex: ['used_by', 'name'],
      align: 'center',
      width: 180,
      ellipsis: true,
      render: (value, record) => (
        <div title={`ID: ${record?.used_by?.id}, Email: ${record?.used_by?.email}`}>{value}</div>
      ),
    },
    {
      title: 'Ngày sử dụng',
      dataIndex: 'used_at',
      align: 'center',
      render: value => <span>{formatDate(value, 'DD/MM/YYYY HH:mm:ss')}</span>,
      width: 180,
    },
    {
      title: 'Thao tác',
      key: 'actions',
      fixed: 'right',
      align: 'center',
      width: 120,
      render: (value, record) => {
        return (
          <div className="actions text-center">
            {
              record.status === ACTIVATE_CODE_STATUS.NotUsed.id &&
              <HyperLink
                title="Xóa"
                onClick={this.confirmDelete(record)}
                className="text-red"
              >
                <DeleteOutlined/> Xóa
              </HyperLink>
            }
          </div>
        );
      },
    },
  ];

  handleTableChange = (page, pageSize) => {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page_number: page,
        page_size: pageSize,
      }
    }), this.getData);
  };

  getData = () => {
    this.setState({
      isLoading: true,
    });
    this.handleGetData().then(res => {
      this.setState({
        isLoading: false,
        data: res?.data?.page_data || [],
        pagination: res?.data?.page_info,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isLoading: false,
        data: [],
      });
    });
  };

  handleGetData = async () => {
    const {pagination, searchParams} = this.state;
    const params = {
      page_number: pagination.page_number,
      page_size: pagination.page_size,
      ...searchParams,
    };
    let result = null;
    while (params.page_number >= 1) {
      result = await getActivateCodesAPI(params);
      const {data: {page_data, page_info}} = result;
      if (!page_data.length && page_info.page_number > 1) {
        params.page_number = page_info.page_number - 1;
      } else {
        break;
      }
    }
    return result;
  };

  handleSearch = (params) => {
    this.setState(prevState => {
      return {
        searchParams: params,
        pagination: {
          ...prevState.pagination,
          page_number: 1,
        }
      };
    }, this.getData);
  };

  handleShowAdd = () => {
    this.createActivateCodeModal.current.handleOpen();
  };

  handleShowActivate = () => {
    this.activateCodeModal.current.handleOpen();
  };

  confirmDelete = (item) => () => {
    Modal.confirm({
      title: 'Xóa mã',
      icon: <ExclamationCircleOutlined/>,
      content: <div>Mã kích hoạt <strong>{item.code}</strong> sẽ bị xóa. Bạn có chắc?</div>,
      okText: 'Xóa',
      okType: 'danger',
      cancelText: 'Hủy bỏ',
      onOk: () => {
        return this.handleDelete(item);
      },
    });
  };

  handleDelete = async (item) => {
    try {
      await deleteActivateCodeAPI(item.id);
      message.info('Đã xóa');
      this.getData();
    } catch (e) {
      message.error(getErrorMessage(e));
    }
  };

  render() {
    const {user} = this.props;
    const {isLoading, data, pagination, searchParams} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Mã kích hoạt</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content tickets">
          <ActivateCodeSearch
            handleSearch={this.handleSearch}
            handleAdd={this.handleShowAdd}
            handleActivate={this.handleShowActivate}
            searchParams={searchParams}
          />
          {
            isLoading && !data.length &&
            <Skeleton/>
          }
          {
            !isLoading && !data.length &&
            <Empty description="Không có kết quả"/>
          }
          {
            !!data.length &&
            <Fragment>
              <Table
                size="small"
                className="common-table"
                columns={this.columns}
                bordered={true}
                rowKey={record => record.id}
                dataSource={data}
                pagination={false}
                loading={isLoading}
                scroll={{x: 1200}}
              />
              <div className="table-footer">
                <Pagination
                  total={pagination.total}
                  pageSize={pagination.page_size}
                  current={pagination.page_number}
                  onChange={this.handleTableChange}
                />
              </div>
            </Fragment>
          }
        </div>
        <CreateActivateCodeModal ref={this.createActivateCodeModal} user={user} onSuccess={this.getData}/>
        <ActivateCodeModal ref={this.activateCodeModal} onSuccess={this.getData}/>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
});

export default connect(mapStateToProps)(ActivateCodes);
