import React from 'react';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  PaperClipOutlined,
  SyncOutlined
} from '@ant-design/icons';
import HyperLink from '../HyperLink/HyperLink';
import './ListFiles.scss';

const DownloadableItem = ({item, children}) => (
  <a className="file-name" href={item.viewUrl} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const NotDownloadableItem = ({children}) => (
  <div className="file-name">
    {children}
  </div>
);

const ListFileItem = ({item, handleDeleteFile}) => {
  let itemIcon = null;
  const Wrapper = item.viewUrl && item.ext === 'pdf' ? DownloadableItem : NotDownloadableItem;
  if (item.ext === 'pdf') {
    itemIcon = <FilePdfOutlined/>;
  } else if (item.ext === 'xml') {
    itemIcon = <FileExcelOutlined/>;
  } else {
    itemIcon = <PaperClipOutlined/>;
  }
  return (
    <div className="file-item">
      <Wrapper item={item}>
        {itemIcon} {item.name}
      </Wrapper>
      <div className="actions">
        {
          item.isUploading &&
          <SyncOutlined spin={true}/>
        }
        {
          item.url &&
          <a href={item.url} className="icon" target="_blank" rel="noopener noreferrer">
            <DownloadOutlined/>
          </a>
        }
        {
          handleDeleteFile &&
          <HyperLink className="icon" onClick={handleDeleteFile(item.localId)}>
            <DeleteOutlined/>
          </HyperLink>
        }
      </div>
    </div>
  );
};

class ListFiles extends React.PureComponent {
  render() {
    const {files, handleDeleteFile} = this.props;
    if (!files) {
      return null;
    }
    return (
      <div className="list-files">
        {
          files.map((item, index) => (
            <ListFileItem
              key={index}
              item={item}
              handleDeleteFile={handleDeleteFile}
            />
          ))
        }
      </div>
    );
  }
}

ListFiles.propTypes = {
  files: PropTypes.array,
  handleDeleteFile: PropTypes.func,
};

export default ListFiles;
