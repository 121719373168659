import React from 'react';
import {Result} from 'antd';
import {HomeOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

class Error404 extends React.PureComponent {
  render() {
    return (
      <Result
        status="500"
        title="Không tìm thấy trang"
        subTitle="Rất tiếc, trang mà bạn tìm kiếm hiện không có sẵn."
        extra={
          <Link
            className="ant-btn ant-btn-primary"
            to={`/`}
          >
            <HomeOutlined/> Quay về Trang chủ
          </Link>
        }
      />
    );
  }
}

export default Error404;
