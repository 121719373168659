import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  ProfileOutlined,
  SettingOutlined,
  StarOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {Layout, Menu} from 'antd';
import {history} from '../../history';
import {logoutAC} from '../../redux/actions/user';
import LoggedInUser from './LoggedInUser';
import WelcomeModal from '../../pages/welcome/WelcomeModal';
import ROLES from '../../common/constants/roles';
import {hasRole} from '../../common/helpers';
import PLANS from '../../common/constants/plans';
import SelectTaxCode from '../../common/components/SelectTaxCode/SelectTaxCode';
import ActivateCodeModal from '../../pages/activate/ActivateCodeModal';
import SelectTaxCodeModal from '../../common/components/SelectTaxCode/SelectTaxCodeModal';
import HeaderNotification from '../../common/components/HeaderNotification/HeaderNotification';
import './MainLayout.scss';
import {Link} from 'react-router-dom';
import HyperLink from '../../common/components/HyperLink/HyperLink';

const {Header, Sider, Content, Footer} = Layout;

class MainLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      selectedKeys: [],
      broken: false,
      detected: false,
    };
    this.activateModalRef = React.createRef();
  }

  componentDidMount() {
    this.getActivePath();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location !== this.props.location) {
      this.getActivePath();
    }
  }

  getActivePath = () => {
    const {location: {pathname}} = this.props;
    const paths = `${pathname}`.split('/').filter(item => !!item);
    const selectedKeys = [];
    let path = '';
    paths.forEach(p => {
      path += '/' + p;
      selectedKeys.push(path);
    });
    this.setState({
      selectedKeys,
    });
  };

  toggle = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  onCollapse = (collapsed, type) => {
    if (type === 'responsive') {
      this.setState({
        collapsed,
      });
    }
  };

  onBreakpoint = (broken) => {
    const state = {
      broken,
      detected: true,
    };
    if (broken) {
      state.collapsed = true;
    }
    this.setState(state);
  };

  handleClick = (event) => {
    console.log(event);
    if (event.key) {
      history.push(event.key);
    }
  };

  handleActivate = () => {
    this.activateModalRef.current.handleOpen();
  };

  handleLogout = () => {
    this.props.dispatch(logoutAC());
  };

  render() {
    const {children, user} = this.props;
    const {selectedKeys, collapsed, broken, detected} = this.state;
    return (
      <Layout className="main-layout" style={{display: detected ? 'block' : 'none'}}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          breakpoint="lg"
          collapsedWidth={broken ? 0 : 80}
          className="main-sider"
          onCollapse={this.onCollapse}
          onBreakpoint={this.onBreakpoint}
        >
          <div className="main-sider-content">
            <div className="logo">
              {collapsed ? process.env.REACT_APP_SHORT_TEXT_LOGO : process.env.REACT_APP_TEXT_LOGO}
            </div>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={selectedKeys}
              defaultOpenKeys={['invoices']}
              onClick={this.handleClick}
            >
              <Menu.SubMenu
                key="invoices"
                title={
                  <Fragment>
                    <ProfileOutlined/>
                    <span>Hóa đơn</span>
                  </Fragment>
                }
              >
                <Menu.Item key="/invoices/purchase">
                  <span>Hóa đơn đầu vào</span>
                </Menu.Item>
                <Menu.Item key="/invoices/sale">
                  <span>Hóa đơn đầu ra</span>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item key="/emails">
                <MailOutlined/> <span>Hộp thư</span>
              </Menu.Item>
              {/*{*/}
              {/*  user.plan?.id !== PLANS.FREE.id && hasRole(ROLES.NORMAL_USER.id, user.role_id, '>=') &&*/}
              {/*  <Menu.Item key="/sub-users">*/}
              {/*    <TeamOutlined/> <span>Sub users</span>*/}
              {/*  </Menu.Item>*/}
              {/*}*/}
              {
                hasRole(ROLES.NORMAL_USER.id, user.role_id, '>=') &&
                <Menu.SubMenu
                  key="settings"
                  title={
                    <Fragment>
                      <SettingOutlined/>
                      <span>Thiết lập</span>
                    </Fragment>
                  }
                >
                  {
                    user.plan?.id !== PLANS.FREE.id &&
                    <Menu.Item key="/settings/company">
                      <span>Thông tin công ty</span>
                    </Menu.Item>
                  }
                  <Menu.Item key="/settings/tax-codes">
                    <span>Mã số thuế làm việc</span>
                  </Menu.Item>
                  <Menu.Item key="/settings/storage">
                    <span>Phương thức lưu trữ</span>
                  </Menu.Item>
                  <Menu.Item key="/settings/api-keys">
                    <span>API key</span>
                  </Menu.Item>
                </Menu.SubMenu>
              }
              {
                hasRole(ROLES.RESELLER.id, user.role_id, '=') &&
                <Menu.SubMenu
                  key="manage"
                  title={
                    <Fragment>
                      <TeamOutlined/>
                      <span>Quản lý</span>
                    </Fragment>
                  }
                >
                  <Menu.Item key="/manage/customers">
                    <span>Khách hàng</span>
                  </Menu.Item>
                  <Menu.Item key="/manage/activate/codes">
                    <span>Mã kích hoạt</span>
                  </Menu.Item>
                </Menu.SubMenu>
              }
              {
                hasRole(ROLES.MANAGER.id, user.role_id, '>=') &&
                <Menu.SubMenu
                  key="manage"
                  title={
                    <Fragment>
                      <TeamOutlined/>
                      <span>Quản lý</span>
                    </Fragment>
                  }
                >
                  <Menu.Item key="/manage/users">
                    <span>Người dùng</span>
                  </Menu.Item>
                  <Menu.Item key="/manage/activate/codes">
                    <span>Mã kích hoạt</span>
                  </Menu.Item>
                  <Menu.Item key="/manage/licenses">
                    <span>Mã kích hoạt phần mềm</span>
                  </Menu.Item>
                  <Menu.Item key="/manage/templates">
                    <span>Mẫu hóa đơn</span>
                  </Menu.Item>
                  <Menu.Item key="/manage/revocations">
                    <span>Chữ ký số bị thu hồi</span>
                  </Menu.Item>
                  <Menu.Item key="/manage/key-ids">
                    <span>KeyID của NCC CKS</span>
                  </Menu.Item>
                </Menu.SubMenu>
              }
              <Menu.Item key="/account">
                <UserOutlined/> <span>Tài khoản của tôi</span>
              </Menu.Item>
              <Menu.Item key="/support/tickets">
                <MessageOutlined/> <span>Hỗ trợ</span>
              </Menu.Item>
            </Menu>
          </div>
          <div className="upgrade-menu">
            {
              user.plan?.id === PLANS.FREE.id &&
              <Link to="/pricing" className="btn btn-warning">
                <StarOutlined/> Nâng cấp
              </Link>
            }
            <HyperLink className="btn btn-success" onClick={this.handleActivate}>
              <ThunderboltOutlined/> Nhập mã kích hoạt
            </HyperLink>
          </div>
        </Sider>
        <Layout className={`main-layout-container ${collapsed ? 'sider-collapsed' : ''}`}>
          <Header
            className="main-header"
            style={{
              background: '#fff',
              padding: 0
            }}>
            {
              collapsed ? <MenuUnfoldOutlined className="trigger" onClick={this.toggle}/> :
                <MenuFoldOutlined className="trigger" onClick={this.toggle}/>
            }
            <HeaderNotification/>
            <div className="header-components">
              <SelectTaxCode/>
              {/*<Tag color="#2db7f5">{!broken ? 'Phiên bản thử nghiệm' : 'BETA'}</Tag>*/}
              <LoggedInUser
                user={user}
                isMobile={broken}
                handleLogout={this.handleLogout}
              />
            </div>
          </Header>
          <Content
            style={{
              margin: '0 16px',
              minHeight: 280,
            }}
          >
            {children}
          </Content>
          <Footer className="main-footer">
            <p className="copy-right">{process.env.REACT_APP_NAME} (v{process.env.REACT_APP_VERSION}) ©2020. Developed
              by <a href="https://smartbiz.vn" target="_blank" rel="noopener noreferrer">SmartBiz</a>.</p>
          </Footer>
        </Layout>
        {
          broken && !collapsed &&
          <div className="sider-backdrop" onClick={this.toggle}/>
        }
        <WelcomeModal/>
        <SelectTaxCodeModal/>
        <ActivateCodeModal ref={this.activateModalRef}/>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
});

export default connect(mapStateToProps)(MainLayout);
