import React from 'react';
import PropTypes from 'prop-types';
import {CloseOutlined, DeleteOutlined, SaveOutlined} from '@ant-design/icons';
import {Button, Form, message, Popconfirm} from 'antd';
import CheckInvoice from '../CheckInvoice/CheckInvoice';
import BasicInvoiceInfo from './BasicInvoiceInfo';
import SellerInfo from './SellerInfo';
import BuyerInfo from './BuyerInfo';
import AmountsInfo from './AmountsInfo';
import OthersInfo from './OthersInfo';
import InvoiceItems from './InvoiceItems';
import './InvoiceForm.scss';

class InvoiceForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      formValues: {},
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    if (this.props.initialValues) {
      this.setState({
        formValues: this.props.initialValues,
      });
    }
  }

  onValuesChange = (values, allValues) => {
    this.setState({
      formValues: allValues,
    });
  };

  handleSubmit = (values) => {
    const {handleSubmit} = this.props;
    const formData = {...values};
    handleSubmit(formData, this.form.current);
  };

  submit = () => {
    this.form.current.submit();
  };

  onFinishFailed = () => {
    message.error('Vui lòng kiểm tra lại các thông tin còn thiếu');
  };

  render() {
    const {
      isEdit,
      isSubmitting,
      isDeleting,
      xmlFile,
      initialValues,
      handleCancel,
      handleDelete,
      setCheckResult,
    } = this.props;
    const {formValues} = this.state;
    return (
      <Form
        initialValues={{
          ...initialValues,
        }}
        onFinish={this.handleSubmit}
        onFinishFailed={this.onFinishFailed}
        onValuesChange={this.onValuesChange}
        className="common-form invoice-form"
        ref={this.form}
      >
        <BasicInvoiceInfo/>
        <SellerInfo/>
        <BuyerInfo/>
        <div className="form-section">
          <h3>Thông tin hàng hóa/dịch vụ</h3>
          <InvoiceItems/>
        </div>
        <AmountsInfo currency={formValues.currency}/>
        <OthersInfo xmlFile={xmlFile}/>

        <CheckInvoice
          invoice={formValues}
          cert={initialValues?.certs?.[0]}
          xmlFile={xmlFile}
          checkResult={initialValues?.metadata?.check_result}
          setCheckResult={setCheckResult}
        />

        <div className={`form-actions`}>
          {
            isEdit &&
            <div className="btn-group">
              <Popconfirm
                title={<div>Bạn có chắc muốn xóa hóa đơn này không?</div>}
                onConfirm={handleDelete}
                okText="Xóa"
                cancelText="Hủy bỏ"
                okType="danger"
              >
                <Button
                  htmlType="button"
                  danger={true}
                  icon={<DeleteOutlined/>}
                  loading={isDeleting}
                >
                  Xóa hóa đơn
                </Button>
              </Popconfirm>
            </div>
          }
          <div className="btn-group">
            <Button
              type="default"
              htmlType="button"
              icon={<CloseOutlined/>}
              onClick={handleCancel}
            >
              Hủy bỏ
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              icon={<SaveOutlined/>}
              loading={isSubmitting}
            >
              Lưu hóa đơn
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

InvoiceForm.propTypes = {
  isEdit: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isDeleting: PropTypes.bool,
  xmlFile: PropTypes.object,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func,
  setCheckResult: PropTypes.func,
};

InvoiceForm.defaultProps = {
  isEdit: false,
  isSubmitting: false,
  xmlFile: null,
  initialValues: null,
};

export default InvoiceForm;
