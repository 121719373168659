import React, {Fragment} from 'react';
import {history} from '../../../history';
import {Prompt} from 'react-router-dom';
import {INVOICE_TYPE} from '../../../common/constants/common';
import {Breadcrumb, Button, message, Result, Skeleton} from 'antd';
import {deleteInvoiceAPI, getInvoiceAPI, updateInvoiceAPI, updateInvoiceFilesAPI} from '../../../api/invoices';
import {formatDate, getErrorMessage} from '../../../common/helpers';
import InvoiceForm from '../common/InvoiceForm/InvoiceForm';
import {uploadFilesAPI} from '../../../api/files';

class EditInvoice extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: INVOICE_TYPE.PURCHASE,
      isFetching: true,
      isSubmitting: false,
      isSubmitted: false,
      invoice: null,
    };
  }

  componentDidMount() {
    this.detectType();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.detectType();
    }
  }

  detectType = () => {
    const {match: {params}} = this.props;
    if (params.type !== 'sale' && params.type !== 'purchase') {
      history.replace('/invoices/purchase');
      return;
    }
    let type = INVOICE_TYPE.PURCHASE;
    if (params.type === 'sale') {
      type = INVOICE_TYPE.SALE;
    }
    this.setState({
      type,
    }, this.getData);
  };

  getData = () => {
    const {match: {params: {id}}} = this.props;
    this.setState({
      isFetching: true,
    });
    getInvoiceAPI(id).then(res => {
      const invoice = res.data;
      if (invoice?.files) {
        invoice.files.forEach(item => item.uid = item.id);
      }
      if (invoice.signed_date) {
        invoice.signed_date = formatDate(invoice.signed_date, 'YYYY-MM-DD');
      }
      if (invoice.issued_date) {
        invoice.issued_date = formatDate(invoice.issued_date, 'YYYY-MM-DD');
      }
      this.setState({
        invoice: res.data,
        isFetching: false,
      });
    }).catch((error) => {
      this.setState({
        isFetching: false,
      });
      message.error(getErrorMessage(error));
    });
  };

  goBack = (event) => {
    const {type} = this.state;
    if (event) {
      event.preventDefault();
    }
    history.push(`/invoices/${type === INVOICE_TYPE.PURCHASE ? 'purchase' : 'sale'}`);
  };

  handleDelete = () => {
    const {invoice} = this.state;
    this.setState({
      isDeleting: true,
    });
    deleteInvoiceAPI(invoice.id).then((res) => {
      if (res.data) {
        message.info('Đã xóa!');
        this.goBack();
        return;
      }
      this.setState({
        isDeleting: false,
      });
    }).catch(error => {
      this.setState({
        isDeleting: false,
      });
      message.error(getErrorMessage(error));
    });
  };

  handleSubmitAsync = async (values) => {
    const {invoice: {id, metadata}} = this.state;
    const {files, ...others} = values;
    let hasError = false;
    if (metadata?.check_result && !others?.metadata?.check_result) {
      if (!others.metadata) {
        others.metadata = {};
      }
      others.metadata.check_result = metadata.check_result;
    }
    await updateInvoiceAPI(id, others);
    const invoiceFiles = (files || []).filter(item => !item.originFileObj);
    const uploadFiles = (files || []).filter(item => !!item.originFileObj).map(file => file.originFileObj);
    if (uploadFiles.length) {
      try {
        const {data: {success, fail}} = await uploadFilesAPI(uploadFiles);
        if (fail?.length) {
          hasError = true;
        }
        success.forEach(file => {
          invoiceFiles.push(file);
        });
      } catch (e) {
        hasError = true;
      }
    }
    try {
      const {data} = await updateInvoiceFilesAPI(id, invoiceFiles);
      if (!data) {
        hasError = true;
      }
    } catch (e) {
      hasError = true;
    }
    if (hasError) {
      message.warning(`Đã lưu hóa đơn nhưng không cập nhật được một số file, vui lòng kiểm tra lại sau!`);
      return;
    }
    message.success('Đã lưu!');
  };

  handleSubmit = (values) => {
    this.setState({
      isSubmitting: true,
    });
    this.handleSubmitAsync(values).then(() => {
      this.setState({
        isSubmitting: false,
        isSubmitted: true,
      }, this.goBack);
    }).catch(error => {
      this.setState({
        isSubmitting: false,
      });
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {isSubmitting, isSubmitted, invoice, isFetching, type} = this.state;
    return (
      <Fragment>
        <Prompt message="Bạn có chắc muốn rời khỏi trang này?" when={!!invoice && !isSubmitted}/>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item
            href={`/invoices/${type === INVOICE_TYPE.PURCHASE ? 'purchase' : 'sale'}`}
            onClick={this.goBack}
          >
            {type === INVOICE_TYPE.PURCHASE ? 'Hóa đơn đầu vào' : 'Hóa đơn đầu ra'}
          </Breadcrumb.Item>
          <Breadcrumb.Item>Sửa hóa đơn</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content">
          {
            isFetching && !invoice &&
            <Skeleton/>
          }
          {
            !isFetching && !invoice &&
            <Result
              status="warning"
              title="Không tải được thông tin hóa đơn"
              extra={
                <Button type="primary" key="console" onClick={this.getData}>
                  Thử lại
                </Button>
              }
            />
          }
          {
            invoice &&
            <InvoiceForm
              initialValues={invoice}
              isSubmitting={isSubmitting}
              isEdit={true}
              handleCancel={this.goBack}
              handleSubmit={this.handleSubmit}
              handleDelete={this.handleDelete}
            />
          }
        </div>
      </Fragment>
    );
  }
}

export default EditInvoice;
