import React, {Fragment} from 'react';
import {formatCurrencyValue, formatDate, getErrorMessage} from '../../../../common/helpers/index';
import {Breadcrumb, Empty, message, Pagination, Skeleton, Table, Tag} from 'antd';
import {getUsersAPI} from '../../../../api/manage/users';
import UserRole from './UserRole';
import UserSearch from './UserSearch';
import UpdateUserModal from '../UpsertUser/UpdateUserModal';
import {connect} from 'react-redux';
import HyperLink from '../../../../common/components/HyperLink/HyperLink';
import {CrownOutlined, EditOutlined} from '@ant-design/icons';
import PLANS, {PLAN_BY_ID} from '../../../../common/constants/plans';
import UpgradePlanModal from '../../customers/UpsertCustomer/UpgradePlanModal';
import {USER_STATUS, USER_STATUS_BY_ID} from '../../../../common/constants/common';
import TopUpModal from '../UpsertUser/TopUpModal';

class Users extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchParams: {
        role_id: null,
      },
      pagination: {
        page_number: 1,
        page_size: 10,
        total: 0,
      },
      isLoading: true,
    };
    this.emailDetailModal = React.createRef();
    this.updateUserModal = React.createRef();
    this.upgradePlanModal = React.createRef();
    this.topUpModal = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      width: 70,
    },
    {
      title: 'Họ tên',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 200,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      width: 110,
      ellipsis: true,
    },
    {
      title: 'Gói',
      width: 110,
      align: 'center',
      key: 'planName',
      render: (value, record) => {
        const plan = PLAN_BY_ID[record?.plan?.id];
        if (!plan) {
          return null;
        }
        return (
          <Tag color={plan.tagColor}>{plan.id === PLANS.PREMIUM.id && <CrownOutlined/>} {plan.name}</Tag>
        );
      },
    },
    {
      title: 'Số hóa đơn',
      dataIndex: ['plan', 'limits', 'invoice'],
      width: 100,
      align: 'right',
      key: 'invoice_limit',
      render: value => formatCurrencyValue(value)
    },
    {
      title: 'Vai trò',
      dataIndex: 'role_id',
      width: 110,
      id: 'role',
      render: value => <UserRole roleId={value}/>
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      align: 'center',
      width: 100,
      render: value => {
        const status = USER_STATUS_BY_ID[value];
        if (!status) {
          return '-';
        }
        return <span className={status.colorClassName}>{status.name}</span>
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      align: 'center',
      render: value => <span>{formatDate(value, 'DD/MM/YYYY HH:mm:ss')}</span>,
      width: 180,
    },
    {
      title: 'Thao tác',
      key: 'actions',
      width: 120,
      fixed: 'right',
      align: 'center',
      render: (value, record) => {
        const {user} = this.props;
        return (
          <div className="actions text-center">
            {
              user.role_id > record.role_id &&
              <HyperLink
                title="Cập nhật"
                onClick={this.handleShowUpdate(record)}
              >
                <EditOutlined/>
              </HyperLink>
            }
          </div>
        );
      },
    },
  ];

  getRowClassName = (record) => {
    if (record.status === USER_STATUS.BLOCKED.id) {
      return 'is-blocked';
    }
    if (record.status === USER_STATUS.DELETED.id) {
      return 'is-deleted';
    }
    return '';
  };

  handleTableChange = (page, pageSize) => {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page_number: page,
        page_size: pageSize,
      }
    }), this.getData);
  };

  getData = () => {
    this.setState({
      isLoading: true,
    });
    this.handleGetData().then(res => {
      this.setState({
        isLoading: false,
        data: res?.data?.page_data || [],
        pagination: res?.data?.page_info,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isLoading: false,
        data: [],
      });
    });
  };

  handleGetData = async () => {
    const {pagination, searchParams} = this.state;
    const params = {
      page_number: pagination.page_number,
      page_size: pagination.page_size,
      ...searchParams,
    };
    if (!params.role_id || params.role_id === '0') {
      delete params.role_id;
    }
    let result = null;
    while (params.page_number >= 1) {
      result = await getUsersAPI(params);
      const {data: {page_data, page_info}} = result;
      if (!page_data.length && page_info.page_number > 1) {
        params.page_number = page_info.page_number - 1;
      } else {
        break;
      }
    }
    return result;
  };

  handleSearch = (params) => {
    this.setState(prevState => {
      return {
        searchParams: params,
        pagination: {
          ...prevState.pagination,
          page_number: 1,
        }
      };
    }, this.getData);
  };

  handleShowUpdate = (user) => () => {
    this.updateUserModal.current.handleOpen(user.id);
  };

  handleShowUpgrade = (user) => () => {
    this.upgradePlanModal.current.handleOpen(user.id);
  };

  handleShowTopUp = (user) => () => {
    this.topUpModal.current.handleOpen(user.id);
  };

  render() {
    const {user} = this.props;
    const {isLoading, data, pagination, searchParams} = this.state;
    const isSearch = Object.keys(searchParams).length > 0;
    const showSearchBox = data.length > 0 || isSearch;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Người dùng</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content tickets">
          {
            showSearchBox &&
            <UserSearch handleSearch={this.handleSearch} searchParams={searchParams}/>
          }
          {
            isLoading && !data.length &&
            <Skeleton/>
          }
          {
            !isLoading && !data.length &&
            <Empty description="Không có kết quả"/>
          }
          {
            !!data.length &&
            <Fragment>
              <Table
                size="small"
                className="common-table"
                columns={this.columns}
                bordered={true}
                rowKey={record => record.id}
                dataSource={data}
                pagination={false}
                loading={isLoading}
                scroll={{x: 1400}}
                rowClassName={this.getRowClassName}
              />
              <div className="table-footer">
                <Pagination
                  total={pagination.total}
                  pageSize={pagination.page_size}
                  current={pagination.page_number}
                  onChange={this.handleTableChange}
                />
              </div>
            </Fragment>
          }
        </div>
        <UpdateUserModal onSuccess={this.getData} userRoleId={user.role_id} ref={this.updateUserModal}/>
        <UpgradePlanModal
          ref={this.upgradePlanModal}
          onSuccess={this.getData}
          user={user}
          isManager={true}
        />
        <TopUpModal
          ref={this.topUpModal}
          onSuccess={this.getData}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
});

export default connect(mapStateToProps)(Users);
