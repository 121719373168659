import React, {Fragment} from "react";
import {Breadcrumb} from "antd";
import "./Pricing.scss";

class Pricing extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
        </Breadcrumb>
        <div className="main-content tickets">
          <div className="pricing pricing-palden">
            <div className="pricing-item">
              <div className="pricing-deco">
                <svg className="pricing-deco-img" enableBackground="new 0 0 300 100" height="100px" id="Layer_1" preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" y="0px">
                  <path className="deco-layer deco-layer--1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729&#x000A;	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"/>
                  <path className="deco-layer deco-layer--2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729&#x000A;	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"/>
                  <path className="deco-layer deco-layer--3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716&#x000A;	H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"/>
                  <path className="deco-layer deco-layer--4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428&#x000A;	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"/>
                </svg>
                <div className="pricing-price">0<span className="pricing-currency">đ</span>
                </div>
                <h3 className="pricing-title">Miễn phí</h3>
              </div>
              <ul className="pricing-feature-list">
                <li className="pricing-feature">200 hóa đơn</li>
                <li className="pricing-feature">3 mã số thuế làm việc</li>
                <li className="pricing-feature">1 tài khoản sao lưu</li>
                <li className="pricing-feature">Kiểm tra hóa đơn</li>
                <li className="pricing-feature not-available">Hỗ trợ qua hotline 24/7</li>
              </ul>
            </div>
            <div className="pricing-item pricing__item--featured">
              <div className="pricing-deco">
                <svg className="pricing-deco-img" enableBackground="new 0 0 300 100" height="100px" id="Layer_1" preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" y="0px">
                  <path className="deco-layer deco-layer--1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729&#x000A;	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"/>
                  <path className="deco-layer deco-layer--2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729&#x000A;	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"/>
                  <path className="deco-layer deco-layer--3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716&#x000A;	H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"/>
                  <path className="deco-layer deco-layer--4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428&#x000A;	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"/>
                </svg>
                <div className="pricing-price"><span className="pricing-period">từ</span>500.000<span className="pricing-currency">đ</span></div>
                <h3 className="pricing-title">Premium</h3>
              </div>
              <ul className="pricing-feature-list">
                <li className="pricing-feature fw-500">Không giới hạn số hóa đơn</li>
                <li className="pricing-feature">10 mã số thuế làm việc</li>
                <li className="pricing-feature">5 tài khoản sao lưu</li>
                <li className="pricing-feature">Kiểm tra hóa đơn</li>
                <li className="pricing-feature">Hỗ trợ qua hotline 24/7</li>
              </ul>
              <button className="pricing-action">Liên hệ: 0888 222 866</button>
            </div>
            <div className="pricing-item">
              <div className="pricing-deco">
                <svg className="pricing-deco-img" enableBackground="new 0 0 300 100" height="100px" id="Layer_1" preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" y="0px">
                  <path className="deco-layer deco-layer--1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729&#x000A;	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"/>
                  <path className="deco-layer deco-layer--2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729&#x000A;	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"/>
                  <path className="deco-layer deco-layer--3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716&#x000A;	H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"/>
                  <path className="deco-layer deco-layer--4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428&#x000A;	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"/>
                </svg>
                <div className="pricing-price">Liên hệ</div>
                <h3 className="pricing-title">Enterprise</h3>
              </div>
              <ul className="pricing-feature-list">
                <li className="pricing-feature fw-500">Không giới hạn số hóa đơn</li>
                <li className="pricing-feature">Không giới hạn mã số thuế làm việc</li>
                <li className="pricing-feature">Không giới hạn sao lưu</li>
                <li className="pricing-feature">Toàn bộ tính năng Premium</li>
                <li className="pricing-feature fw-500">Tính năng đặc biệt</li>
              </ul>
              <button className="pricing-action">Liên hệ: 0888 222 866</button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Pricing;
