export const GET_USER_SETTINGS_AC = 'GET_USER_SETTINGS_AC';
export const GET_USER_SETTINGS_SUCCESS_AC = 'GET_USER_SETTINGS_SUCCESS_AC';

export const getUserSettingsAC = () => ({
  type: GET_USER_SETTINGS_AC,
});

export const getUserSettingsSuccessAC = (settings) => ({
  type: GET_USER_SETTINGS_SUCCESS_AC,
  settings,
});
