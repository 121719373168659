import {combineReducers} from 'redux';
import auth from './auth';
import companies from './companies';
import info from './info';
import settings from './settings';

export default combineReducers({
  auth,
  companies,
  info,
  settings,
});
