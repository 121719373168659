import React, {forwardRef} from 'react';

const HyperLink = ({onClick, children, className, ...others}, ref) => {
  const handleClick = (event) => {
    event.preventDefault();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <a
      ref={ref}
      href="/"
      onClick={handleClick}
      className={`hyper-link ${className ? className : ''}`}
      {...others}
    >
      {children}
    </a>
  )
};

export default forwardRef(HyperLink);
