import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}`;

export const checkTBPHAPI = (params) => {
  return Requester.get(`${API_URL}/utils/check-tbph`, params);
};

export const checkXMLSignatureAPI = (params) => {
  return Requester.get(`${API_URL}/utils/check-xml-signature`, params);
};

export const getNotificationsAPI = (params) => {
  return Requester.get(`${API_URL}/utils/notifications`, params);
};
