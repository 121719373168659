import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, message, Row} from 'antd';
import {addTaxCodeAPI, updateTaxCodeAPI} from '../../../api/tax_codes';
import {getErrorMessage} from '../../../common/helpers';

class UpsertTaxCode extends React.PureComponent {
  state = {
    isSubmitting: false,
  };

  handleSubmit = (values) => {
    const {selectedItem, onSuccess} = this.props;
    const isEdit = !!selectedItem?.id;
    this.setState({
      isSubmitting: true,
    });
    const handler = () => {
      if (isEdit) {
        return updateTaxCodeAPI(selectedItem.id, values);
      } else {
        return addTaxCodeAPI(values);
      }
    };
    handler().then(() => {
      message.success('Đã lưu!');
      this.setState({
        isSubmitting: false,
      }, onSuccess);
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  render() {
    const {selectedItem, onCancel} = this.props;
    const {isSubmitting} = this.state;
    const isEdit = !!selectedItem?.id;
    return (
      <Form
        initialValues={selectedItem}
        onFinish={this.handleSubmit}
        className="common-form add-tax-code-form"
      >
        <h3>{!isEdit ? 'Thêm' : 'Sửa'} mã số thuế</h3>
        <Row gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}>
          <Col xs={{span: 24}} md={{span: 24}}>
            <Form.Item
              label="Tên công ty"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên công ty',
                },
              ]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 24}}>
            <Form.Item
              label="Tên gợi nhớ/viết tắt"
              name="short_name"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên gợi nhớ',
                },
              ]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 24}}>
            <Form.Item
              label="Mã số thuế"
              name="tax_code"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mã số thuế',
                },
              ]}
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={isSubmitting}>{!isEdit ? 'Thêm' : 'Lưu'}</Button>
          <Button disabled={isSubmitting} onClick={onCancel}>Hủy bỏ</Button>
        </Form.Item>
      </Form>
    );
  }
}

UpsertTaxCode.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

export default UpsertTaxCode;
