import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {getTicketCommentsAPI} from '../../../api/tickets';
import {Comment, List, message, Skeleton} from 'antd';
import {formatDate, getErrorMessage} from '../../../common/helpers';
import NewComment from './NewComment';
import UserAvatar from '../../../common/components/UserInfo/UserAvatar';

class TicketComments extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      data: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.ticketId !== this.props.ticketId) {
      this.getData();
    }
  }

  getData = () => {
    const {ticketId} = this.props;
    this.setState({
      isFetching: true,
    });
    getTicketCommentsAPI(ticketId).then(res => {
      this.setState({
        isFetching: false,
        data: res.data || [],
      });
    }).catch(error => {
      this.setState({
        isFetching: false,
      });
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {ticketId} = this.props;
    const {isFetching, data} = this.state;
    if (isFetching && !data.length) {
      return <Skeleton/>
    }
    if (!data.length) {
      return <NewComment ticketId={ticketId} onSuccess={this.getData}/>
    }
    return (
      <Fragment>
        <List
          className="comment-list"
          header={`${data.length} phản hồi`}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <li>
              <Comment
                author={item?.author?.name}
                avatar={<UserAvatar user={item.author} showName={false}/>}
                content={item.content}
                datetime={formatDate(item.created_at, 'DD/MM/YYYY HH:mm:ss')}
              />
            </li>
          )}
        />
        <NewComment ticketId={ticketId} onSuccess={this.getData}/>
      </Fragment>
    );
  }
}

TicketComments.propTypes = {
  ticketId: PropTypes.any,
};

export default TicketComments;
