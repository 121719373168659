import React from 'react';
import {Col, Form, Input, Row} from 'antd';
import WarningInput from '../../../../common/components/Form/WarningInput';

class SellerInfo extends React.PureComponent {
  render() {
    return (
      <div className="form-section">
        <h3>Thông tin người bán</h3>
        <Row gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}>
          <Col xs={{span: 24}} md={{span: 24}} lg={{span: 24}}>
            <Form.Item
              label="Người bán"
              name="seller_name"
              rules={[{
                required: true,
                message: 'Vui lòng nhập tên người bán!'
              }]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 8}} lg={{span: 8}}>
            <Form.Item
              name="__auto_seller_tax_code"
              className="d-none"
            >
              <WarningInput/>
            </Form.Item>
            <Form.Item
              label="MST người bán"
              name="seller_tax_code"
              rules={[{
                required: true,
                message: 'Vui lòng nhập mã số thuế người bán!'
              }]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 16}} lg={{span: 16}}>
            <Form.Item
              label="Địa chỉ người bán"
              name="seller_address"
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SellerInfo;
