import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, DatePicker, Form, Input, Row} from 'antd';
import {ClearOutlined, MinusOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/vi_VN';

const {RangePicker} = DatePicker;

const rowProps = {
  gutter: {
    xs: 4,
    sm: 8,
    md: 12,
    lg: 24,
  }
};

class InvoiceSearch extends React.PureComponent {
  state = {};
  formEl = React.createRef();

  toggleAdvancedSearch = () => {
    this.setState(prevState => ({
      advancedSearch: !prevState.advancedSearch,
    }));
  };

  onSearch = (values) => {
    const params = {};
    Object.keys(values).forEach(key => {
      if (values[key]) {
        if (key === 'issued_date') {
          if (Array.isArray(values[key]) && values[key].length === 2) {
            params.issued_date = values[key].map(item => item.format('YYYY-MM-DD'));
          }
        } else {
          params[key] = `${values[key]}`.trim();
        }
      }
    });
    if (this.props.handleSearch) {
      this.props.handleSearch(params);
    }
  };

  clearForm = () => {
    const {searchParams, handleSearch} = this.props;
    if (searchParams && Object.keys(searchParams).length > 0 && handleSearch) {
      handleSearch({});
    }
    this.formEl.current.resetFields();
  };

  render() {
    const {advancedSearch} = this.state;
    return (
      <Form
        ref={this.formEl}
        className="search-form common-form"
        onFinish={this.onSearch}
      >
        <Row {...rowProps}>
          <Col xs={{span: 24}} md={{span: advancedSearch ? 12 : 10}}>
            <Form.Item
              name="name"
              label="Tên hóa đơn"
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} sm={{span: 12}} md={{span: advancedSearch ? 6 : 4}}>
            <Form.Item
              name="no"
              label="Số hóa đơn"
            >
              <Input/>
            </Form.Item>
          </Col>
          {
            !advancedSearch &&
            <Col xs={{span: 24}} sm={{span: 12}} md={{span: advancedSearch ? 8 : 8}}>
              <Form.Item label="Thao tác" className="hidden-label">
                <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>
                  Tìm kiếm
                </Button>
                <Button
                  icon={<ClearOutlined/>}
                  onClick={this.clearForm}
                  title="Xóa"
                />
                <Button
                  icon={<PlusOutlined/>}
                  onClick={this.toggleAdvancedSearch}
                  title="Tìm kiếm nâng cao"
                />
              </Form.Item>
            </Col>
          }
          {
            advancedSearch &&
            <Col xs={{span: 24}} sm={{span: 12}} md={{span: 6}}>
              <Form.Item
                name="issued_date"
                label="Ngày lập hóa đơn"
              >
                <RangePicker locale={locale}/>
              </Form.Item>
            </Col>
          }
        </Row>
        {
          advancedSearch &&
          <Fragment>
            <Row {...rowProps}>
              <Col xs={{span: 24}} sm={{span: 12}} md={{span: 6}}>
                <Form.Item
                  name="seller_name"
                  label="Tên nguời bán"
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} sm={{span: 12}} md={{span: 6}}>
                <Form.Item
                  name="seller_tax_code"
                  label="Mã số thuế người bán"
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} sm={{span: 12}} md={{span: 6}}>
                <Form.Item
                  name="buyer_name"
                  label="Tên nguời mua"
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col xs={{span: 24}} sm={{span: 12}} md={{span: 6}}>
                <Form.Item
                  name="buyer_tax_code"
                  label="Mã số thuế người mua"
                >
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row {...rowProps}>
              <Col span={24} className="action-buttons">
                <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>
                  Tìm kiếm
                </Button>
                <Button
                  title="Xóa"
                  icon={<ClearOutlined/>}
                  onClick={this.clearForm}
                />
                <Button
                  title="Thu gọn"
                  icon={<MinusOutlined/>}
                  onClick={this.toggleAdvancedSearch}
                />
              </Col>
            </Row>
          </Fragment>
        }
      </Form>
    );
  }
}

InvoiceSearch.propTypes = {
  searchParams: PropTypes.object,
  handleSearch: PropTypes.func,
};

export default InvoiceSearch;