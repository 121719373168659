import React from 'react';
import {Col, Form, Input, Row} from 'antd';
import WarningInput from '../../../../common/components/Form/WarningInput';
import {isValidDate} from '../../../../common/helpers';

class BasicInvoiceInfo extends React.PureComponent {

  validateDate = async (rule, value) => {
    if (!value) {
      return;
    }
    if (`${value}`.trim().length !== 10) {
      throw new Error();
    }
    if (!isValidDate(value)) {
      throw new Error();
    }
  };

  render() {
    return (
      <div className="form-section">
        <h3>Thông tin cơ bản</h3>
        <Row gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}>
          <Col xs={{span: 24}} md={{span: 16}}>
            <Form.Item
              label="Tên hóa đơn"
              name="name"
              rules={[{
                required: true,
                message: 'Vui lòng nhập tên hóa đơn!'
              }]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 8}}>
            <Form.Item
              label="Số hóa đơn"
              name="no"
              rules={[{
                required: true,
                message: 'Vui lòng nhập số hóa đơn!'
              }]}
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}>
          <Col xs={{span: 12}} md={{span: 6}}>
            <Form.Item
              label="Mẫu số"
              name="pattern"
              rules={[{
                required: true,
                message: 'Vui lòng nhập mẫu số!'
              }]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 12}} md={{span: 6}}>
            <Form.Item
              label="Ký hiệu"
              name="serial"
              rules={[{
                required: true,
                message: 'Vui lòng nhập ký hiệu!'
              }]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 6}}>
            <Form.Item
              name="__auto_issued_date"
              className="d-none"
            >
              <WarningInput/>
            </Form.Item>
            <Form.Item
              label="Ngày tạo"
              name="issued_date"
              rules={[{
                required: true,
                message: 'Vui lòng nhập ngày tạo hóa đơn!'
              }, {
                validator: this.validateDate,
                message: 'Vui lòng nhập ngày hợp lệ (định dạng YYYY-MM-DD)'
              }]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 6}}>
            <Form.Item
              name="__auto_signed_date"
              className="d-none"
            >
              <WarningInput/>
            </Form.Item>
            <Form.Item
              label="Ngày ký"
              name="signed_date"
              rules={[{
                validator: this.validateDate,
                message: 'Vui lòng nhập ngày hợp lệ (định dạng YYYY-MM-DD)'
              }]}
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BasicInvoiceInfo;
