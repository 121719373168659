import React from 'react';
import PropTypes from 'prop-types';
import {SaveOutlined} from '@ant-design/icons';
import {Button, Form, Input, message} from 'antd';
import {getErrorMessage} from '../../../common/helpers/index';
import {updatePasswordAPI} from '../../../api/users';

class SecurityForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      confirmDirty: false,
    };
  }

  validateConfirmPassword = ({getFieldValue}) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject('Mật khẩu không khớp!');
    },
  });

  handleSubmit = (data) => {
    const {onSuccess} = this.props;
    this.setState({
      isSubmitting: true,
    });
    updatePasswordAPI(data).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đã thay đổi mật khẩu, vui lòng đăng nhập lại!');
      onSuccess();
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  render() {
    const {user} = this.props;
    const {isSubmitting} = this.state;
    return (
      <Form
        initialValues={user}
        onFinish={this.handleSubmit}
        className="common-form edit-profile"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{
            required: true,
            message: 'Vui lòng nhập username!'
          }]}
        >
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item
          label="Mật khẩu cũ"
          name="old_password"
          rules={[{
            required: true,
            message: 'Vui lòng nhập mật khẩu!',
          }]}
        >
          <Input.Password/>
        </Form.Item>
        <Form.Item
          label="Mật khẩu mới"
          name="password"
          hasFeedback={true}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu!',
            },
            {
              min: 6,
              message: 'Mật khẩu ít nhất 6 ký tự!',
            },
            {
              max: 100,
              message: 'Mật khẩu tối đa 100 ký tự!',
            }
          ]}
        >
          <Input.Password/>
        </Form.Item>
        <Form.Item
          label="Nhập lại mật khẩu mới"
          name="confirm"
          hasFeedback={true}
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu!',
            },
            this.validateConfirmPassword,
          ]}
        >
          <Input.Password/>
        </Form.Item>
        <Form.Item className="form-actions">
          <Button
            type="primary"
            htmlType="submit"
            className="submit-button"
            icon={<SaveOutlined/>}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Lưu thay đổi
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

SecurityForm.propTypes = {
  user: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default SecurityForm;
