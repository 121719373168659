import React from 'react';
import PropTypes from 'prop-types';
import {Button, Drawer, message, Popconfirm, Spin} from 'antd';
import {deleteInvoiceAPI, getInvoiceAPI} from '../../../api/invoices';
import {getErrorMessage} from '../../../common/helpers';
import {CloseOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import InvoiceDetail from './InvoiceDetail';
import {Link} from 'react-router-dom';

const DrawerFooter = ({invoice, isDeleting, invoiceType, handleClose, handleDelete}) => {
  if (!invoice) {
    return null;
  }
  return (
    <div className="invoice-detail-footer">
      <Popconfirm
        title={<div>Bạn có chắc muốn xóa hóa đơn này không?</div>}
        onConfirm={handleDelete}
        okText="Xóa"
        cancelText="Hủy bỏ"
        okType="danger"
      >
        <Button
          htmlType="button"
          danger={true}
          icon={<DeleteOutlined/>}
          loading={isDeleting}
        >
          Xóa hóa đơn
        </Button>
      </Popconfirm>
      <div className="btn-group">
        <Button
          onClick={handleClose}
          style={{marginRight: 8}}
          icon={<CloseOutlined/>}
        >
          Đóng lại
        </Button>
        <Link
          to={`/invoices/${invoiceType}/${invoice.id}`}
          className="ant-btn ant-btn-primary"
        >
          <EditOutlined/> Sửa hóa đơn
        </Link>
      </div>
    </div>
  );
};

class InvoiceDetailDrawer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      invoice: null,
      isFetching: false,
      isDeleting: false,
    };
  }

  handleOpen = (invoice) => {
    this.setState({
      isFetching: true,
      invoice: null,
      isOpen: true,
    });
    this.getInvoiceDetail(invoice.id);
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  getInvoiceDetail = (id) => {
    getInvoiceAPI(id).then(res => {
      this.setState({
        invoice: res.data,
      });
    }).catch((error) => {
      message.error(getErrorMessage(error));
    });
  };

  handleDelete = () => {
    const {onUpdated, getCurrentUser} = this.props;
    const {invoice} = this.state;
    this.setState({
      isDeleting: true,
    });
    deleteInvoiceAPI(invoice.id).then((res) => {
      if (res.data) {
        message.info('Đã xóa!');
        onUpdated();
        getCurrentUser();
      }
      this.setState({
        isDeleting: false,
      });
      this.handleClose();
    }).catch(error => {
      this.setState({
        isDeleting: false,
      });
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {invoiceType} = this.props;
    const {isOpen, isDeleting, invoice} = this.state;
    return (
      <Drawer
        title="Chi tiết hóa đơn"
        placement="right"
        className="invoice-detail-drawer common-drawer"
        onClose={this.handleClose}
        visible={isOpen}
        width={700}
        footer={
          <DrawerFooter
            invoiceType={invoiceType}
            invoice={invoice}
            isDeleting={isDeleting}
            handleClose={this.handleClose}
            handleDelete={this.handleDelete}
          />
        }
      >
        {
          !invoice &&
          <Spin/>
        }
        {
          invoice &&
          <InvoiceDetail invoice={invoice}/>
        }
      </Drawer>
    );
  }
}

InvoiceDetailDrawer.propTypes = {
  onUpdated: PropTypes.func,
};

export default InvoiceDetailDrawer;
