import React from 'react';
import {publicUrl} from '../../../common/helpers';
import {Button} from 'antd';
import {RightOutlined} from '@ant-design/icons';

class Welcome extends React.PureComponent {
  render() {
    return (
      <div className="step two-col welcome">
        <div className="col col-img">
          <figure style={{backgroundImage: `url(${publicUrl('/assets/images/banner/welcome.svg')})`}}/>
        </div>
        <div className="col col-main">
          <div className="--title">Chào mừng bạn!</div>
          <div className="--content">
            <p>Cảm ơn bạn đã đăng ký và sử dụng dịch vụ!</p>
            <p>Hãy cùng xem lướt qua đôi chút về cách sử dụng dịch vụ nhé!</p>
          </div>
          <div className="--footer">
            <Button type="primary" block onClick={this.props.setStep('introduction')}>
              Tiếp tục <RightOutlined/>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;
