import React from 'react';
import {Switch} from 'react-router-dom';
import {AppRoute, MainLayout} from './layout';
import Login from './pages/auth/Login/Login';
import Register from './pages/auth/Register/Register';
import Dashboard from './pages/dashboard/Dashboard';
import Account from './pages/settings/AccountSettings/Account';
import ResetPassword from './pages/auth/ResetPassword/ResetPassword';
import InvoicesContainer from './pages/invoices/Invoices/InvoicesContainer';
import GoogleAuth from './pages/auth/Google/GoogleAuth';
import NewInvoice from './pages/invoices/UpsertInvoice/NewInvoice';
import AuthLayout from './layout/AuthLayout/AuthLayout';
import VerifyEmail from './pages/auth/VerifyEmail/VerifyEmail';
import EditInvoice from './pages/invoices/UpsertInvoice/EditInvoice';
import StorageSettings from './pages/settings/StorageSettings/StorageSettings';
import Error404 from './pages/errors/Error404';
import Tickets from './pages/support/Tickets/Tickets';
import NewTicket from './pages/support/NewTicket/NewTicket';
import TicketDetail from './pages/support/TicketDetail/TicketDetail';
import Emails from './pages/emails/Emails';
import TaxCodes from './pages/settings/TaxCodes/TaxCodes';
import APIKeySettings from './pages/settings/APIKeySettings/APIKeySettings';
import Users from './pages/manage/users/Users/Users';
import Customers from './pages/manage/customers/Customers/Customers';
// import SubUsers from './pages/subUsers/SubUsers/SubUsers';
import CompanySettings from './pages/settings/CompanySettings/CompanySettings';
import Templates from './pages/manage/templates/Templates';
import NewTemplate from './pages/manage/templates/UpsertTemplates/NewTemplate';
import ActivateCodes from './pages/manage/activate/ActivateCodes';
import Revocations from './pages/manage/revocations/Revocations';
import UploadRevocation from './pages/manage/revocations/UpsertRevocation/UploadRevocation';
import Pricing from './pages/pricing/Pricing';
import KeyIds from './pages/manage/keyIds/KeyIds';
import UpsertKeyId from './pages/manage/keyIds/UpsertKeyId/UpsertKeyId';
import Licenses from './pages/manage/licenses/Licenses';

const fallback = () => (
  <div>Loading...</div>
);

const Routes = () => (
  <Switch>
    <AppRoute
      path={'/'}
      component={Dashboard}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/invoices/:type/new'}
      component={NewInvoice}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/invoices/:type/:id'}
      component={EditInvoice}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/invoices/:type'}
      component={InvoicesContainer}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/emails'}
      component={Emails}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/settings/storage'}
      component={StorageSettings}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/settings/tax-codes'}
      component={TaxCodes}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/settings/api-keys'}
      component={APIKeySettings}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/settings/company'}
      component={CompanySettings}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    {/*<AppRoute*/}
    {/*  path={'/sub-users'}*/}
    {/*  component={SubUsers}*/}
    {/*  layout={MainLayout}*/}
    {/*  isPrivate={true}*/}
    {/*  exact={true}*/}
    {/*/>*/}
    <AppRoute
      path={'/account'}
      component={Account}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/support/tickets'}
      component={Tickets}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/support/tickets/new'}
      component={NewTicket}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/support/tickets/:id'}
      component={TicketDetail}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/manage/users'}
      component={Users}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/manage/customers'}
      component={Customers}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/manage/activate/codes'}
      component={ActivateCodes}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/manage/licenses'}
      component={Licenses}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/manage/templates'}
      component={Templates}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
      fallback={fallback}
    />
    <AppRoute
      path={'/manage/templates/new'}
      component={NewTemplate}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
      fallback={fallback}
    />
    <AppRoute
      path={'/manage/revocations'}
      component={Revocations}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
      fallback={fallback}
    />
    <AppRoute
      path={'/manage/revocations/new'}
      component={UploadRevocation}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
      fallback={fallback}
    />
    <AppRoute
      path={'/manage/key-ids'}
      component={KeyIds}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
      fallback={fallback}
    />
    <AppRoute
      path={'/manage/key-ids/new'}
      component={UpsertKeyId}
      layout={MainLayout}
      isPrivate={true}
      exact={true}
      fallback={fallback}
    />
    <AppRoute
      path={'/pricing'}
      component={Pricing}
      isPrivate={true}
      layout={MainLayout}
      exact={true}
    />
    <AppRoute
      path={'/oauth2/google'}
      component={GoogleAuth}
      isPrivate={true}
      exact={true}
    />
    <AppRoute
      path={'/login'}
      component={Login}
      layout={AuthLayout}
      exact={true}
    />
    <AppRoute
      path={'/register'}
      component={Register}
      layout={AuthLayout}
      exact={true}
    />
    <AppRoute
      path={'/reset-password'}
      component={ResetPassword}
      layout={AuthLayout}
      exact={true}
    />
    <AppRoute
      path={'/verify-email'}
      component={VerifyEmail}
      exact={true}
    />
    <AppRoute
      component={Error404}
    />
  </Switch>
);

export default Routes;
