import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_PARSER_SERVICE_API_URL}`;

export const getKeyIdsAPI = () => {
  return Requester.get(`${API_URL}/key-ids`);
};

export const addKeyIdAPI = (data) => {
  return Requester.post(`${API_URL}/key-ids`, data);
};

export const deleteKeyIdAPI = (id) => {
  return Requester.delete(`${API_URL}/key-ids/${id}`);
};
