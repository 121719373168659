import React, {Fragment} from 'react';
import './XMLNode.scss';

const XMLNode = React.memo(function XMLNode({name, node, handleSelect, selectedPath, arrayPath, path = ''}) {
  if (!node) {
    return null;
  }
  const isSelected = selectedPath === path || (arrayPath && selectedPath === arrayPath);
  if (typeof node !== 'object') {
    return (
      <div
        className={`xml-node ${isSelected ? 'is-selected' : ''}`}
        onClick={handleSelect(arrayPath || path)}
      >
        {
          name &&
          <div className="node-name">&lt;{name}&gt;</div>
        }
        <div className="node-value">{node}</div>
        {
          name &&
          <div className="node-name">&lt;&#47;{name}&gt;</div>
        }
      </div>
    );
  }
  if (Array.isArray(node)) {
    return (
      <Fragment>
        {
          node.map((item, index) => (
            <XMLNode
              name={name}
              key={index}
              node={item}
              selectedPath={selectedPath}
              handleSelect={handleSelect}
              arrayPath={path}
              path={`${path}[${index}]`}
            />
          ))
        }
      </Fragment>
    );
  }
  // const attr = node['__attr'];
  const childKeys = Object.keys(node).filter(item => item !== '__attr');
  return (
    <div
      className={`xml-node ${isSelected ? 'is-selected' : ''}`}
      onClick={handleSelect(arrayPath || path)}
    >
      {
        name &&
        <div className="node-name">&lt;{name}&gt;</div>
      }
      {
        childKeys.length > 0 &&
        <div className="node-child">
          {
            childKeys.map(key => (
              <XMLNode
                name={key}
                key={key}
                node={node[key]}
                handleSelect={handleSelect}
                selectedPath={selectedPath}
                path={`${path ? path + '.' : ''}${key}`}
              />
            ))
          }
        </div>
      }
      {
        name &&
        <div className="node-name">&lt;&#47;{name}&gt;</div>
      }
    </div>
  );
});

export default XMLNode;
