import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}`;

export const getInvoicesAPI = (params = null) => {
  return Requester.get(`${API_URL}/invoices`, params);
};

export const createInvoiceAPI = (data) => {
  return Requester.post(`${API_URL}/invoices`, data);
};

export const getInvoiceAPI = (id) => {
  return Requester.get(`${API_URL}/invoices/${id}`);
};

export const updateInvoiceAPI = (id, data) => {
  return Requester.put(`${API_URL}/invoices/${id}`, data);
};

export const deleteInvoiceAPI = (id) => {
  return Requester.delete(`${API_URL}/invoices/${id}`);
};

export const updateInvoiceFilesAPI = (id, data) => {
  return Requester.put(`${API_URL}/invoices/${id}/files`, data);
};

export const checkDuplicateInvoicesAPI = (no) => {
  return Requester.get(`${API_URL}/invoices/check-duplicated`, {no});
};
