import React, {Fragment} from 'react';
import {formatDate, getErrorMessage} from '../../../common/helpers';
import {getTicketsAPI} from '../../../api/tickets';
import {Breadcrumb, message, Pagination, Skeleton, Table} from 'antd';
import {Link, Redirect} from 'react-router-dom';
import {PlusOutlined} from '@ant-design/icons';

class Tickets extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchParams: {},
      pagination: {
        page_number: 1,
        page_size: 10,
        total: 0,
      },
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      width: 80,
    },
    {
      title: 'Yêu cầu',
      dataIndex: 'subject',
      ellipsis: true,
      render: (value, record) => <Link className="text-black" to={`/support/tickets/${record.id}`}>{value}</Link>,
    },
    {
      title: 'Phản hồi',
      dataIndex: 'conversation_status',
      align: 'center',
      width: 150,
      ellipsis: true,
      render: value => {
        switch (value) {
          case 0:
            return <span className="text-gray">Chờ phản hồi</span>;
          case 5:
            return <span className="text-green">Bộ phận hỗ trợ trả lời</span>;
          case 10:
            return <span className="text-blue">Bạn đã trả lời</span>;
          default:
            return <span>-</span>;
        }
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      align: 'center',
      width: 130,
      ellipsis: true,
      render: value => {
        switch (value) {
          case 0:
            return <span className="text-gray">Đang mở</span>;
          case 10:
            return <span className="text-blue">Đang xử lý</span>;
          case 20:
            return <span className="text-green">Hoàn thành</span>;
          case 30:
            return <span>Đã đóng</span>;
          case 40:
            return <span className="text-gray">Chờ xử lý</span>;
          default:
            return <span>-</span>;
        }
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      align: 'center',
      render: value => <span>{formatDate(value)}</span>,
      width: 130,
    },
  ];

  handleTableChange = (page, pageSize) => {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page_number: page,
        page_size: pageSize,
      }
    }), this.getData);
  };

  getData = () => {
    this.setState({
      isLoading: true,
    });
    this.handleGetData().then(res => {
      this.setState({
        isLoading: false,
        data: res?.data?.page_data || [],
        pagination: res?.data?.page_info,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isLoading: false,
        data: [],
      });
    });
  };

  handleGetData = async () => {
    const {pagination} = this.state;
    const params = {
      page_number: pagination.page_number,
      page_size: pagination.page_size,
    };
    let result = null;
    while (params.page_number >= 1) {
      result = await getTicketsAPI(params);
      const {data: {page_data, page_info}} = result;
      if (!page_data.length && page_info.page_number > 1) {
        params.page_number = page_info.page_number - 1;
      } else {
        break;
      }
    }
    return result;
  };

  render() {
    const {isLoading, data, pagination} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Hỗ trợ</Breadcrumb.Item>
          {
            !!data.length &&
            <Breadcrumb.Item>Yêu cầu đã gửi</Breadcrumb.Item>
          }
        </Breadcrumb>
        <div className="main-content tickets">
          {
            isLoading && !data.length &&
            <Skeleton/>
          }
          {
            !isLoading && !data.length &&
            <Redirect to="/support/tickets/new"/>
          }
          {
            !!data.length &&
            <Fragment>
              <Table
                size="small"
                className="common-table"
                columns={this.columns}
                bordered={true}
                rowKey={record => record.id}
                dataSource={data}
                pagination={false}
                loading={isLoading}
                scroll={{x: 600}}
              />
              <div className="table-footer">
                <div className="action-buttons">
                  <Link
                    className="ant-btn ant-btn-primary"
                    to={`/support/tickets/new`}
                  >
                    <PlusOutlined/> Gửi hỗ trợ mới
                  </Link>
                </div>
                <Pagination
                  total={pagination.total}
                  pageSize={pagination.page_size}
                  current={pagination.page_number}
                  onChange={this.handleTableChange}
                />
              </div>
            </Fragment>
          }
        </div>
      </Fragment>
    );
  }
}

export default Tickets;
