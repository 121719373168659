import Requester from '../common/network/Requester';
const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/api-keys`;

export const getAPIKeysAPI = () => {
  return Requester.get(`${API_URL}`);
};

export const addAPIKeyAPI = (data) => {
  return Requester.post(`${API_URL}/`, data);
};

export const deleteAPIKeyAPI = (id) => {
  return Requester.delete(`${API_URL}/${id}`);
};
