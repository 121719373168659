import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, message, Modal} from 'antd';
import {getErrorMessage} from '../../../../common/helpers/index';
import {TRANSACTION_TYPES} from '../../../../common/constants/common';
import {topUpAPI} from '../../../../api/manage/transactions';
import AntInputNumber from '../../../../common/components/Form/AntInputNumber';

class TopUpModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isSubmitting: false,
      isFetching: false,
      renderKey: 0,
      userId: null,
    };
    this.defaultState = {...this.state};
    this.form = React.createRef();
  }

  handleOpen = (userId) => {
    this.setState({
      ...this.defaultState,
      isOpen: true,
      userId,
      renderKey: new Date().getTime(),
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleSubmit = (formData) => {
    const {onSuccess} = this.props;
    const {userId} = this.state;
    this.setState({
      isSubmitting: true,
    });
    const postData = {
      ...formData,
      transaction_type: TRANSACTION_TYPES.CHARGE,
      user_id: userId,
    };
    topUpAPI(postData).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Thành công!', 3);
      this.handleClose();
      if (onSuccess) {
        onSuccess();
      }
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  submit = () => {
    this.form.current.submit();
  };

  render() {
    const {isOpen, isSubmitting, renderKey} = this.state;
    return (
      <Modal
        title="Nạp tiền"
        visible={isOpen}
        width={500}
        okText="Tiếp tục"
        cancelText="Hủy bỏ"
        maskClosable={false}
        confirmLoading={isSubmitting}
        onOk={this.submit}
        onCancel={this.handleClose}
      >
        <Form
          className="common-form"
          ref={this.form}
          key={renderKey}
          onFinish={this.handleSubmit}
        >
          <Form.Item
            label="Số tiền nạp"
            name="amount"
            rules={[{
              required: true,
              message: 'Vui lòng nhập số tiền'
            }]}
          >
            <AntInputNumber/>
          </Form.Item>
          <Form.Item
            label="Ghi chú"
            name="note"
            rules={[{
              required: true,
              message: 'Vui lòng nhập ghi chú'
            }]}
          >
            <Input.TextArea/>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

TopUpModal.propTypes = {
  onSuccess: PropTypes.func,
};

export default TopUpModal;
