import Requester from '../../common/network/Requester';
const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/manage`;

export const getTransactionsAPI = (params) => {
  return Requester.get(`${API_URL}/transactions`, params);
};

export const topUpAPI = (data) => {
  return Requester.post(`${API_URL}/transactions/topUp`, data);
};
