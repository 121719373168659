import React, {Fragment} from 'react';
import {Button, Empty, message, Modal, Spin} from 'antd';
import {getEmailDetailAPI} from '../../api/emails';
import {byteTo, getErrorMessage} from '../../common/helpers';
import HTMLView from '../../common/components/HTMLViewer/HTMLViewer';
import {PaperClipOutlined} from '@ant-design/icons';
import {history} from '../../history';
import './EmailDetailModal.scss';

class EmailDetailModal extends React.PureComponent {
  state = {
    isOpen: false,
    isFetching: false,
    email: null,
    title: '',
    emailId: null,
  };

  handleOpen = (title, emailId) => {
    this.setState({
      isOpen: true,
      isFetching: true,
      email: null,
      title,
      emailId,
    });
    this.getEmail(emailId);
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  getEmail = (id) => {
    getEmailDetailAPI(id).then(res => {
      if (this.state.emailId !== id) {
        return;
      }
      this.setState({
        isFetching: false,
        email: res.data,
      });
    }).catch(error => {
      this.setState({
        isFetching: false,
        email: null,
      });
      message.error(getErrorMessage(error));
    });
  };

  handleImport = () => {
    const {email} = this.state;
    history.push(`/invoices/purchase/new?emailId=${email.id}`);
  };

  render() {
    const {isOpen, isFetching, email, title} = this.state;
    return (
      <Modal
        title={email ? email.subject : title}
        visible={isOpen}
        width={900}
        onCancel={this.handleClose}
        footer={[
          <Button
            key="close"
            onClick={this.handleClose}
          >
            Đóng lại
          </Button>,
          <Button
            key="import"
            type="primary"
            onClick={this.handleImport}
            disabled={!email?.attachments?.length}
          >
            Import hóa đơn
          </Button>,
        ]}
      >
        <div className="modal-content">
          {
            isFetching && <Spin tip="Đang tải..."/>
          }
          {
            !isFetching && (
              <Fragment>
                {
                  email && (
                    <div className="email-detail">
                      <div className="email-body">
                        <HTMLView className="email-content" html={email.html || email.text}/>
                      </div>
                      {
                        !!email.attachments?.length &&
                        <div className="email-attachments">
                          <h3>Tệp đính kèm</h3>
                          <div className="attachments">
                            {
                              email.attachments.map((item, index) => (
                                <div className="attachment" key={index}>
                                  <div className="file-icon">
                                    <PaperClipOutlined/>
                                  </div>
                                  <div className="file-info">
                                    <div className="file-name">{item.name}</div>
                                    <div className="file-size">{byteTo(item.size)}KB</div>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      }
                    </div>
                  )
                }
                {
                  !email && <Empty description="Không tải được email"/>
                }
              </Fragment>
            )
          }
        </div>
      </Modal>
    );
  }
}

export default EmailDetailModal;
