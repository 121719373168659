import {GET_CURRENT_USER_SUCCESS_AC, LOGIN_SUCCESS_AC, LOGOUT_AC} from '../../actions/user';
import createReducer from '../createReducer';

const initialState = null;

const handler = {
  [LOGIN_SUCCESS_AC]: (state, action) => {
    return {
      ...state,
      ...action.data.user,
    };
  },
  [GET_CURRENT_USER_SUCCESS_AC]: (state, action) => ({
    ...state,
    ...action.data,
  }),
  [LOGOUT_AC]: () => null,
};

export default createReducer(initialState, handler);
