import React from 'react';
import {publicUrl} from '../../../common/helpers';
import {Button} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';

class ViewInvoice extends React.PureComponent {
  render() {
    const {setStep} = this.props;
    return (
      <div className="step two-col view-invoice">
        <div className="col col-img">
          <figure
            className="fit"
            style={{backgroundImage: `url(${publicUrl('/assets/images/banner/invoices.jpg')})`}}
          />
        </div>
        <div className="col col-main">
          <div className="--title">Tra cứu hóa đơn</div>
          <div className="--content">
            <p>
              Bạn có thể dễ dàng tìm kiếm và tải lại các file hóa đơn của mình bất kì lúc nào, miễn là bạn thiết lập
              ít nhất 1 phương thức lưu trữ hóa đơn.
            </p>
            <p>
              Bạn có thể tìm kiếm nâng cao bằng cách bấm vào dấu "+" ở bên cạnh nút "Tìm kiếm".
            </p>
          </div>
          <div className="--footer two-button">
            <Button onClick={setStep('create-invoice')}>
              <LeftOutlined/> Quay lại
            </Button>
            <Button type="primary" onClick={setStep('tax-code')}>
              Tiếp tục <RightOutlined/>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewInvoice;
