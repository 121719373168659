import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/activate`;

export const getActivateHistoryAPI = (params) => {
  return Requester.get(`${API_URL}/history`, params);
};

export const activateCodeAPI = (data) => {
  return Requester.post(`${API_URL}`, data);
};
