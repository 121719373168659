export const GET_USER_COMPANIES_AC = 'GET_USER_COMPANIES_AC';
export const GET_USER_COMPANIES_SUCCESS_AC = 'GET_USER_COMPANIES_SUCCESS_AC';
export const SELECT_COMPANY_AC = 'SELECT_COMPANY_AC';

export const getUserCompaniesAC = () => ({
  type: GET_USER_COMPANIES_AC,
});

export const getUserCompaniesSuccessAC = (companies) => ({
  type: GET_USER_COMPANIES_SUCCESS_AC,
  companies,
});

export const selectCompanyAC = (companyId) => ({
  type: SELECT_COMPANY_AC,
  companyId,
});
