import React from 'react';
import PropTypes from 'prop-types';
import {Descriptions} from 'antd';
import {formatCurrency, formatDate} from '../../../common/helpers';
import './InvoiceDetail.scss';
import FileStatus from '../common/FileStatus/FileStatus';

class InvoiceDetail extends React.PureComponent {
  render() {
    const {invoice} = this.props;
    if (!invoice) {
      return;
    }
    return (
      <Descriptions
        bordered={true}
        size="middle"
        layout="vertical"
        column={4}
        className="invoice-detail"
      >
        <Descriptions.Item label="Mã hóa đơn" className="part-header">
          {invoice.code}
        </Descriptions.Item>
        <Descriptions.Item label="Tên hóa đơn" span={3} className="part-header">
          {invoice.name}
        </Descriptions.Item>
        <Descriptions.Item label="Số hóa đơn">
          {invoice.no}
        </Descriptions.Item>
        <Descriptions.Item label="Ngày tạo">
          {formatDate(invoice.issued_date)}
        </Descriptions.Item>
        <Descriptions.Item label="Mẫu số">
          {invoice.pattern}
        </Descriptions.Item>
        <Descriptions.Item label="Ký hiệu">
          {invoice.serial}
        </Descriptions.Item>
        <Descriptions.Item label="Người bán" span={4} className="part-header">
          {invoice.seller_name}
        </Descriptions.Item>
        <Descriptions.Item label="Mã số thuế">
          {invoice.seller_tax_code}
        </Descriptions.Item>
        <Descriptions.Item label="Địa chỉ" span={3}>
          {invoice.seller_address}
        </Descriptions.Item>
        <Descriptions.Item label="Người mua" span={4} className="part-header">
          {invoice.buyer_name}
        </Descriptions.Item>
        <Descriptions.Item label="Mã số thuế">
          {invoice.buyer_tax_code}
        </Descriptions.Item>
        <Descriptions.Item label="Địa chỉ" span={3}>
          {invoice.buyer_address}
        </Descriptions.Item>
        <Descriptions.Item label="Tổng số tiền chưa có VAT" span={2} className="part-header content-right">
          {formatCurrency(invoice.total_amount_without_vat)}
        </Descriptions.Item>
        <Descriptions.Item label="Số tiền VAT" span={2} className="part-header content-right">
          {formatCurrency(invoice.total_vat_amount)}
        </Descriptions.Item>
        <Descriptions.Item label="Tổng số tiền đã có VAT" span={2} className="part-header content-right">
          {formatCurrency(invoice.total_amount_with_vat)}
        </Descriptions.Item>
        <Descriptions.Item label="Tổng số tiền thanh toán" span={2} className="part-header content-right">
          {formatCurrency(invoice.total_payment_amount)}
        </Descriptions.Item>
        <Descriptions.Item label="File" span={4} className="part-header">
          <FileStatus files={invoice.files} showListFile={true}/>
        </Descriptions.Item>
      </Descriptions>
    );
  }
}

InvoiceDetail.propTypes = {
  invoice: PropTypes.object,
};

export default InvoiceDetail;
