import React, {Fragment, useState} from 'react';
import {Button, Col, Form, Input, Row} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import AntInputNumber from '../../../../common/components/Form/AntInputNumber';
import WarningInput from '../../../../common/components/Form/WarningInput';

const rules = [{required: true, message: 'Vui lòng nhập trường này'}];

const InvoiceItems = () => {
  const [page, setPage] = useState(1);
  const limit = page * 10;
  return (
    <Form.List name="items">
      {(fields, {add, remove}) => {
        const hasMore = fields.length > limit;
        if (!fields.length) {
          return (
            <Fragment>
              <Row>
                <Col xs={{span: 24}}>Không có hàng hóa</Col>
              </Row>
              <Row>
                <Col xs={{span: 21}} md={{span: 22}}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      style={{width: '100%'}}
                    >
                      <PlusOutlined/> Thêm hàng hóa
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Fragment>
          )
        }
        /**
         * `fields` internal fill with `name`, `key`, `fieldKey` props.
         * You can extends this into sub field to support multiple dynamic fields.
         */
        return (
          <Fragment>
            <Row
              gutter={{
                xs: 4,
                sm: 8,
                md: 12,
              }}
            >
              <Col xs={{span: 9}} md={{span: 2}}>
                Mã mặt hàng
              </Col>
              <Col xs={{span: 12}} md={{span: 6}}>
                Tên mặt hàng
              </Col>
              <Col xs={{span: 9}} md={{span: 2}}>
                Đơn vị tính
              </Col>
              <Col xs={{span: 12}} md={{span: 3}}>
                Đơn giá
              </Col>
              <Col xs={{span: 9}} md={{span: 2}}>
                Số lượng
              </Col>
              <Col xs={{span: 12}} md={{span: 3}}>
                Thành tiền
              </Col>
              <Col xs={{span: 9}} md={{span: 2}}>
                % VAT
              </Col>
              <Col xs={{span: 12}} md={{span: 3}}>
                Tiền thuế VAT
              </Col>
              <Col flex="none">
                Xóa
              </Col>
            </Row>
            {fields.map((field, index) => {
              if (index >= limit) {
                return null;
              }
              return (
                (
                  <Row
                    key={field.key}
                    gutter={{
                      xs: 4,
                      sm: 8,
                      md: 12,
                    }}
                  >
                    <Col xs={{span: 9}} md={{span: 2}}>
                      <Form.Item
                        name={[field.name, 'item_code']}
                        fieldKey={[field.fieldKey, 'item_code']}
                      >
                        <Input placeholder="Mã mặt hàng"/>
                      </Form.Item>
                    </Col>
                    <Col xs={{span: 12}} md={{span: 6}}>
                      <Form.Item
                        name={[field.name, 'item_name']}
                        fieldKey={[field.fieldKey, 'item_name']}
                        rules={rules}
                      >
                        <Input placeholder="Tên mặt hàng"/>
                      </Form.Item>
                    </Col>
                    <Col xs={{span: 9}} md={{span: 2}}>
                      <Form.Item
                        name={[field.name, 'unit_name']}
                        fieldKey={[field.fieldKey, 'unit_name']}
                      >
                        <Input placeholder="Đơn vị tính"/>
                      </Form.Item>
                    </Col>
                    <Col xs={{span: 12}} md={{span: 3}}>
                      <Form.Item
                        name={[field.name, 'unit_price']}
                        fieldKey={[field.fieldKey, 'unit_price']}
                      >
                        <AntInputNumber placeholder="Đơn giá"/>
                      </Form.Item>
                    </Col>
                    <Col xs={{span: 9}} md={{span: 2}}>
                      <Form.Item
                        name={[field.name, 'quantity']}
                        fieldKey={[field.fieldKey, 'quantity']}
                      >
                        <AntInputNumber placeholder="Số lượng"/>
                      </Form.Item>
                    </Col>
                    <Col xs={{span: 12}} md={{span: 3}}>
                      <Form.Item
                        name={[field.name, 'item_total_amount_without_vat']}
                        fieldKey={[field.fieldKey, 'item_total_amount_without_vat']}
                        rules={rules}
                      >
                        <AntInputNumber placeholder="Thành tiền"/>
                      </Form.Item>
                    </Col>
                    <Col xs={{span: 9}} md={{span: 2}}>
                      <Form.Item
                        name={[field.name, '__auto_vat_rate']}
                        fieldKey={[field.fieldKey, '__auto_vat_rate']}
                        className="d-none"
                      >
                        <WarningInput/>
                      </Form.Item>
                      <Form.Item
                        name={[field.name, 'vat_rate']}
                        fieldKey={[field.fieldKey, 'vat_rate']}
                        rules={rules}
                      >
                        <AntInputNumber placeholder="% VAT"/>
                      </Form.Item>
                    </Col>
                    <Col xs={{span: 9}} md={{span: 3}}>
                      <Form.Item
                        name={[field.name, '__auto_vat_amount']}
                        fieldKey={[field.fieldKey, '__auto_vat_amount']}
                        className="d-none"
                      >
                        <WarningInput/>
                      </Form.Item>
                      <Form.Item
                        name={[field.name, 'vat_amount']}
                        fieldKey={[field.fieldKey, 'vat_amount']}
                        rules={rules}
                      >
                        <AntInputNumber placeholder="Thuế VAT"/>
                      </Form.Item>
                    </Col>
                    <Col flex="none">
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Col>
                  </Row>
                )
              )
            })}
            {
              hasMore &&
              <Row>
                <Col xs={{span: 21}} md={{span: 23}}>
                  <Form.Item>
                    <Button
                      type="link"
                      onClick={() => {
                        setPage(page + 1)
                      }}
                      style={{width: '100%'}}
                    >
                      <PlusOutlined/> Xem thêm (Còn {fields.length - limit} hàng hóa nữa)
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            }
            <Row>
              <Col xs={{span: 21}} md={{span: 23}}>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    style={{width: '100%'}}
                  >
                    <PlusOutlined/> Thêm hàng hóa
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Fragment>
        );
      }}
    </Form.List>
  )
};

export default InvoiceItems;
