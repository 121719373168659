import React from 'react';
import {ROLE_BY_ID} from '../../../../common/constants/roles';

const UserRole = ({roleId}) => {
  const role = ROLE_BY_ID[roleId];
  if (role) {
    return <span className={role.colorClassName}>{role.name}</span>
  }
  return 'N/A';
};

export default UserRole;
