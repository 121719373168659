import React from 'react';
import {connect} from 'react-redux';
import {Modal} from 'antd';
import Steps from './Steps';
import './WelcomeModal.scss';

class WelcomeModal extends React.PureComponent {
  state = {
    step: 'welcome',
    data: {
      taxCode: '',
      companyName: '',
    },
  };

  setStep = (step, data = null) => () => {
    if (!data) {
      this.setState({step});
      return;
    }
    this.setState(prevState => ({
      step,
      data: {
        ...prevState.data,
        ...data,
      },
    }));
  };

  render() {
    const {settings} = this.props;
    const {step, data} = this.state;
    return (
      <Modal
        visible={settings.isFetched && !settings.keyValue['completed-welcome-tutorial']}
        closable={false}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        className="welcome-modal"
        width="auto"
        zIndex={1001}
      >
        <Steps
          step={step}
          data={data}
          setStep={this.setStep}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  settings: state.user.settings,
});

export default connect(mapStateToProps)(WelcomeModal);
