import React from 'react';
import {verifyEmailAPI} from '../../../api/auth';
import {HomeOutlined, RedoOutlined} from '@ant-design/icons';
import {Button, Result} from 'antd';
import {getErrorMessage} from '../../../common/helpers';
import {history} from '../../../history';
import qs from 'qs';

class VerifyEmail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: true,
      isSuccess: false,
      error: null,
    };
  }

  componentDidMount() {
    this.verify();
  }

  verify = () => {
    const params = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
    if (!params.token) {
      history.replace('/');
      return null;
    }
    this.setState({
      isSubmitting: true,
    });
    verifyEmailAPI({
      token: params.token,
    }).then(() => {
      this.setState({
        isSubmitting: false,
        isSuccess: true,
      });
    }).catch(error => {
      const errorMessage = getErrorMessage(error);
      this.setState({
        isSubmitting: false,
        isSuccess: false,
        error: errorMessage,
      });
    });
  };

  goHome = (event) => {
    event.preventDefault();
    history.push('/');
  };

  render() {
    const {isSubmitting, isSuccess, error} = this.state;
    if (isSubmitting && !error) {
      return null;
    }
    if (isSuccess) {
      return (
        <Result
          status="success"
          title="Xác thực email thành công!"
          subTitle="Email của bạn đã được xác thực. Xin cảm ơn!"
          extra={[
            <Button type="primary" key="home" href="/" onClick={this.goHome} icon={<HomeOutlined/>}>
              Trang chủ
            </Button>,
          ]}
        />
      );
    }
    return (
      <Result
        status="error"
        title="Xác thực không thành công!"
        subTitle={error}
        extra={[
          <Button type="primary" key="retry" onClick={this.verify} loading={isSubmitting} icon={<RedoOutlined/>}>
            Thử lại
          </Button>,
          <Button type="default" key="home" href="/" onClick={this.goHome} icon={<HomeOutlined/>}>
            Trang chủ
          </Button>
        ]}
      />
    );
  }
}

export default VerifyEmail;
