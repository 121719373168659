import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {CrownOutlined, PoweroffOutlined, UserOutlined} from '@ant-design/icons';
import {Dropdown, Menu, Tag} from 'antd';
import {Link} from 'react-router-dom';
import HyperLink from '../../common/components/HyperLink/HyperLink';
import UserAvatar from '../../common/components/UserInfo/UserAvatar';
import ROLES from '../../common/constants/roles';
import {formatCurrency} from '../../common/helpers';
import PLANS, {PLAN_BY_ID} from '../../common/constants/plans';

class LoggedInUser extends React.PureComponent {
  render() {
    const {user, handleLogout, isMobile} = this.props;
    if (!user) {
      return null;
    }
    const plan = PLAN_BY_ID[user.plan?.id];
    const menu = (
      <Menu>
        <Menu.Item key="0">
          <Link to="/account">Chào <strong>{user.name}</strong>!</Link>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="1">
          <Link to="/account"><UserOutlined className="mr-5"/> Tài khoản của bạn</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <HyperLink onClick={handleLogout}><PoweroffOutlined className="mr-5"/> Đăng xuất</HyperLink>
        </Menu.Item>
      </Menu>
    );
    return (
      <Fragment>
        {
          user.role_id === ROLES.RESELLER.id &&
          <Tag color="red">Số dư: <strong>{formatCurrency(user.balance)}</strong></Tag>
        }
        {
          plan &&
          <Tag color={plan.tagColor}>{plan.id === PLANS.PREMIUM.id &&
          <CrownOutlined/>} {!isMobile ? plan.name : ''}</Tag>
        }
        {
          user.stats && user.plan &&
          <Tag color="#2db7f5">{formatCurrency(user.stats.invoices.total, false)}/{formatCurrency(user.plan.limits.invoice, false)}{!isMobile ? ' hóa đơn' : ''}</Tag>
        }
        <Dropdown overlay={menu} trigger={['click']}>
          <HyperLink className="header-icon">
            <UserAvatar user={user} showName={!isMobile} showShortName={true}/>
          </HyperLink>
        </Dropdown>
      </Fragment>
    );
  }
}

LoggedInUser.propTypes = {
  user: PropTypes.any,
  isMobile: PropTypes.bool,
  handleLogout: PropTypes.func,
};

LoggedInUser.defaultProps = {
  showName: true,
};

export default LoggedInUser;
