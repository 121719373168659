import React, {Fragment} from 'react';
import {CheckCircleFilled, CloseCircleFilled, WarningFilled} from '@ant-design/icons';
import {formatDate} from '../../../../common/helpers';
import moment from 'moment';

const CheckCertInfo = ({cert, sellerName, signedDate, rawSignedDate}) => {
  if (!cert) {
    return null;
  }
  const issuer = (cert?.issuer?.attributes || []).find(item => item.name === 'commonName');
  const organizationName = (cert?.issuer?.attributes || []).find(item => item.name === 'organizationName');
  const subject = (cert?.subject?.attributes || []).find(item => item.name === 'commonName');
  const isValidSubject = `${subject?.value || ''}`.trim().toLowerCase() === `${sellerName}`.trim().toLowerCase();
  const names = [];
  if (issuer) {
    names.push(issuer.value);
  }
  if (organizationName) {
    names.push(organizationName.value);
  }
  const name = names.join(', ');
  let revocationClassName = 'text-red';
  if (cert.revocation && signedDate) {
    let signedAt;
    try {
      const revocationAt = moment(cert.revocation.date, 'DD/MM/YYYY H:mm A');
      if (rawSignedDate) {
        signedAt = moment(rawSignedDate);
        if (revocationAt.isAfter(signedAt)) {
          revocationClassName = 'text-orange';
        }
      } else {
        signedAt = moment(signedDate);
        if (revocationAt.isSameOrAfter(signedAt, 'day')) {
          revocationClassName = 'text-orange';
        }
      }
    } catch (e) {

    }
  }
  return (
    <div className="check-invoice-item">
      <label className="check-item-label">Thông tin chữ ký số:</label>
      {
        !cert.isValidKeyId &&
        (
          <div className="result">
            <CloseCircleFilled className="text-red"/> <span className="text-red">Chứng thư số không hợp pháp</span><br/>
            (Chứng thư số không được cấp bởi nhà cung cấp dịch vụ chữ ký số được Bộ Thông tin và Truyền thông cấp phép)
          </div>
        )
      }
      {
        isValidSubject &&
        <div className="result">
          <CheckCircleFilled className="text-green"/> Ký bởi: <strong>{subject?.value}</strong>
        </div>
      }
      {
        !isValidSubject &&
        <div className="result">
          <WarningFilled className="text-orange"/> Ký bởi: <strong className="text-orange">{subject?.value}</strong>
          <br/>(Tên người bán và tên người ký có thể không trùng khớp)
        </div>
      }
      <div className="result">
        <CheckCircleFilled className="text-green"/> Nhà cung cấp: <strong>{name}</strong>
      </div>
      {
        cert.validity &&
        <div className="result">
          <CheckCircleFilled className="text-green"/> Hiệu lực:
          từ <strong>{formatDate(cert.validity.notBefore)}</strong> đến <strong>{formatDate(cert.validity.notAfter)}</strong>
        </div>
      }
      {
        !cert.revocation &&
        <Fragment>
          <div className="result">
            <CheckCircleFilled className="text-green"/> Serial number: {cert.serialNumber}
          </div>
          <div className="result">
            <CheckCircleFilled className="text-green"/> Chứng thư số đang hoạt động
          </div>
        </Fragment>
      }
      {
        cert.revocation &&
        <Fragment>
          <div className="result">
            <CloseCircleFilled className={revocationClassName}/> Serial number: <strong>{cert.serialNumber}</strong>
          </div>
          <div className="result">
            <CloseCircleFilled className={revocationClassName}/> Chứng thư số đã bị thu hồi - Ngày thu
            hồi: <strong>{cert.revocation.date}</strong>
          </div>
        </Fragment>
      }
    </div>
  );
};

export default React.memo(CheckCertInfo);
