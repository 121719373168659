import React from 'react';
import CreateInvoice from './CreateInvoice';
import Finish from './Finish';
import Introduction from './Introduction';
import Storage from './Storage';
import TaxCode from './TaxCode';
import ViewInvoice from './ViewInvoice';
import Welcome from './Welcome';

const Steps = ({step, ...others}) => {
  let Component = null;
  switch (step) {
    case 'welcome':
      Component = Welcome;
      break;
    case 'introduction':
      Component = Introduction;
      break;
    case 'storage':
      Component = Storage;
      break;
    case 'create-invoice':
      Component = CreateInvoice;
      break;
    case 'view-invoice':
      Component = ViewInvoice;
      break;
    case 'tax-code':
      Component = TaxCode;
      break;
    case 'finish':
      Component = Finish;
      break;
    default:
      return null;
  }
  return <Component {...others}/>
};

export default Steps;
