import React from 'react';
import {connect} from 'react-redux';
import {Form, Input, message, Modal} from 'antd';
import {getErrorMessage} from '../../common/helpers/index';
import {activateCodeAPI} from '../../api/activate';
import {ACTIVATE_CODE_STATUS} from '../../common/constants/common';
import PropTypes from 'prop-types';
import {refreshTokeAC} from '../../redux/actions/user';

class ActivateCodeModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isSubmitting: false,
      isFetching: false,
      renderKey: 0,
      formData: {},
    };
    this.defaultState = {...this.state};
    this.form = React.createRef();
  }

  handleOpen = () => {
    this.setState({
      ...this.defaultState,
      isOpen: true,
      renderKey: new Date().getTime(),
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  onValuesChange = (changedFields, allFields) => {
    this.setState({
      formData: allFields,
    });
  };

  handleSubmit = (values) => {
    const {onSuccess, dispatch} = this.props;
    this.setState({
      isSubmitting: true,
    });
    activateCodeAPI(values).then((res) => {
      this.setState({
        isSubmitting: false,
      });
      if (res?.data?.status === ACTIVATE_CODE_STATUS.PaymentPending.id) {
        message.success('Thành công! Vui lòng thanh toán để hoàn tất!');
      } else if (res?.data?.status === ACTIVATE_CODE_STATUS.Activated.id) {
        message.success('Kích hoạt thành công!');
        dispatch(refreshTokeAC(true));
      } else {
        message.success('Thành công! Vui lòng chờ xác nhận!');
      }
      this.handleClose();
      if (onSuccess) {
        onSuccess();
      }
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  submit = () => {
    this.form.current.submit();
  };

  render() {
    const {isOpen, renderKey} = this.state;
    return (
      <Modal
        title="Kích hoạt dịch vụ"
        visible={isOpen}
        width={450}
        okText="Kích hoạt"
        cancelText="Hủy bỏ"
        maskClosable={false}
        onOk={this.submit}
        onCancel={this.handleClose}
      >
        <Form
          className="common-form"
          ref={this.form}
          key={renderKey}
          onFinish={this.handleSubmit}
          onValuesChange={this.onValuesChange}
        >
          <Form.Item
            label="Mã kích hoạt"
            name="code"
            rules={[{
              required: true,
              message: 'Vui lòng nhập mã kích hoạt!'
            }]}
          >
            <Input/>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

ActivateCodeModal.propTypes = {
  onSuccess: PropTypes.func,
};

export default connect(null, null, null, {
  forwardRef: true,
})(ActivateCodeModal);
