import React from 'react';
import InvoiceConstants from '../../../../common/constants/invoice';

const ReviewStatus = ({reviewStatus}) => {
  switch (reviewStatus) {
    case InvoiceConstants.ReviewStatus.Reviewed:
      return (
        <span className="text-green">Đã duyệt</span>
      );
    case InvoiceConstants.ReviewStatus.NotReviewed:
      return (
        <span className="text-gray">Chưa duyệt</span>
      );
    default:
      return '-';
  }
};

export default ReviewStatus;
