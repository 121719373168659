import Requester from '../common/network/Requester';
const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/companies/settings`;

export const getCompanySettingsAPI = (params = null) => {
  return Requester.get(`${API_URL}`, params);
};

export const updateCompanySettingsAPI = (data) => {
  return Requester.put(`${API_URL}`, data);
};
