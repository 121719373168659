import {call, put, takeEvery} from 'redux-saga/effects';
import {GET_USER_SETTINGS_AC, getUserSettingsSuccessAC} from '../../actions/user';
import {getUserSettingsAPI} from '../../../api/settings';

function* getUserSettings() {
  try {
    const {data} = yield call(getUserSettingsAPI);
    yield put(getUserSettingsSuccessAC(data));
  } catch (e) {
  }
}

export default function* () {
  yield takeEvery(GET_USER_SETTINGS_AC, getUserSettings);
}
