import React, {Fragment} from 'react';
import {getErrorMessage} from '../../../common/helpers/index';
import {Breadcrumb, message, Popconfirm, Result, Skeleton, Table} from 'antd';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {deleteRevocationAPI, getRevocationsAPI} from '../../../api/revocations';

class Revocations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  columns = [
    {
      title: 'Tên file',
      dataIndex: 'name',
    },
    {
      title: 'Thao tác',
      key: 'actions',
      width: 120,
      fixed: 'right',
      align: 'center',
      render: (value, record) => (
        <div className="actions text-center">
          {/*<HyperLink*/}
          {/*title="Cập nhật"*/}
          {/*onClick={this.handleShowUpdate(record)}*/}
          {/*>*/}
          {/*<EditOutlined/>*/}
          {/*</HyperLink>*/}
          <Popconfirm
            title={<div>Bạn có chắc muốn xóa file này không?</div>}
            onConfirm={this.handleShowDelete(record.id)}
            okText="Xóa"
            cancelText="Hủy bỏ"
            okType="danger"
          >
            <DeleteOutlined/>
          </Popconfirm>
        </div>
      ),
    },
  ];

  getData = () => {
    this.setState({
      isLoading: true,
    });
    getRevocationsAPI().then(res => {
      this.setState({
        isLoading: false,
        data: res?.data || [],
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isLoading: false,
        data: [],
      });
    });
  };

  // handleShowUpdate = (data) => () => {
  // };

  handleShowDelete = (id) => () => {
    deleteRevocationAPI(id).then(() => {
      this.getData();
    }).catch(error => {
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {isLoading, data} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Chữ ký số bị thu hồi</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content templates">
          {
            isLoading && !data.length &&
            <Skeleton/>
          }
          {
            !isLoading && !data.length &&
            <Result
              status="404"
              title="Chưa có file nào"
              subTitle="Bạn chưa thêm file nào. Click vào nút dưới đây để thêm mới."
              extra={
                <Link
                  className="ant-btn ant-btn-primary"
                  to="/manage/revocations/new"
                >
                  <PlusOutlined/> Tải lên file mới
                </Link>
              }
            />
          }
          {
            !!data.length &&
            <Fragment>
              <Table
                size="small"
                className="common-table"
                columns={this.columns}
                bordered={true}
                rowKey={record => record.id}
                dataSource={data}
                pagination={false}
                loading={isLoading}
                scroll={{x: 1500}}
              />
              <div className="table-footer">
                <div className="action-buttons">
                  <Link
                    className="ant-btn ant-btn-primary"
                    to={`/manage/revocations/new`}
                  >
                    <PlusOutlined/> Thêm mới
                  </Link>
                </div>
              </div>
            </Fragment>
          }
        </div>
      </Fragment>
    );
  }
}

export default Revocations;
