// import React from 'react';
import InvoiceConstants from '../../constants/invoice';

const InvoiceStatus = ({invoice_status}) => {
  switch (invoice_status) {
    case InvoiceConstants.InvoiceStatus.HoaDonDaXoaBo:
      return 'Hoá đơn đã xóa bỏ';
    case InvoiceConstants.InvoiceStatus.HoaDonGoc:
      return 'Hoá đơn gốc';
    case InvoiceConstants.InvoiceStatus.HoaDonThayThe:
      return 'Hoá đơn thay thế';
    default:
      return '';
  }
};

export default InvoiceStatus;
