import {createBrowserHistory} from 'history';
import ga from './common/helpers/ga';

const history = createBrowserHistory();

history.listen(location => {
  ga.set({ page: location.pathname }); // Update the user's current page
  ga.pageView(location.pathname); // Record a pageview for the given page
});

export {
  history,
};
