import {call, put, takeEvery} from 'redux-saga/effects';
import {
  GET_CURRENT_USER_AC,
  getCurrentUserSuccessAC,
  getUserCompaniesAC,
  getUserSettingsAC,
  logoutAC
} from '../../actions/user';
import {getCurrentUserAPI, getCurrentUserPlanAPI} from '../../../api/users';
import {getInvoicesStatsAPI} from '../../../api/stats';

function* getCurrentUserFlow(action) {
  try {
    const {data} = yield call(getCurrentUserAPI);
    const {data: plan} = yield call(getCurrentUserPlanAPI);
    const {data: invoicesStats} = yield call(getInvoicesStatsAPI);
    yield put(getCurrentUserSuccessAC({
      ...data,
      plan,
      stats: {
        invoices: invoicesStats,
      },
    }));
    yield put(getUserSettingsAC());
    yield put(getUserCompaniesAC());
  } catch (e) {
    if (action.isCheckLogin) {
      yield put(logoutAC(true));
    }
  }
}

export default function* () {
  yield takeEvery(GET_CURRENT_USER_AC, getCurrentUserFlow);
}
