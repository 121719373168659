const ROLES = {
  SUB_USER: {
    id: 2,
    name: 'Sub user',
    colorClassName: 'text-gray',
  },
  NORMAL_USER: {
    id: 5,
    name: 'Normal user',
    colorClassName: '',
  },
  COLLABORATOR: {
    id: 10,
    name: 'Collaborator',
    colorClassName: 'text-blue',
  },
  RESELLER: {
    id: 20,
    name: 'Reseller',
    colorClassName: 'text-blue',
  },
  MANAGER: {
    id: 100,
    name: 'Manager',
    colorClassName: 'text-red',
  },
  ADMIN: {
    id: 105,
    name: 'Admin',
    colorClassName: 'text-red',
  },
  SUPER_ADMIN: {
    id: 110,
    name: 'Super admin',
    colorClassName: 'text-red',
  },
};

export const ROLE_BY_ID = Object.values(ROLES).reduce(function (result, item) {
  result[item.id] = item;
  return result;
}, {});

export default ROLES;
