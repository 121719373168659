import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, message, Modal, Select} from 'antd';
import {formatCurrencyValue, getErrorMessage} from '../../../common/helpers/index';
import {createActivateCodeAPI} from '../../../api/manage/activateCodes';
import {INVOICE_PLANS} from '../../../common/constants/plans';
import ROLES from '../../../common/constants/roles';
import {ACTIVATE_CODE_TYPE} from '../../../common/constants/common';

class CreateActivateCodeModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isSubmitting: false,
      isFetching: false,
      renderKey: 0,
      formData: {},
    };
    this.defaultState = {...this.state};
    this.form = React.createRef();
  }

  handleOpen = () => {
    this.setState({
      ...this.defaultState,
      isOpen: true,
      renderKey: new Date().getTime(),
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  onValuesChange = (changedFields, allFields) => {
    this.setState({
      formData: allFields,
    });
  };

  handleSubmit = (values) => {
    const {onSuccess} = this.props;
    this.setState({
      isSubmitting: true,
    });
    createActivateCodeAPI(values).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đã tạo mã kích hoạt!');
      this.handleClose();
      if (onSuccess) {
        onSuccess();
      }
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  submit = () => {
    this.form.current.submit();
  };

  render() {
    const {user} = this.props;
    const {isOpen, renderKey, isSubmitting, formData} = this.state;
    return (
      <Modal
        title="Tạo mã kích hoạt dịch vụ"
        visible={isOpen}
        width={600}
        okText="Tạo mã"
        cancelText="Hủy bỏ"
        maskClosable={false}
        onOk={this.submit}
        onCancel={this.handleClose}
      >
        <Form
          className="common-form"
          ref={this.form}
          key={renderKey}
          onFinish={this.handleSubmit}
          onValuesChange={this.onValuesChange}
        >
          <Form.Item
            name="type"
            label="Loại mã"
            rules={[{required: true, message: 'Vui lòng chọn một giá trị'}]}
          >
            <Select disabled={isSubmitting}>
              <Select.Option value={ACTIVATE_CODE_TYPE.Normal.id}>{ACTIVATE_CODE_TYPE.Normal.name}</Select.Option>
              <Select.Option value={ACTIVATE_CODE_TYPE.Trial.id}>{ACTIVATE_CODE_TYPE.Trial.name}</Select.Option>
              {
                user.role_id >= ROLES.MANAGER.id &&
                <Select.Option value={ACTIVATE_CODE_TYPE.Gift.id}>{ACTIVATE_CODE_TYPE.Gift.name}</Select.Option>
              }
            </Select>
          </Form.Item>
          {
            formData.type !== ACTIVATE_CODE_TYPE.Trial.id &&
            (
              <Form.Item
                label="Gói hóa đơn"
                name="invoice_qty"
                rules={[{
                  required: true,
                  message: 'Vui lòng chọn gói hóa đơn!'
                }]}
              >
                <Select disabled={isSubmitting}>
                  {
                    INVOICE_PLANS.map((item) => (
                      <Select.Option
                        key={item.invoice_limit}
                        value={item.invoice_limit}
                      >
                        {formatCurrencyValue(item.invoice_limit)} hóa đơn
                        ({formatCurrencyValue(item.invoice_limit * item.pricing)}đ)
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            )
          }
          <Form.Item
            label="Ghi chú"
            name="note"
          >
            <Input.TextArea disabled={isSubmitting}/>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

CreateActivateCodeModal.propTypes = {
  onSuccess: PropTypes.func,
};

export default CreateActivateCodeModal;
