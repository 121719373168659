import React from 'react';
import PropTypes from 'prop-types';
import {UserOutlined} from '@ant-design/icons';
import {Avatar} from 'antd';
import './UserAvatar.scss';

class UserAvatar extends React.PureComponent {
  render() {
    const {user, showAvatar, showName, showShortName} = this.props;
    if (!user) {
      return null;
    }
    let avatar = null;
    if (showAvatar) {
      if (!user.name) {
        avatar = <Avatar icon={<UserOutlined/>}/>;
      } else {
        avatar = <Avatar>{`${user.name}`.charAt(0)}</Avatar>;
      }
    }
    let name = '';
    if (showName) {
      name = user.name;
      if (showShortName && name) {
        name = `${name}`.trim().split(' ').pop();
      }
    }
    return (
      <div className="user-avatar">
        {avatar}
        {
          name &&
          <span className="user-name">{name}</span>
        }
      </div>
    );
  }
}

UserAvatar.propTypes = {
  user: PropTypes.object,
  showAvatar: PropTypes.bool,
  showName: PropTypes.bool,
  showShortName: PropTypes.bool,
};

UserAvatar.defaultProps = {
  showAvatar: true,
  showName: true,
  showShortName: false,
};

export default UserAvatar;
