import React from 'react';
import PropTypes from 'prop-types';
import {SaveOutlined} from '@ant-design/icons';
import {Button, Form, Input, message} from 'antd';
import {getErrorMessage} from '../../../common/helpers/index';
import {updateCompanySettingsAPI} from '../../../api/companies';

class CompanyForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  handleSubmit = (data) => {
    const {onSuccess} = this.props;
    this.setState({
      isSubmitting: true,
    });
    updateCompanySettingsAPI(data).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đã lưu!');
      onSuccess();
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  render() {
    const {company} = this.props;
    const {isSubmitting} = this.state;
    return (
      <Form
        initialValues={company}
        onFinish={this.handleSubmit}
        className="common-form edit-company-settings"
      >
        <Form.Item
          label="Alias"
          name="alias"
          rules={[{
            required: true,
            message: 'Vui lòng nhập alias!'
          }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Tên công ty"
          name="name"
          rules={[{
            required: true,
            message: 'Vui lòng nhập tên công ty!'
          }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Mã số thuế"
          name="tax_code"
          rules={[{
            required: true,
            message: 'Vui lòng nhập MST!'
          }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Địa chỉ"
          name="address"
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phone"
        >
          <Input/>
        </Form.Item>
        <Form.Item className="form-actions">
          <Button
            type="primary"
            htmlType="submit"
            className="submit-button"
            icon={<SaveOutlined/>}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Lưu thay đổi
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

CompanyForm.propTypes = {
  company: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default CompanyForm;
