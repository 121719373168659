import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_STORAGE_SERVICE_API_URL}/google`;

export const getGoogleLoginUrlAPI = () => {
  return Requester.get(`${API_URL}/auth/url`);
};

export const loginGoogleAPI = (data) => {
  return Requester.post(`${API_URL}/auth`, data);
};
