import React, {Fragment} from 'react';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Empty, message, Pagination, Popconfirm, Result, Skeleton, Table} from 'antd';
import {deleteInvoiceAPI, getInvoicesAPI} from '../../../api/invoices';
import {getErrorMessage} from '../../../common/helpers/index';
import HyperLink from '../../../common/components/HyperLink/HyperLink';
import {formatDate} from '../../../common/helpers';
import FileStatus from '../common/FileStatus/FileStatus';
import InvoiceDetailDrawer from '../InvoiceDetail/InvoiceDetailDrawer';
import {INVOICE_TYPE} from '../../../common/constants/common';
import {Link} from 'react-router-dom';
import InvoiceSearch from './InvoiceSearch';
import ReviewStatus from '../common/ReviewStatus/ReviewStatus';
import './Invoices.scss';
import InvoiceStatus from '../../../common/components/InvoiceStatus/InvoiceStatus';

class Invoices extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: INVOICE_TYPE.PURCHASE,
      data: [],
      searchParams: {},
      pagination: {
        page_number: 1,
        page_size: 10,
        total: 0,
      },
      isLoading: true,
      selectedIds: [],
      columns: [],
    };
    this.invoiceDetail = React.createRef();
  }

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.type !== this.props.type) {
      this.reset();
    }
    if (prevProps.companyId !== this.props.companyId) {
      this.reset();
    }
  }

  reset = () => {
    this.setColumns();
    this.setState(prevState => {
      return {
        pagination: {
          ...prevState.pagination,
          page_number: 1,
          total: 0,
        }
      };
    }, this.getData);
  };

  getData = () => {
    this.setState({
      isLoading: true,
    });
    this.handleGetData().then(res => {
      this.setState({
        isLoading: false,
        data: res?.data?.page_data || [],
        pagination: res?.data?.page_info,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isLoading: false,
        data: [],
      });
    });
  };

  handleGetData = async () => {
    const {type, companyId} = this.props;
    const {pagination, searchParams} = this.state;
    const params = {
      page_number: pagination.page_number,
      page_size: pagination.page_size,
      tax_code_id: companyId,
      type,
      ...searchParams,
    };
    let result = null;
    while (params.page_number >= 1) {
      result = await getInvoicesAPI(params);
      const {data: {page_data, page_info}} = result;
      if (!page_data.length && page_info.page_number > 1) {
        params.page_number = page_info.page_number - 1;
      } else {
        break;
      }
    }
    return result;
  };

  handleSearch = (params) => {
    this.setState(prevState => {
      return {
        searchParams: params,
        pagination: {
          ...prevState.pagination,
          page_number: 1,
        }
      };
    }, this.getData);
  };

  setColumns = () => {
    const {type} = this.props;
    let columns = [
      {
        title: 'Mã hóa đơn',
        dataIndex: 'code',
        width: 100,
        ellipsis: true,
      },
      {
        title: () => (
          <div>Số hóa đơn<br/>Ngày lập</div>
        ),
        dataIndex: 'no',
        align: 'center',
        width: 100,
        render: (value, r) => (
          <div className="text-center">
            <div className="text-ellipsis">{value}</div>
            <div className="text-ellipsis">{formatDate(r.issued_date)}</div>
          </div>
        ),
      },
      {
        title: () => (
          <div>Mẫu số<br/>Ký hiệu</div>
        ),
        dataIndex: 'pattern',
        align: 'center',
        width: 120,
        render: (value, r) => (
          <div className="text-center">
            <div className="text-ellipsis">{value}</div>
            <div className="text-ellipsis">{r.serial}</div>
          </div>
        )
      },
    ];
    if (type === INVOICE_TYPE.SALE) {
      columns.push({
        title: 'Người mua',
        dataIndex: 'buyer_name',
        render: (value, r) => (
          <div>
            <div className="text-ellipsis font-500">{value}</div>
            <div className="text-ellipsis">{r.buyer_tax_code}</div>
          </div>
        )
      });
    } else {
      columns.push({
        title: 'Người bán',
        dataIndex: 'seller_name',
        render: (value, r) => (
          <div>
            <div className="text-ellipsis font-500">{value}</div>
            <div className="text-ellipsis">{r.seller_tax_code}</div>
          </div>
        )
      });
    }
    const others = [
      {
        title: 'Số tiền thanh toán',
        dataIndex: 'total_payment_amount',
        width: 140,
        align: 'right',
        render: value => {
          if (!value || !value.toLocaleString) {
            return `${value}đ`;
          }
          return `${value.toLocaleString()}đ`;
        },
      },
      {
        title: 'Trạng thái hóa đơn',
        dataIndex: 'invoice_status',
        align: 'center',
        render: (value) => (
          <InvoiceStatus invoice_status={value}/>
        ),
        width: 120,
      },
      {
        title: 'Phê duyệt',
        dataIndex: 'review_status',
        align: 'center',
        render: value => (<ReviewStatus reviewStatus={value}/>),
        width: 90,
      },
      {
        title: 'File',
        dataIndex: 'files',
        align: 'center',
        render: value => (<FileStatus files={value}/>),
        width: 90,
      },
      {
        title: 'Thao tác',
        key: 'actions',
        width: 90,
        fixed: 'right',
        align: 'center',
        render: (value, record) => {
          return (
            <div className="actions text-center">
              <HyperLink
                className="pc-only"
                onClick={this.handleView(record)}
              >
                <EditOutlined/> Xem/Sửa
              </HyperLink>
              <Link
                className="mobile-only"
                to={`/invoices/${this.props.match.params.type}/${record.id}`}
              >
                <EditOutlined/> Sửa
              </Link>
            </div>
          );
        },
      },
    ];
    columns = [...columns, ...others];
    this.setState({
      columns,
    });
  };

  rowSelection = {
    columnWidth: 40,
    onChange: (selectedRowKeys) => {
      this.setState({
        selectedIds: selectedRowKeys,
      });
    },
  };

  handleView = (invoice) => () => {
    this.invoiceDetail.current.handleOpen(invoice);
  };

  handleTableChange = (page, pageSize) => {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page_number: page,
        page_size: pageSize,
      }
    }), this.getData);
  };

  handleDeleteAsync = async () => {
    const {selectedIds} = this.state;
    let successIds = [], errorIds = [];
    for (let i = 0; i < selectedIds.length; i++) {
      const id = selectedIds[i];
      try {
        await deleteInvoiceAPI(id);
        successIds.push(id);
      } catch (e) {
        errorIds.push(id);
      }
    }
    if (successIds.length > 0) {
      if (errorIds.length === 0) {
        message.info('Đã xóa');
      } else {
        message.warning(`Đã xóa ${successIds.length} hóa đơn, ${errorIds.length} không xóa được`);
      }
      this.setState({
        selectedIds: [],
      }, () => {
        this.getData();
        this.props.getCurrentUser();
      });
    } else if (errorIds.length > 0) {
      message.error('Xóa hóa đơn bị lỗi');
    }
  };

  handleDelete = () => {
    this.handleDeleteAsync().catch(error => {
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {invoiceTypeName, getCurrentUser} = this.props;
    const {isLoading, data, selectedIds, searchParams, columns, pagination} = this.state;
    const isSearch = Object.keys(searchParams).length > 0;
    const showSearchBox = data.length > 1 || isSearch;
    return (
      <Fragment>
        {
          showSearchBox &&
          <InvoiceSearch handleSearch={this.handleSearch} searchParams={searchParams}/>
        }
        {
          isLoading && !data.length &&
          <Skeleton/>
        }
        {
          !isLoading && !data.length &&
          <Fragment>
            {
              !isSearch &&
              <Result
                status="404"
                title="Chưa có hóa đơn"
                subTitle="Bạn chưa có hóa đơn nào. Click vào nút dưới đây để tải lên hóa đơn mới."
                extra={
                  <Link
                    className="ant-btn ant-btn-primary"
                    to={`/invoices/${invoiceTypeName}/new`}
                  >
                    <PlusOutlined/> Tạo hóa đơn
                  </Link>
                }
              />
            }
            {
              isSearch &&
              <Empty description="Không có kết quả"/>
            }
          </Fragment>
        }
        {
          !!data.length &&
          <Fragment>
            <div className="table-header">
              <div className="action-buttons">
                <Link
                  className="ant-btn ant-btn-primary"
                  to={`/invoices/${invoiceTypeName}/new`}
                >
                  <PlusOutlined/> Thêm mới
                </Link>
              </div>
            </div>
            {
              !!selectedIds.length &&
              <p>
                Đã chọn {selectedIds.length} hóa đơn.
                <Popconfirm
                  title={<div>Bạn có chắc muốn xóa {selectedIds.length} hóa đơn này không?</div>}
                  onConfirm={this.handleDelete}
                  okText="Xóa"
                  cancelText="Hủy bỏ"
                  okType="danger"
                >
                  <HyperLink style={{marginLeft: 10}}>Xóa</HyperLink>
                </Popconfirm>
              </p>
            }
            <Table
              size="small"
              className="common-table"
              columns={columns}
              bordered={true}
              rowKey={record => record.id}
              dataSource={data}
              pagination={false}
              loading={isLoading}
              rowSelection={{
                ...this.rowSelection,
                selectedRowKeys: selectedIds,
              }}
              scroll={{x: 1100}}
            />
            <div className="table-footer">
              <Pagination
                total={pagination.total}
                pageSize={pagination.page_size}
                current={pagination.page_number}
                onChange={this.handleTableChange}
              />
            </div>
          </Fragment>
        }
        <InvoiceDetailDrawer
          ref={this.invoiceDetail}
          invoiceType={invoiceTypeName}
          onUpdated={this.getData}
          getCurrentUser={getCurrentUser}
        />
      </Fragment>
    );
  }
}

export default Invoices;
