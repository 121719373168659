import React, {Fragment} from 'react';
import __escape from 'lodash/escape';
import {Breadcrumb, message, Skeleton, Typography} from 'antd';
import {getTicketDetailAPI} from '../../../api/tickets';
import {getErrorMessage} from '../../../common/helpers';
import TicketComments from './TicketComments';
import {history} from '../../../history';

const {Paragraph} = Typography;

class TicketDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      ticket: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match !== this.props.match) {
      this.getData();
    }
  }

  getData = () => {
    const {match: {params: {id}}} = this.props;
    this.setState({
      isFetching: true,
    });
    getTicketDetailAPI(id).then(res => {
      if (res?.data?.content) {
        res.data.content = __escape(`${res.data.content}`).replace(/(?:\r\n|\r|\n)/g, '<br>');
      }
      this.setState({
        isFetching: false,
        ticket: res.data,
      });
    }).catch(error => {
      this.setState({
        isFetching: false,
      });
      message.error(getErrorMessage(error));
    });
  };

  goBack = (event) => {
    if (event) {
      event.preventDefault();
    }
    history.push(`/support/tickets`);
  };

  render() {
    const {isFetching, ticket} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Hỗ trợ</Breadcrumb.Item>
          <Breadcrumb.Item href="/support/tickets" onClick={this.goBack}>Yêu cầu đã gửi</Breadcrumb.Item>
          {
            ticket &&
            <Breadcrumb.Item>{ticket.subject}</Breadcrumb.Item>
          }
        </Breadcrumb>
        <div className="main-content ticket-detail">
          {
            isFetching && !ticket && <Skeleton/>
          }
          {
            ticket &&
            <Fragment>
              <h1>{ticket.subject}</h1>
              <Paragraph>
                <div dangerouslySetInnerHTML={{__html: ticket.content}}/>
              </Paragraph>
              <TicketComments ticketId={ticket.id}/>
            </Fragment>
          }
        </div>
      </Fragment>
    );
  }
}

export default TicketDetail;
