import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Prompt} from 'react-router-dom';
import {Alert, Breadcrumb, message, Modal} from 'antd';
import {history} from '../../../history';
import UploadXMLFile from './UploadXMLFile';
import InvoiceForm from '../common/InvoiceForm/InvoiceForm';
import {createInvoiceAPI, updateInvoiceFilesAPI} from '../../../api/invoices';
import {getErrorMessage, getQueryParams} from '../../../common/helpers';
import {INVOICE_TYPE} from '../../../common/constants/common';
import HyperLink from '../../../common/components/HyperLink/HyperLink';
import {uploadFilesAPI} from '../../../api/files';
import {getCurrentUserAC, getUserCompaniesAC} from '../../../redux/actions/user';
import './NewInvoice.scss';

class NewInvoice extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: INVOICE_TYPE.PURCHASE,
      emailId: null,
      isSubmitting: false,
      isSubmitted: false,
      xmlFile: null,
      skipUpload: false,
      invoiceInfo: null,
      renderUploadKey: 0,
      checkResult: {},
    };
  }

  componentDidMount() {
    this.detectType();
    this.props.dispatch(getUserCompaniesAC());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.detectType();
    }
  }

  detectType = () => {
    const {match: {params}, location: {search}} = this.props;

    if (params.type !== 'sale' && params.type !== 'purchase') {
      history.replace('/invoices/purchase/new');
      return;
    }
    const state = {
      type: INVOICE_TYPE.PURCHASE,
    };
    if (params.type === 'sale') {
      state.type = INVOICE_TYPE.SALE;
    }

    const queryParams = getQueryParams(search);
    state.emailId = queryParams.emailId;

    this.setState(state);
  };

  onUploadXMLFileSuccess = (file, data) => {
    this.checkCompany(data, () => {
      this.setState({
        xmlFile: {
          ...file,
          hasData: !!data,
        },
        invoiceInfo: data,
      });
    }, () => {
      const {emailId} = this.state;
      if (emailId) {
        history.goBack();
        return;
      }
      this.setState({
        renderUploadKey: new Date().getTime(),
      });
    });
  };

  handleSkipUpload = () => {
    this.setState({
      skipUpload: true,
      invoiceInfo: null,
    });
  };

  checkCompany = (data, onOk, onCancel, isSubmit = false) => {
    const {companyByTaxCode} = this.props;
    if (!data || !data.seller_tax_code || !data.buyer_tax_code || Object.keys(companyByTaxCode).length === 0) {
      return onOk();
    }
    if (!companyByTaxCode[data.seller_tax_code] && !companyByTaxCode[data.buyer_tax_code]) {
      return this.alertCompany(onOk, onCancel);
    }
    if (isSubmit) {
      if (companyByTaxCode[data.buyer_tax_code]?.tax_code === data.buyer_tax_code) {
        data.tax_code_id = companyByTaxCode[data.buyer_tax_code].id;
        if (data.type === INVOICE_TYPE.SALE) {
          data.type = INVOICE_TYPE.PURCHASE;
          message.info('Đã đổi sang Hóa đơn đầu vào');
        }
      } else if (companyByTaxCode[data.seller_tax_code]?.tax_code === data.seller_tax_code) {
        data.tax_code_id = companyByTaxCode[data.seller_tax_code].id;
        if (data.type === INVOICE_TYPE.PURCHASE) {
          data.type = INVOICE_TYPE.SALE;
          message.info('Đã đổi sang Hóa đơn đầu ra');
        }
      }
    }
    return onOk();
  };

  alertCompany = (onOk, onCancel) => {
    Modal.confirm({
      title: 'Cảnh báo',
      content: 'File hóa đơn này không có mã số thuế mà bạn làm việc!',
      okText: 'OK',
      onOk: onCancel,
      cancelButtonProps: {
        className: 'd-none',
      },
    });
  };

  goBack = (event, type) => {
    if (type === undefined) {
      type = this.state.type;
    }
    if (event) {
      event.preventDefault();
    }
    history.push(`/invoices/${type === INVOICE_TYPE.PURCHASE ? 'purchase' : 'sale'}`);
  };

  setCheckResult = (values) => {
    this.setState(prevState => ({
      checkResult: {
        ...prevState.checkResult,
        ...values,
      },
    }));
  };

  handleSubmitAsync = async (formData) => {
    const {checkResult} = this.state;
    const {files, ...others} = formData;
    if (!others.metadata) {
      others.metadata = {};
    }
    others.metadata.check_result = checkResult;
    const {data: {id}} = await createInvoiceAPI(others);
    const {xmlFile} = this.state;
    let hasError = false;
    const invoiceFiles = [];
    if (xmlFile) {
      invoiceFiles.push(xmlFile);
    }
    if (files?.length) {
      try {
        const existedFile = files.filter(file => file.tmp_name);
        const uploadFiles = files.filter(file => !file.tmp_name).map(file => file.originFileObj || file);
        existedFile.forEach(file => {
          invoiceFiles.push(file);
        });
        if (uploadFiles.length) {
          const {data: {success}} = await uploadFilesAPI(uploadFiles);
          if (success.length !== uploadFiles.length) {
            hasError = true;
          }
          success.forEach(file => {
            invoiceFiles.push(file);
          });
        }
      } catch (e) {
        hasError = true;
      }
    }
    if (invoiceFiles.length) {
      try {
        const {data} = await updateInvoiceFilesAPI(id, invoiceFiles);
        if (!data) {
          hasError = true;
        }
      } catch (e) {
        hasError = true;
      }
    }
    if (hasError) {
      message.warning(`Hóa đơn được tạo nhưng không có một số file, vui lòng kiểm tra lại sau!`);
      return;
    }
    message.success('Thành công!');
  };

  handleSubmit = (postData) => {
    this.setState({
      isSubmitting: true,
    });
    this.handleSubmitAsync(postData).then(() => {
      this.setState({
        isSubmitting: false,
        isSubmitted: true,
      }, () => {
        this.props.dispatch(getCurrentUserAC());
        this.goBack(null, postData.type);
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  preSubmit = (formData) => {
    const {type, invoiceInfo} = this.state;
    const postData = {
      ...invoiceInfo,
      ...formData,
      type,
    };
    this.checkCompany(postData, () => {
      this.handleSubmit(postData);
    }, () => {
    }, true);
  };

  render() {
    const {isSubmitting, isSubmitted, xmlFile, skipUpload, invoiceInfo, type, renderUploadKey, emailId} = this.state;
    return (
      <Fragment>
        <Prompt message="Bạn có chắc muốn rời khỏi trang này?" when={!!xmlFile && !isSubmitted}/>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item
            href={`/invoices/${type === INVOICE_TYPE.PURCHASE ? 'purchase' : 'sale'}`}
            onClick={this.goBack}
          >
            {type === INVOICE_TYPE.PURCHASE ? 'Hóa đơn đầu vào' : 'Hóa đơn đầu ra'}
          </Breadcrumb.Item>
          <Breadcrumb.Item>Hóa đơn mới</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content">
          {
            !xmlFile && !skipUpload &&
            <Fragment>
              <UploadXMLFile
                emailId={emailId}
                onSuccess={this.onUploadXMLFileSuccess}
                key={renderUploadKey}
              />
              {
                !emailId &&
                <p className="skip-upload">
                  Hoặc <HyperLink onClick={this.handleSkipUpload}>tạo hóa đơn mà không upload xml file</HyperLink>
                </p>
              }
            </Fragment>
          }
          {
            (xmlFile || skipUpload) &&
            <Fragment>
              {
                invoiceInfo?.__auto_completed_fields &&
                <Alert
                  message={
                    <div>
                      Một số trường dữ liệu không thể hiện trong file xml và được tính toán tự động. <strong>Vui lòng
                      kiểm tra kỹ dữ liệu các trường đánh dấu màu vàng!</strong>
                    </div>
                  }
                  type="warning"
                  showIcon={true}
                  className="mb-10"
                />
              }
              <InvoiceForm
                xmlFile={xmlFile}
                initialValues={invoiceInfo}
                isSubmitting={isSubmitting}
                handleCancel={this.goBack}
                handleSubmit={this.preSubmit}
                setCheckResult={this.setCheckResult}
              />
            </Fragment>
          }
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  companyByTaxCode: state.user.companies.byTaxCode,
});

export default connect(mapStateToProps)(NewInvoice);
