import React from 'react';
import {Button, Col, Form, Input, Radio, Row, Upload} from 'antd';
import InvoiceConstants from '../../../../common/constants/invoice';
import {CheckCircleOutlined, UploadOutlined} from '@ant-design/icons';
import {getFileExt} from '../../../../common/helpers';
import {ALLOWED_UPLOAD_FILE_EXTENSIONS, MAX_UPLOAD_FILE_SIZE} from '../../../../common/constants/common';

const normFile = e => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

class OthersInfo extends React.PureComponent {
  objectURLs = {};

  componentWillUnmount() {
    Object.values(this.objectURLs).forEach(url => {
      URL.revokeObjectURL(url);
    });
  }

  uploadProps = {
    beforeUpload: (file) => {
      file.url = URL.createObjectURL(file);
      this.objectURLs[file.uid] = file.url;
      return false;
    },
  };

  validateFile = async (rule, value) => {
    if (!value) {
      return;
    }
    for (let i = 0; i < value.length; i++) {
      const file = value[i];
      const ext = getFileExt(file.name);
      if (ext && !ALLOWED_UPLOAD_FILE_EXTENSIONS.includes(ext)) {
        throw new Error(`Định dạng file không được hỗ trợ, chỉ hỗ trợ các tệp sau: ${ALLOWED_UPLOAD_FILE_EXTENSIONS.join(', ')}.`);
      }
      if (file.size > MAX_UPLOAD_FILE_SIZE.value) {
        throw new Error(`Dung lượng file tối đa là ${MAX_UPLOAD_FILE_SIZE.textValue}`);
      }
    }
  };

  render() {
    const {xmlFile} = this.props;
    return (
      <div className="form-section">
        <h3>Thông tin khác</h3>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
          }}
        >
          <Col xs={{span: 24}} md={{span: 12}}>
            <Form.Item
              name="invoice_status"
              label="Trạng thái hóa đơn"
              rules={[{
                required: true,
                message: 'Vui lòng chọn trạng thái hóa đơn!',
              }]}
            >
              <Radio.Group>
                <Radio value={InvoiceConstants.InvoiceStatus.HoaDonGoc}>Hóa đơn gốc</Radio>
                <Radio value={InvoiceConstants.InvoiceStatus.HoaDonDaXoaBo}>Hóa đơn đã xóa bỏ</Radio>
                <Radio value={InvoiceConstants.InvoiceStatus.HoaDonThayThe}>Hóa đơn thay thế</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              name="item_type"
              label="Loại hóa đơn"
              rules={[{
                required: true,
                message: 'Vui lòng chọn loại hóa đơn!',
              }]}
            >
              <Radio.Group>
                <Radio value={InvoiceConstants.ItemType.Product}>Hàng hóa</Radio>
                <Radio value={InvoiceConstants.ItemType.Service}>Dịch vụ</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
          }}
        >
          <Col xs={{span: 24}} md={{span: 9}}>
            <Form.Item
              name={['metadata', 'portal_link']}
              label="Link tra cứu hóa đơn"
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 3}}>
            <Form.Item
              name={['metadata', 'f_key']}
              label="Mã tra cứu"
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
          }}
        >
          <Col xs={{span: 24}} md={{span: 12}}>
            <Form.Item
              name="note"
              label="Ghi chú"
            >
              <Input.TextArea rows={4}/>
            </Form.Item>
          </Col>
        </Row>

        <h3>File hóa đơn</h3>
        <Row>
          <Col>
            {
              xmlFile &&
              <Form.Item>
                <div className="xml-file">
                  <CheckCircleOutlined style={{marginRight: 5}} className="text-green"/> {xmlFile.name}
                </div>
              </Form.Item>
            }
            <Form.Item
              name="files"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{
                validator: this.validateFile,
              }]}
            >
              <Upload {...this.uploadProps}>
                <Button>
                  <UploadOutlined/> Thêm file
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
}

export default OthersInfo;
