import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/users/me/settings`;

export const upsertUserSettingAPI = (key, data) => {
  return Requester.put(`${API_URL}/${key}`, data);
};

export const getUserSettingAPI = (key, params = null) => {
  return Requester.get(`${API_URL}/${key}`, params);
};

export const getUserSettingsAPI = (params = null) => {
  return Requester.get(`${API_URL}/`, params);
};
