import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {formatDate, getErrorMessage} from '../../common/helpers';
import {getEmailsAPI} from '../../api/emails';
import {Breadcrumb, message, Pagination, Result, Skeleton, Table, Typography} from 'antd';
import EmailDetailModal from './EmailDetailModal';
import HyperLink from '../../common/components/HyperLink/HyperLink';
import {CheckCircleOutlined, WarningOutlined} from '@ant-design/icons';

const {Paragraph, Text} = Typography;

class Emails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchParams: {},
      pagination: {
        page_number: 1,
        page_size: 10,
        total: 0,
      },
      isLoading: true,
    };
    this.emailDetailModal = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  columns = [
    {
      title: 'Người gửi',
      dataIndex: 'sender',
      width: 200,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'subject',
      ellipsis: true,
      render: (value, record) => (
        <HyperLink
          className="text-black"
          onClick={this.handleViewEmail(record)}
        >
          {value}
        </HyperLink>
      ),
    },
    {
      title: 'Tệp đính kèm',
      dataIndex: 'attachments',
      ellipsis: true,
      width: 150,
      align: 'center',
      render: (value) => <span>{value?.length}</span>,
    },
    {
      title: 'Trạng thái hóa đơn',
      dataIndex: 'import_status',
      align: 'center',
      width: 150,
      ellipsis: true,
      render: value => {
        switch (value) {
          case 0:
            return <span className="text-gray">Đang xử lý</span>;
          case 1:
            return <span className="text-green">Đã import hóa đơn</span>;
          case 2:
            return <span className="text-blue">Không import</span>;
          default:
            return <span>-</span>;
        }
      },
    },
    {
      title: 'Ngày nhận',
      dataIndex: 'sent_at',
      align: 'center',
      render: value => <span>{formatDate(value)}</span>,
      width: 130,
    },
  ];

  handleTableChange = (page, pageSize) => {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page_number: page,
        page_size: pageSize,
      }
    }), this.getData);
  };

  getData = () => {
    this.setState({
      isLoading: true,
    });
    this.handleGetData().then(res => {
      this.setState({
        isLoading: false,
        data: res?.data?.page_data || [],
        pagination: res?.data?.page_info,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isLoading: false,
        data: [],
      });
    });
  };

  handleGetData = async () => {
    const {pagination} = this.state;
    const params = {
      page_number: pagination.page_number,
      page_size: pagination.page_size,
    };
    let result = null;
    while (params.page_number >= 1) {
      result = await getEmailsAPI(params);
      const {data: {page_data, page_info}} = result;
      if (!page_data.length && page_info.page_number > 1) {
        params.page_number = page_info.page_number - 1;
      } else {
        break;
      }
    }
    return result;
  };

  handleViewEmail = (email) => () => {
    this.emailDetailModal.current.handleOpen(email.subject, email.id);
  };

  render() {
    const {user} = this.props;
    const {isLoading, data, pagination} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Hộp thư</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content tickets">
          {
            isLoading && !data.length &&
            <Skeleton/>
          }
          {
            !isLoading && !data.length &&
            <Fragment>
              <Result
                status="404"
                title="Không có có email nào"
                subTitle={
                  <div>Bạn có thể gửi email về địa chỉ <strong>{user.username}@quanlyhoadon.net</strong> để import hóa
                    đơn.</div>
                }
              >
                <div className="desc">
                  <Paragraph>
                    <Text strong>
                      Hướng dẫn nhập hóa đơn qua email:
                    </Text>
                  </Paragraph>
                  <Paragraph>
                    <CheckCircleOutlined className="text-green"/> Gửi email có chứa file hóa đơn (xml, pdf) về địa
                    chỉ <strong>{user.username}@quanlyhoadon.net</strong>. (File xml là bắt buộc phải có).
                  </Paragraph>
                  <Paragraph>
                    <CheckCircleOutlined className="text-green"/> Chờ tối đa 30 phút để hệ thống xử lý file hóa đơn của
                    bạn, nếu sau 30 phút mà bạn chưa thấy hóa đơn được nhập, vui lòng liên hệ hỗ trợ.
                  </Paragraph>
                  <Paragraph>
                    <CheckCircleOutlined className="text-green"/> Kiểm tra và duyệt lại thông tin hóa đơn trong
                    mục <strong>Hóa đơn</strong>.
                  </Paragraph>
                  <Paragraph className="text-gray">
                    <WarningOutlined/> Lưu ý: Tính năng này vẫn đang trong quá trình thử nghiệm. Nếu bạn phát hiện ra
                    bất cứ lỗi gì, hãy báo cho chúng tôi để giúp chúng tôi cải thiện dịch vụ. Xin cảm ơn!
                  </Paragraph>
                </div>
              </Result>
            </Fragment>
          }
          {
            !!data.length &&
            <Fragment>
              <Table
                size="small"
                className="common-table"
                columns={this.columns}
                bordered={true}
                rowKey={record => record.id}
                dataSource={data}
                pagination={false}
                loading={isLoading}
                scroll={{x: 600}}
              />
              <div className="table-footer">
                <Pagination
                  total={pagination.total}
                  pageSize={pagination.page_size}
                  current={pagination.page_number}
                  onChange={this.handleTableChange}
                />
              </div>
            </Fragment>
          }
        </div>
        <EmailDetailModal ref={this.emailDetailModal}/>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.info,
});

export default connect(mapStateToProps)(Emails);
