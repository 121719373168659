export const ERROR_MESSAGES = {
  API_KEY_1: 'API Key không tồn tại',
  AUTH_0: 'Chưa đăng nhập',
  AUTH_1: 'Phiên làm việc đã hết hạn',
  AUTH_2: 'Access denied',
  AUTH_3: 'Không cấp đủ quyền truy cập',
  AUTH_4: 'Bạn đã từng liên kết tài khoản này, vui lòng liên kết lại lần nữa',
  AUTH_5: 'Đã liên kết',
  COMPANY_MISSING: 'Vui lòng thiết lập công ty trước',
  EMAIL_0: 'Không thể xử lý email',
  FEATURE_NOT_AVAILABLE: 'Tính năng không có sẵn, vui lòng nâng cấp tài khoản để sử dụng',
  FILE_0: 'Upload file bị lỗi',
  FILE_1: 'Định dạng file không được hỗ trợ',
  FILE_2: 'File quá lớn',
  FILE_3: 'Không thể đọc được xml file',
  FILE_4: 'File không tồn tại',
  FILE_5: 'Không đọc được file metadata',
  FILE_6: 'Không có file nào được upload',
  FILE_7: 'Không có file xml nào được upload',
  FILE_8: 'Không có file hợp lệ nào trong file zip',
  INP_0: 'Dữ liệu không hợp lệ',
  INP_1: 'Dữ liệu không hợp lệ',
  LIMIT_REACHED: 'Bạn đã đạt giới hạn tài nguyên, vui lòng nâng cấp tài khoản để tiếp tục',
  LOGIN_0: 'Sai tên truy cập hoặc mật khẩu',
  ORDER_0: 'Người dùng này đã nâng cấp tài khoản',
  ORDER_1: 'Đã đạt giới hạn nợ tối đa, vui lòng nạp tiền để tiếp tục',
  PASSWORD_0: 'Mật khẩu không chính xác',
  TAX_CODE_0: 'Mã số thuế đã tồn tại',
  TAX_CODE_1: 'Mã số thuế không tồn tại',
  TOKEN_0: 'Token không hợp lệ hoặc đã hết hạn',
  TOKEN_1: 'Token đã được sử dụng', // used
  TOKEN_2: 'Token đã hết hạn', // expired
  TOKEN_3: 'Đã đạt số lượt sử dụng tối đa', // expired
  USER_0: 'Người dùng không tồn tại',
  USER_1: 'Tài khoản đã bị cấm',
};
