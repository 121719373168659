import HTTPClient from './HTTPClient';
import Store from '../../redux/store';
import {logoutAC, refreshTokenSuccessAC} from '../../redux/actions/user';
import {refreshTokenAPI} from '../../api/auth';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const defaultConfig = {
  __auth: true,
};

/**
 * List of callback to recall after renew access token success
 * @type {Array}
 */
let subscribers = [];

/**
 * Flag is refreshing token
 * @type {boolean}
 */
let isRefreshingToken = false;

const setHeader = (config, key, value) => {
  if (!config.headers) {
    config.headers = {};
  }
  config.headers[key] = value;
};

const onRequest = (config) => {
  config = {...defaultConfig, ...config};

  setHeader(config, 'x-client-id', CLIENT_ID);

  if (config.__auth && !config?.headers?.Authorization) {
    const user = Store.getState().user;
    if (user.auth.token) {
      const accessToken = user.auth.token.access_token;
      setHeader(config, 'Authorization', `Bearer ${accessToken}`);
    }
  }

  if (`${config.method}`.toLowerCase() === 'get' && !config?.params?.t) {
    if (!config.params) {
      config.params = {};
    }
    config.params.t = new Date().getTime();
  }

  return config;
};

export const onResponse = (response) => {
  const {data} = response;
  if (data && data.metadata && data.metadata.code !== 'SUCCESS') {
    const error = new Error('Request failed');
    error['response'] = response;
    return Promise.reject(error);
  }
  if (data) {
    return data;
  }
  return response;
};

export const onResponseError = (error) => {
  if (error.response) {
    const {status} = error.response;
    if (status === 401) {
      return handleError401(error);
    }
  }
  return Promise.reject(error);
};

const handleError401 = (error) => {
  const {config} = error;
  let refresh = false;
  if (!isRefreshingToken) {
    refresh = true;
  }
  return new Promise((resolve, reject) => {
    if (refresh) {
      isRefreshingToken = true;
      handleRefreshAccessToken().then(data => {
        isRefreshingToken = false;
        subscribers.forEach(callback => callback(data.token.access_token));
        subscribers = [];
        Store.dispatch(refreshTokenSuccessAC(data));
      }).catch(() => {
        isRefreshingToken = false;
        reject(error);
        Store.dispatch(logoutAC(true));
      });
    }
    subscribers.push(accessToken => {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      resolve(Requester.axiosInstance({...config, __isRetry: true}));
    });
  });
};

/**
 * Renew the access token
 * @return {*}
 */
const handleRefreshAccessToken = async () => {
  const user = Store.getState().user;
  if (!user?.auth?.token?.refresh_token) {
    throw new Error('No refresh token found');
  }
  const {data} = await refreshTokenAPI(user.auth.token);
  return data;
};

const Requester = new HTTPClient({
  onRequest: onRequest,
  onResponse: onResponse,
  onResponseError: onResponseError,
});

export default Requester;
