import React from 'react';
import {connect} from 'react-redux';
import {getErrorMessage, publicUrl} from '../../../common/helpers';
import {Button, notification} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {addTaxCodeAPI} from '../../../api/tax_codes';
// import {addStorageAccountAPI} from '../../../api/storage';
// import {STORAGE_TYPE} from '../../../common/constants/common';
import {getCurrentUserAC} from '../../../redux/actions/user';
import {upsertUserSettingAPI} from '../../../api/settings';

class Finish extends React.PureComponent {
  state = {
    isSubmitting: false,
    submitTime: 0,
    error: null,
  };

  handleSubmit = async () => {
    const {data} = this.props;
    try {
      await addTaxCodeAPI({
        name: data.companyName,
        tax_code: data.taxCode,
      });
    } catch (e) {
      notification.error({
        message: `Không thêm được mã số thuế`,
        description: <div><strong>Lỗi:</strong> {getErrorMessage(e)}<br/>Vui lòng thử lại sau trong mục <strong>Thiết
          lập &gt; Mã số thuế
          làm việc</strong>.</div>,
        duration: 0,
      });
    }
    // await addStorageAccountAPI({
    //   type: STORAGE_TYPE.INTERNAL_SERVER.id,
    // });
    await upsertUserSettingAPI('completed-welcome-tutorial', {
      value: 1,
    });
  };

  submit = () => {
    this.setState({
      isSubmitting: true,
      submitTime: new Date().getTime(),
    });
    this.handleSubmit().then(() => {
      this.props.dispatch(getCurrentUserAC());
    }).catch(error => {
      this.setState({
        isSubmitting: false,
        error,
      });
    });
  };

  render() {
    const {setStep} = this.props;
    const {isSubmitting} = this.state;
    return (
      <div className="step two-col finish">
        <div className="col col-img">
          <figure style={{backgroundImage: `url(${publicUrl('/assets/images/banner/happy.svg')})`}}/>
        </div>
        <div className="col col-main">
          <div className="--title">Hoàn tất!</div>
          <div className="--content">
            <p>Bạn đã hoàn thành bài hướng dẫn sử dụng dịch vụ.</p>
            <p>Nếu có bất cứ góp ý hoặc thắc mắc cần hỗ trợ nào, hãy gửi cho chúng tôi tại mục <strong>Hỗ trợ
              nhé</strong>!</p>
          </div>
          <div className="--footer two-button">
            <Button onClick={setStep('tax-code')} disabled={isSubmitting}>
              <LeftOutlined/> Quay lại
            </Button>
            <Button type="primary" loading={isSubmitting} onClick={this.submit}>
              Hoàn tất <RightOutlined/>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Finish);
