import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Form, message, Modal, Radio, Select} from 'antd';
import {STORAGE_DELETE_RULE, STORAGE_TYPE} from '../../../../common/constants/common';
import {addStorageAccountAPI} from '../../../../api/storage';
import {getErrorMessage} from '../../../../common/helpers';
import {getGoogleLoginUrlAPI} from '../../../../api/google';

const {Option} = Select;
const {confirm} = Modal;

const STORAGE_TYPES = Object.values(STORAGE_TYPE);

const defaultData = {
  type: STORAGE_TYPE.GOOGLE_DRIVE.id,
  delete_rule: STORAGE_TYPE.GOOGLE_DRIVE.defaultDeleteRule,
};

class UpsertStorageAccountModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      formData: {},
      initialFormData: {},
      isSubmitting: false,
      renderKey: 0,
      options: {},
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('storage', this.watchStorageChange);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.watchStorageChange);
  }

  watchStorageChange = (event) => {
    if (event && event.key === 'google-auth-result') {
      if (event.newValue && !event.oldValue) {
        if (event.newValue === '1') {
          message.success('Đã liên kết!');
          this.handleSuccess();
        } else {
          message.error('Liên kết không thành công!');
          this.setState({
            isSubmitting: false,
          });
        }
        localStorage.removeItem('google-auth-result');
      }
    }
  };

  onValuesChange = (changedFields, allFields) => {
    this.setState({
      formData: allFields,
    });
  };

  onTypeChange = (value) => {
    const selectedType = STORAGE_TYPES.find(item => item.id === value);
    this.form.current.setFieldsValue({
      type: value,
      delete_rule: selectedType?.defaultDeleteRule,
    });
  };

  handleOpen = (account = null, options = {}) => {
    const formData = account ? {...account} : {...defaultData};
    const initialFormData = {...formData};
    this.setState({
      isOpen: true,
      isSubmitting: false,
      formData,
      initialFormData,
      renderKey: new Date().getTime(),
      options,
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
      isSubmitting: false,
    });
  };

  handleSuccess = () => {
    this.setState({
      isOpen: false,
      isSubmitting: false,
    });
    this.props.onSuccess();
  };

  handleGoogleDriveAuth = async () => {
    this.setState({
      isSubmitting: true,
    });
    try {
      const {data: {login_url}} = await getGoogleLoginUrlAPI();
      window.open(login_url);
    } catch (e) {
      this.setState({
        isSubmitting: false,
      });
      message.error(getErrorMessage(e));
    }
  };

  handleAddInternalStorage = (values) => {
    addStorageAccountAPI(values).then(() => {
      message.success('Đã lưu!');
      this.handleSuccess();
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      }, this.handleClose);
    });
  };

  handleSubmit = (values) => {
    if (values.type === STORAGE_TYPE.INTERNAL_SERVER.id) {
      this.setState({
        isSubmitting: true,
      });
      return this.handleAddInternalStorage(values);
    }
    if (values.type === STORAGE_TYPE.GOOGLE_DRIVE.id) {
      confirm({
        title: 'Thêm tài khoản Google Drive',
        okText: 'Ok, tôi đồng ý',
        cancelText: 'Hủy bỏ',
        content: (
          <div>
            <Alert
              message="Bạn sẽ được chuyển đến Google ngay bây giờ. Vui lòng đăng nhập và cấp quyền cho phép chúng tôi truy cập tài khoản Drive của bạn!"
              type="info"
              showIcon={true}
            />
            <p/>
            <Alert
              message={`Sau khi liên kết thành công, chúng tôi sẽ tạo ra 1 thư mục có tên "QuanLyHoaDon.Net" trong Google Drive của bạn, vui lòng không xóa hoặc chỉnh sửa thư mục này và bất kì file nào trong đó!`}
              type="warning"
              showIcon={true}
            />
          </div>
        ),
        onOk: this.handleGoogleDriveAuth,
      });
    }
  };

  submit = () => {
    this.form.current.submit();
  };

  render() {
    const {isOpen, isSubmitting, formData, initialFormData, renderKey, options} = this.state;
    const isEdit = !!formData.id;
    const disableInternalStorage = options.hasInternalStorage && formData.type === STORAGE_TYPE.INTERNAL_SERVER.id;
    const canSubmit = !disableInternalStorage && !isSubmitting;
    return (
      <Modal
        title={isEdit ? 'Chỉnh sửa' : 'Thêm phương thức'}
        visible={isOpen}
        maskClosable={false}
        onOk={this.handleClose}
        onCancel={this.handleClose}
        footer={[
          <Button key="close" type="default" disabled={isSubmitting} onClick={this.handleClose}>
            Hủy bỏ
          </Button>,
          <Button key="submit" type="primary" disabled={!canSubmit} loading={isSubmitting} onClick={this.submit}>
            {
              formData.type === STORAGE_TYPE.INTERNAL_SERVER.id && 'Hoàn tất'
            }
            {
              formData.type === STORAGE_TYPE.GOOGLE_DRIVE.id && 'Tiếp tục'
            }
          </Button>,
        ]}
      >
        <Form
          className="upsert-storage-form common-form"
          ref={this.form}
          key={renderKey}
          initialValues={initialFormData}
          onFinish={this.handleSubmit}
          onValuesChange={this.onValuesChange}
        >
          {
            disableInternalStorage &&
            <Alert
              type="error"
              message={`Bạn chỉ có thể có 1 phương thức lưu trữ trên ${STORAGE_TYPE.INTERNAL_SERVER.name}`}
            />
          }
          <Form.Item
            name="type"
            label="Lưu trữ tại"
            rules={[{required: true, message: 'Vui lòng chọn một giá trị'}]}
          >
            <Select onChange={this.onTypeChange} disabled={isSubmitting}>
              {
                STORAGE_TYPES.map((item) => (
                  <Option value={item.id} key={item.id}>{item.name}</Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            name="delete_rule"
            label="Khi xóa hóa đơn"
            rules={[{required: true, message: 'Vui lòng chọn một giá trị'}]}
            style={{display: 'none'}}
          >
            <Radio.Group className="vertical" disabled={isSubmitting}>
              <Radio
                value={STORAGE_DELETE_RULE.KEEP_FILES}
                disabled={formData.type === STORAGE_TYPE.INTERNAL_SERVER.id}>
                Không xóa các file đã lưu trữ
              </Radio>
              <Radio
                value={STORAGE_DELETE_RULE.DELETE_FILES}
              >
                Xóa các file đã lưu trữ
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

UpsertStorageAccountModal.propTypes = {
  onSuccess: PropTypes.func,
  account: PropTypes.object,
};

export default UpsertStorageAccountModal;
