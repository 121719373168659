import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_PARSER_SERVICE_API_URL}`;


export const getRevocationsAPI = () => {
  return Requester.get(`${API_URL}/revocations`);
};

export const addRevocationAPI = (data) => {
  return Requester.post(`${API_URL}/revocations`, data);
};

export const deleteRevocationAPI = (id) => {
  return Requester.delete(`${API_URL}/revocations/${id}`);
};

export const updateRevocationAPI = (data) => {
  return Requester.put(`${API_URL}/revocations`, data);
};
