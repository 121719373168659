import React from 'react';
import PropTypes from 'prop-types';
import {SaveOutlined} from '@ant-design/icons';
import {Button, Form, Input, message, Radio} from 'antd';
import {updateCurrentUserAPI} from '../../../api/users';
import {getErrorMessage} from '../../../common/helpers/index';

class AccountForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  handleSubmit = (data) => {
    const {onSuccess} = this.props;
    this.setState({
      isSubmitting: true,
    });
    updateCurrentUserAPI(data).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đã lưu!');
      onSuccess();
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  render() {
    const {user} = this.props;
    const {isSubmitting} = this.state;
    return (
      <Form
        initialValues={user}
        onFinish={this.handleSubmit}
        className="common-form edit-profile"
      >
        <Form.Item
          label="Họ tên"
          name="name"
          rules={[{
            required: true,
            message: 'Vui lòng nhập họ tên!'
          }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{
            required: true,
            message: 'Vui lòng nhập email!'
          }]}
        >
          <Input disabled={true}/>
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phone"
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Địa chỉ"
          name="address"
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Giới tính"
          name="gender"
        >
          <Radio.Group>
            <Radio value={1}>Nam</Radio>
            <Radio value={0}>Nữ</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item className="form-actions">
          <Button
            type="primary"
            htmlType="submit"
            className="submit-button"
            icon={<SaveOutlined/>}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Lưu thay đổi
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

AccountForm.propTypes = {
  user: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default AccountForm;
