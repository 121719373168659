import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, message, Modal} from 'antd';
import {getErrorMessage} from '../../../common/helpers/index';
import {activateActivateCodeAPI} from '../../../api/manage/activateCodes';

class ActivateCodeModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isSubmitting: false,
      isFetching: false,
      renderKey: 0,
      formData: {},
    };
    this.defaultState = {...this.state};
    this.form = React.createRef();
  }

  handleOpen = () => {
    this.setState({
      ...this.defaultState,
      isOpen: true,
      renderKey: new Date().getTime(),
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  onValuesChange = (changedFields, allFields) => {
    this.setState({
      formData: allFields,
    });
  };

  handleSubmit = (values) => {
    const {onSuccess} = this.props;
    this.setState({
      isSubmitting: true,
    });
    activateActivateCodeAPI(values).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đã kích hoạt!');
      this.handleClose();
      if (onSuccess) {
        onSuccess();
      }
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  submit = () => {
    this.form.current.submit();
  };

  render() {
    const {isOpen, renderKey} = this.state;
    return (
      <Modal
        title="Kích hoạt dịch vụ"
        visible={isOpen}
        width={450}
        okText="Kích hoạt"
        cancelText="Hủy bỏ"
        maskClosable={false}
        onOk={this.submit}
        onCancel={this.handleClose}
      >
        <Form
          className="common-form"
          ref={this.form}
          key={renderKey}
          onFinish={this.handleSubmit}
          onValuesChange={this.onValuesChange}
        >
          <Form.Item
            label="Mã kích hoạt"
            name="code"
            rules={[{
              required: true,
              message: 'Vui lòng nhập mã kích hoạt!'
            }]}
          >
            <Input/>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

ActivateCodeModal.propTypes = {
  onSuccess: PropTypes.func,
};

export default ActivateCodeModal;
