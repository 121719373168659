import React, {Fragment} from 'react';
import {getCompanySettingsAPI} from '../../../api/companies';
import {Breadcrumb, message, Skeleton} from 'antd';
import {getErrorMessage} from '../../../common/helpers';
import CompanyForm from './CompanyForm';

class CompanySettings extends React.PureComponent {
  state = {
    isFetching: true,
    isSubmitting: false,
    company: null,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({
      isFetching: true,
    });
    getCompanySettingsAPI().then(res => {
      this.setState({
        isFetching: false,
        company: res.data,
      });
    }).catch(error => {
      this.setState({
        isFetching: false,
      });
      message.error(getErrorMessage(error));
    });
  };

  render() {
    const {company, isFetching} = this.state;
    return (
      <Fragment>
        <Breadcrumb className="main-breadcrumb">
          <Breadcrumb.Item>Thiết lập thông tin công ty</Breadcrumb.Item>
        </Breadcrumb>
        <div className="main-content company-setting">
          {
            isFetching &&
            <Skeleton/>
          }
          {
            !isFetching &&
            <CompanyForm company={company} onSuccess={this.getData}/>
          }
        </div>
      </Fragment>
    );
  }
}

export default CompanySettings;
