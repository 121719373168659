const PLANS = {
  FREE: {
    id: 1,
    name: 'FREE',
    limits: {
      invoice: 200,
      taxCode: 1,
      extStorage: 1,
      checkInvoice: 0,
      subUser: 0,
    },
    tagColor: '#87d068',
  },
  PREMIUM: {
    id: 2,
    name: 'PREMIUM',
    limits: {
      taxCode: 10,
      extStorage: 3,
    },
    tagColor: '#ffc729',
  },
  CUSTOM: {
    id: 9,
    name: 'CUSTOM',
    tagColor: '#2db7f5',
  },
};

export const PLAN_BY_ID = Object.values(PLANS).reduce(function (result, item) {
  result[item.id] = item;
  return result;
}, {});

export const INVOICE_PLANS = [
  {
    invoice_limit: 500,
    pricing: 1000,
  },
  {
    invoice_limit: 1000,
    pricing: 900,
  },
  {
    invoice_limit: 2000,
    pricing: 800,
  },
  {
    invoice_limit: 5000,
    pricing: 700,
  },
  {
    invoice_limit: 10000,
    pricing: 600,
  },
  {
    invoice_limit: 20000,
    pricing: 500,
  },
];


export default PLANS;
