import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Input, Row, Select} from 'antd';
import {ClearOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons';
import ROLES from '../../../../common/constants/roles';

const rowProps = {
  gutter: {
    xs: 4,
    sm: 8,
    md: 12,
    lg: 24,
  }
};

const RoleOptions = [{id: 0, name: 'Tất cả'}, ROLES.NORMAL_USER, ROLES.SUB_USER, ROLES.RESELLER, ROLES.ADMIN];

class UserSearch extends React.PureComponent {
  state = {};
  formEl = React.createRef();

  toggleAdvancedSearch = () => {
    this.setState(prevState => ({
      advancedSearch: !prevState.advancedSearch,
    }));
  };

  onSearch = (values) => {
    const params = {};
    Object.keys(values).forEach(key => {
      if (values[key] || values[key] === 0) {
        if (key === 'created_at') {
          if (Array.isArray(values[key]) && values[key].length === 2) {
            params.created_at = values[key].map(item => item.format('YYYY-MM-DD'));
          }
        } else {
          params[key] = `${values[key]}`.trim();
        }
      }
    });
    if (this.props.handleSearch) {
      this.props.handleSearch(params);
    }
  };

  clearForm = () => {
    const {searchParams, handleSearch} = this.props;
    if (searchParams && Object.keys(searchParams).length > 0 && handleSearch) {
      handleSearch({
        role_id: 0,
      });
    }
    this.formEl.current.resetFields();
  };

  render() {
    const {searchParams, handleAdd, hideRoles} = this.props;
    return (
      <Form
        ref={this.formEl}
        className="search-form common-form"
        onFinish={this.onSearch}
        initialValues={{...searchParams, role_id: 0}}
      >
        <Row {...rowProps}>
          {
            !hideRoles &&
            <Col xs={{span: 12}} md={{span: 3}}>
              <Form.Item
                name="role_id"
                label="Vai trò"
              >
                <Select>
                  {
                    RoleOptions.map((item, index) => (
                      <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
          }
          <Col xs={{span: 12}} md={{span: 4}}>
            <Form.Item
              name="username"
              label="Tên đăng nhập"
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 12}} md={{span: 4}}>
            <Form.Item
              name="name"
              label="Họ tên"
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 12}} md={{span: 4}}>
            <Form.Item
              name="email"
              label="Email"
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 12}} md={{span: 4}}>
            <Form.Item
              name="phone"
              label="Số điện thoại"
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 12}} md={{span: 5}} className="action-buttons">
            <Form.Item label="Thao tác" className="hidden-label hidden-label-sm">
              <Button type="primary" htmlType="submit" icon={<SearchOutlined/>}>
                Tìm kiếm
              </Button>
              <Button
                icon={<ClearOutlined/>}
                onClick={this.clearForm}
                title="Xóa"
              />
              <Button
                type="primary"
                icon={<PlusOutlined/>}
                onClick={handleAdd}
                title="Thêm"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

UserSearch.propTypes = {
  searchParams: PropTypes.object,
  handleSearch: PropTypes.func,
  handleAdd: PropTypes.func,
  hideRoles: PropTypes.bool,
};

export default UserSearch;
