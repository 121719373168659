import Requester from '../common/network/Requester';

const API_URL = `${process.env.REACT_APP_PARSER_SERVICE_API_URL}`;


export const getInvoiceTemplatesAPI = () => {
  return Requester.get(`${API_URL}/templates`);
};

export const addInvoiceTemplateAPI = (data) => {
  return Requester.post(`${API_URL}/templates`, data);
};

export const deleteInvoiceTemplateAPI = (id) => {
  return Requester.delete(`${API_URL}/templates/${id}`);
};

export const testInvoiceTemplateAPI = (data) => {
  return Requester.post(`${API_URL}/templates/test`, data);
};

export const updateInvoiceTemplateAPI = (data) => {
  return Requester.put(`${API_URL}/templates`, data);
};

export const getInvoicePropertiesAPI = () => {
  return Requester.get(`${API_URL}/templates/properties`);
};
