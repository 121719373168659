import Requester from '../../common/network/Requester';
const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/manage`;

export const getActivateCodesAPI = (params) => {
  return Requester.get(`${API_URL}/activate/codes`, params);
};

export const createActivateCodeAPI = (data) => {
  return Requester.post(`${API_URL}/activate/codes`, data);
};

export const deleteActivateCodeAPI = (id) => {
  return Requester.delete(`${API_URL}/activate/codes/${id}`);
};

export const activateActivateCodeAPI = (data) => {
  return Requester.post(`${API_URL}/activate`, data);
};
