import React from 'react';
import {Col, Form, Input, Row} from 'antd';
import WarningInput from '../../../../common/components/Form/WarningInput';

class BuyerInfo extends React.PureComponent {
  render() {
    return (
      <div className="form-section">
        <h3>Thông tin người mua</h3>
        <Row gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}>
          <Col xs={{span: 24}} md={{span: 24}} lg={{span: 24}}>
            <Form.Item
              label="Người mua"
              name="buyer_name"
              rules={[{
                required: true,
                message: 'Vui lòng nhập tên người mua!'
              }]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 8}} lg={{span: 8}}>
            <Form.Item
              name="__auto_buyer_tax_code"
              className="d-none"
            >
              <WarningInput/>
            </Form.Item>
            <Form.Item
              label="MST người mua"
              name="buyer_tax_code"
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} md={{span: 16}} lg={{span: 16}}>
            <Form.Item
              label="Địa chỉ người mua"
              name="buyer_address"
            >
              <Input/>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BuyerInfo;
