import axios from 'axios';
import {onRequest, onRequestError, onResponse, onResponseError} from './DefaultImplementation';

class HTTPClient {
  constructor(options = {}) {
    this.axiosInstance = axios.create();
    this.options = {
      onRequest: onRequest,
      onRequestError: onRequestError,
      onResponse: onResponse,
      onResponseError: onResponseError,
      ...options,
    };
    this.axiosInstance.interceptors.request.use(this.options.onRequest, this.options.onRequestError);
    this.axiosInstance.interceptors.response.use(this.options.onResponse, this.options.onResponseError);
  }

  /**
   * Make a HTTP request
   * @param config
   * @return {Promise<AxiosResponse<T>>}
   */
  request = (config = {}) => {
    return this.axiosInstance.request(config);
  };

  /**
   * Make GET request
   * @param url
   * @param params
   * @param config
   * @return {Promise<AxiosResponse<T>>}
   */
  get = (url, params = null, config = {}) => {
    return this.request({
      method: 'get',
      url,
      params,
      ...config,
    });
  };

  /**
   * Make POST request
   * @param url
   * @param data
   * @param config
   * @return {Promise<AxiosResponse<T>>}
   */
  post = (url, data = null, config = {}) => {
    return this.request({
      method: 'post',
      url,
      data,
      ...config,
    });
  };

  /**
   * Make PUT request
   * @param url
   * @param data
   * @param config
   * @return {Promise<AxiosResponse<T>>}
   */
  put = (url, data = null, config = {}) => {
    return this.request({
      method: 'put',
      url,
      data,
      ...config,
    });
  };

  /**
   * Make DELETE request
   * @param url
   * @param config
   * @return {Promise<AxiosResponse<T>>}
   */
  delete = (url, config = {}) => {
    return this.request({
      method: 'delete',
      url,
      ...config,
    });
  };
}

export default HTTPClient;
