import React, {Fragment} from 'react';
import moment from 'moment';
import {CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled} from '@ant-design/icons';
import {formatDate} from '../../../../common/helpers';

const CheckSignedDate = ({issuedDate, signedDate, rawSignedDate, cert}) => {
  if (!signedDate) {
    return (
      <div className="check-invoice-item">
        <label className="check-item-label">Ngày ký hóa đơn:</label>
        <div className="result">
          <ExclamationCircleFilled className="text-gray"/> Chưa nhập ngày ký hóa đơn
        </div>
      </div>
    );
  }
  const isMatchIssuedDate = issuedDate && signedDate && issuedDate === signedDate;
  let isSignedBeforeIssue = false;
  if (!isMatchIssuedDate && issuedDate) {
    isSignedBeforeIssue = moment(signedDate).isBefore(issuedDate, 'day');
  }
  let isValidDate = undefined;
  let validFrom = undefined;
  let validTo = undefined;
  if (cert?.validity && signedDate) {
    validFrom = moment(cert.validity.notBefore);
    validTo = moment(cert.validity.notAfter);
    if (rawSignedDate) {
      isValidDate = moment(rawSignedDate).isBetween(validFrom, validTo, undefined, '[]');
    } else {
      isValidDate = moment(signedDate).isBetween(validFrom, validTo, 'day', '[]');
    }
  }
  return (
    <div className="check-invoice-item">
      <label className="check-item-label">Ngày ký hóa đơn:</label>
      {
        isValidDate !== undefined &&
        <div className="result">
          {
            isValidDate === true &&
            <Fragment>
              <CheckCircleFilled className="text-green"/> Ngày ký hóa đơn hợp lệ (tại thời điểm ký hóa đơn chữ
              ký số còn hiệu lực)
            </Fragment>
          }
          {
            isValidDate === false &&
            <Fragment>
              <CloseCircleFilled className="text-red"/> {!rawSignedDate ? 'Ngày' : 'Thời điểm'} ký hóa đơn không hợp lệ:
              {
                !rawSignedDate &&
                <div className="explain">
                  <span className="font-500">Ngày ký</span>: {formatDate(signedDate)}<br/>
                  <span className="font-500">Chữ ký số</span>: hiệu lực
                  từ {validFrom.format('DD/MM/YYYY')} đến {validTo.format('DD/MM/YYYY')}
                </div>
              }
              {
                rawSignedDate &&
                <div className="explain">
                  <span className="font-500">Ký lúc</span>: {formatDate(rawSignedDate, 'DD/MM/YYYY HH:mm:ss')}<br/>
                  <span className="font-500">Chữ ký số</span>: hiệu lực
                  từ {validFrom.format('DD/MM/YYYY HH:mm:ss')} đến {validTo.format('DD/MM/YYYY HH:mm:ss')}
                </div>
              }
            </Fragment>
          }
        </div>
      }
      <div className="result">
        {
          isMatchIssuedDate &&
          <Fragment><CheckCircleFilled className="text-green"/> Ngày ký hóa đơn trùng ngày lập ({formatDate(signedDate)})
          </Fragment>
        }
        {
          !isMatchIssuedDate && isSignedBeforeIssue &&
          <Fragment>
            <CloseCircleFilled className="text-red"/> Ngày ký hóa đơn trước ngày lập:
            <div className="explain">
              <span className="font-500">Ngày lập</span>: {formatDate(issuedDate)}<br/>
              <span className="font-500">Ngày ký</span>: {formatDate(signedDate)}
            </div>
          </Fragment>
        }
        {
          !isMatchIssuedDate && !isSignedBeforeIssue &&
          <Fragment>
            <ExclamationCircleFilled className="text-orange"/> Ngày ký hóa đơn không trùng ngày lập:
            <div className="explain">
              <span className="font-500">Ngày lập</span>: {formatDate(issuedDate)}<br/>
              <span className="font-500">Ngày ký</span>: {formatDate(signedDate)}
            </div>
          </Fragment>
        }
      </div>
    </div>
  );
};

export default React.memo(CheckSignedDate);
