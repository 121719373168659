import React from 'react';
import PropTypes from 'prop-types';
import XMLNode from './XMLNode';
import {Button, Modal} from 'antd';
import Broadcaster from '../../helpers/broadcaster';
import './XMLViewModal.scss';
import __get from 'lodash/get';

class XMLViewModal extends React.PureComponent {
  state = {
    isOpen: false,
    selectedPath: '',
    productKey: '',
    isProductProperty: false,
  };

  componentDidMount() {
    Broadcaster.on('OPEN_SELECT_XML_NODE', this.handleOpen);
  }

  handleOpen = (data) => {
    if (data?.resolve) {
      this.resolve = data.resolve;
    }
    this.setState({
      isProductProperty: !!data.isProductProperty,
      productKey: data.productKey,
      isOpen: true,
      selectedPath: '',
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleSelect = (path) => (event) => {
    event.stopPropagation();
    this.setState({
      selectedPath: path,
    });
  };

  handleSubmit = () => {
    if (this.resolve) {
      this.resolve(this.state.selectedPath);
    }
    this.handleClose();
  };

  getRoot = () => {
    const {jsonTree} = this.props;
    const {isProductProperty, productKey} = this.state;
    if (isProductProperty && productKey) {
      return __get(jsonTree, productKey);
    }
    return jsonTree;
  };

  render() {
    const {isOpen, selectedPath} = this.state;
    const root = this.getRoot();
    return (
      <Modal
        title="XML View"
        className="xml-view-modal"
        visible={isOpen}
        width={900}
        footer={[
          <Button key="back" onClick={this.handleClose}>
            Hủy bỏ
          </Button>,
          <Button
            key="submit"
            disabled={!selectedPath}
            type="primary"
            onClick={this.handleSubmit}
          >
            OK
          </Button>,
        ]}
      >
        <XMLNode
          name=""
          path=""
          selectedPath={selectedPath}
          node={root}
          handleSelect={this.handleSelect}
        />
      </Modal>
    );
  }
}

XMLViewModal.propTypes = {
  jsonTree: PropTypes.object.isRequired,
};

export default XMLViewModal;
