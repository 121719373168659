import {GET_USER_SETTINGS_SUCCESS_AC} from '../../actions/user';
import createReducer from '../createReducer';

const initialState = {
  isFetched: false,
  list: [],
  keyValue: {},
};

const handler = {
  [GET_USER_SETTINGS_SUCCESS_AC]: (state, action) => {
    if (action.settings) {
      const keyValue = (action.settings || []).reduce(function (result, item) {
        result[item.alias] = item.value;
        return result;
      }, {});
      return {
        ...state,
        isFetched: true,
        list: action.settings || [],
        keyValue,
      }
    }
    return state;
  },
};

export default createReducer(initialState, handler);
