import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Routes from './Routes';

class App extends React.PureComponent {
  render() {
    const {isAuthenticated} = this.props;
    return (
      <Fragment>
        <Routes isAuthenticated={isAuthenticated}/>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.auth.isAuthenticated,
});

export default withRouter(connect(mapStateToProps)(App));
