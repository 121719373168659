import React from 'react';
import {connect} from 'react-redux';
import {Select} from 'antd';
import {selectCompanyAC} from '../../../redux/actions/user';
import './SelectTaxCode.scss';

const SelectTaxCode = ({companies, handleChange}) => {
  const {isLoaded, list, selectedCompany} = companies;
  if (!list.length || list.length === 1) {
    return null;
  }
  return (
    <Select
      className="select-tax-code"
      bordered={false}
      value={selectedCompany?.id}
      disabled={!isLoaded}
      loading={!isLoaded}
      placeholder="Chọn mã số thuế làm việc"
      onChange={handleChange}
    >
      {
        list.map((item, index) => (
          <Select.Option value={item.id} key={index}>
            <div className="select-tax-code-item">
              <div className="com-name">{item.name}</div>
              <div className="com-tax-code">{item.tax_code}</div>
            </div>
          </Select.Option>
        ))
      }
    </Select>
  );
};

const mapStateToProps = (state) => ({
  companies: state.user.companies,
});

const mapDispatchToProps = (dispatch) => ({
  handleChange: (value) => {
    dispatch(selectCompanyAC(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectTaxCode);
