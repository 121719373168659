import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {message, Spin} from 'antd';
import {CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled} from '@ant-design/icons';
import {checkXMLSignatureAPI} from '../../../../api/utils';
import {getErrorMessage} from '../../../../common/helpers';

const CHECK_RESULT = {
  VALID: 1,
  UNKNOWN: 0,
  INVALID: -1,
};

class CheckXMLSignature extends React.PureComponent {
  state = {
    isSubmitting: false,
    result: null,
  };

  componentDidMount() {
    if (this.props.savedResult) {
      this.setState({
        result: this.props.savedResult,
      });
    } else {
      this.check();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.xmlFile !== this.props.xmlFile) {
      this.check();
    }
    if (prevState.result !== this.state.result) {
      if (this.props.setCheckResult) {
        this.props.setCheckResult({
          xml_signature: this.state.result,
        });
      }
    }
  }

  check = () => {
    const {xmlFile} = this.props;
    if (!xmlFile) {
      return;
    }
    this.setState({
      isSubmitting: true,
    });
    checkXMLSignatureAPI({
      download_token: xmlFile.download_token,
    }).then((res) => {
      this.setState({
        isSubmitting: false,
        result: res?.data,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
        result: CHECK_RESULT.UNKNOWN,
      });
    });
  };

  render() {
    const {isSubmitting, result} = this.state;
    return (
      <div className="check-invoice-item">
        <label className="check-item-label">Tình trạng file hóa đơn: </label>
        {
          isSubmitting && <Spin/>
        }
        {
          !isSubmitting &&
          <Fragment>
            <div className="result">
              {
                result === CHECK_RESULT.VALID &&
                <Fragment><CheckCircleFilled className="text-green"/> Cấu trúc file XML còn nguyên vẹn (chưa bị chỉnh
                  sửa sau khi ký điện tử)</Fragment>
              }
              {
                result === CHECK_RESULT.INVALID &&
                <Fragment><CloseCircleFilled className="text-red"/> Cấu trúc file XML không còn nguyên vẹn (có thể file
                  đã bị chỉnh sửa sau khi ký điện tử)</Fragment>
              }
              {
                result !== CHECK_RESULT.VALID && result !== CHECK_RESULT.INVALID &&
                <Fragment><ExclamationCircleFilled className="text-gray"/> Không kiểm tra được</Fragment>
              }
            </div>
          </Fragment>
        }
      </div>
    );
  }
}

CheckXMLSignature.propTypes = {
  xmlFile: PropTypes.object,
};

export default CheckXMLSignature;
