const InvoiceType = {
  Purchase: 0,
  Sale: 1,
};

const PaymentStatus = {
  Paid: 0,
  Unpaid: 1,
};

const ItemType = {
  Product: 0,
  Service: 1,
};

const InvoiceStatus = {
  HoaDonGoc: 0,
  HoaDonDaXoaBo: 1,
  HoaDonThayThe: 2,
};

const ReviewStatus = {
  NotReviewed: 0,
  Reviewed: 1,
};

const InvoiceConstants = {
  InvoiceType,
  PaymentStatus,
  ItemType,
  InvoiceStatus,
  ReviewStatus,
};

export default InvoiceConstants;
