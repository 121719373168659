import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Form, message, Modal, Select, Slider} from 'antd';
import {getUserDetailAPI, updateUserAPI} from '../../../../api/manage/users';
import {getErrorMessage} from '../../../../common/helpers/index';
import ROLES from '../../../../common/constants/roles';
import {USER_STATUS} from '../../../../common/constants/common';

const RoleOptions = [ROLES.NORMAL_USER, ROLES.RESELLER, ROLES.ADMIN, ROLES.SUPER_ADMIN];

const StatusOptions = Object.values(USER_STATUS);

const sliderMask = {
  0: '0',
  20: '20tr',
  40: '40tr',
  60: '60tr',
  80: '80tr',
  100: '100tr',
};

class UpdateUserModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isSubmitting: false,
      isFetching: false,
      user: null,
      renderKey: 0,
      roleOptions: [],
      formData: {},
    };
    this.defaultState = {...this.state};
    this.form = React.createRef();
  }

  handleOpen = (userId) => {
    const {userRoleId} = this.props;
    const roleOptions = RoleOptions.filter(item => item.id < userRoleId);
    this.setState({
      roleOptions,
    });
    this.setState({
      ...this.defaultState,
      isFetching: true,
      isOpen: true,
      roleOptions,
      renderKey: new Date().getTime(),
    });
    this.getUser(userId);
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  getUser(userId) {
    getUserDetailAPI(userId).then(res => {
      const user = res.data;
      let formData = {};
      if (user) {
        if (!user.debt_limit) {
          user.debt_limit = 0;
        }
        user.debt_limit = Math.floor(user.debt_limit / 1000000);
        formData = JSON.parse(JSON.stringify(user));
      }
      this.setState({
        user: res.data,
        isFetching: false,
        formData,
      });
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        user: null,
        isFetching: false,
      });
    });
  }

  onValuesChange = (changedFields, allFields) => {
    this.setState({
      formData: allFields,
    });
  };

  handleSubmit = (values) => {
    const {onSuccess} = this.props;
    const {user} = this.state;
    this.setState({
      isSubmitting: true,
    });
    updateUserAPI(user.id, {
      ...values,
      debt_limit: values.debt_limit * 1000000,
    }).then(() => {
      this.setState({
        isSubmitting: false,
      });
      message.success('Đã cập nhật!');
      this.handleClose();
      if (onSuccess) {
        onSuccess();
      }
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  submit = () => {
    this.form.current.submit();
  };

  render() {
    const {isOpen, renderKey, user, roleOptions, isSubmitting, formData} = this.state;
    return (
      <Modal
        title="Cập nhật người dùng"
        visible={isOpen}
        width={600}
        okText="Cập nhật"
        cancelText="Hủy bỏ"
        maskClosable={false}
        onOk={this.submit}
        onCancel={this.handleClose}
      >
        {
          user &&
          <Form
            className="upsert-storage-form common-form"
            ref={this.form}
            key={renderKey}
            initialValues={user}
            onFinish={this.handleSubmit}
            onValuesChange={this.onValuesChange}
          >
            <Form.Item
              name="status"
              label="Trạng thái"
              rules={[{required: true, message: 'Vui lòng chọn một giá trị'}]}
            >
              <Select disabled={isSubmitting}>
                {
                  StatusOptions.map((item) => (
                    <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
            {
              user.role_id >= ROLES.NORMAL_USER.id &&
              (
                <Form.Item
                  name="role_id"
                  label="Vai trò"
                  rules={[{required: true, message: 'Vui lòng chọn một giá trị'}]}
                >
                  <Select disabled={isSubmitting}>
                    {
                      roleOptions.map((item) => (
                        <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              )
            }
            {
              formData.role_id === ROLES.RESELLER.id &&
              <Fragment>
                <Form.Item
                  label="Giới hạn nợ"
                  name="debt_limit"
                  rules={[{
                    required: true,
                    message: 'Vui lòng nhập giới hạn nợ!'
                  }]}
                >
                  <Slider
                    marks={sliderMask}
                    step={5}
                  />
                </Form.Item>
              </Fragment>
            }
          </Form>
        }
      </Modal>
    );
  }
}

UpdateUserModal.propTypes = {
  onSuccess: PropTypes.func,
  userRoleId: PropTypes.number,
};

export default UpdateUserModal;
