import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input, message, Modal, Select} from 'antd';
import {copyToClipboard, getErrorMessage} from '../../../common/helpers/index';
import {LICENSE_TYPE} from '../../../common/constants/common';
import {createLicenseAPI} from '../../../api/manage/licenses';

class CreateLicenseModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isSubmitting: false,
      isFetching: false,
      renderKey: 0,
      formData: {},
      licenseKey: null,
    };
    this.defaultState = {...this.state};
    this.form = React.createRef();
  }

  handleOpen = () => {
    this.setState({
      ...this.defaultState,
      isOpen: true,
      renderKey: new Date().getTime(),
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  onValuesChange = (changedFields, allFields) => {
    this.setState({
      formData: allFields,
    });
  };

  handleSubmit = (values) => {
    const {onSuccess} = this.props;
    this.setState({
      isSubmitting: true,
    });
    createLicenseAPI(values).then((res) => {
      this.setState({
        isSubmitting: false,
        licenseKey: res?.data?.license_key,
      });
      message.success('Thành công! Đã copy license key vào clipboard.');
      copyToClipboard(res?.data?.license_key);
      this.handleClose();
      if (onSuccess) {
        onSuccess();
      }
    }).catch(error => {
      message.error(getErrorMessage(error));
      this.setState({
        isSubmitting: false,
      });
    });
  };

  submit = () => {
    this.form.current.submit();
  };

  render() {
    const {isOpen, renderKey, isSubmitting, formData} = this.state;
    return (
      <Modal
        title="Tạo mã kích hoạt phần mềm"
        visible={isOpen}
        width={600}
        okText="Tạo mã"
        cancelText="Hủy bỏ"
        maskClosable={false}
        onOk={this.submit}
        onCancel={this.handleClose}
      >
        <Form
          className="common-form"
          ref={this.form}
          key={renderKey}
          onFinish={this.handleSubmit}
          onValuesChange={this.onValuesChange}
        >
          <Form.Item
            label="Email"
            name="email"
          >
            <Input disabled={isSubmitting}/>
          </Form.Item>
          <Form.Item
            name="type"
            label="Loại mã"
            rules={[{required: true, message: 'Vui lòng chọn một giá trị'}]}
          >
            <Select disabled={isSubmitting}>
              <Select.Option value={LICENSE_TYPE.Normal.id}>{LICENSE_TYPE.Normal.name}</Select.Option>
              <Select.Option value={LICENSE_TYPE.Trial.id}>{LICENSE_TYPE.Trial.name}</Select.Option>
            </Select>
          </Form.Item>
          {
            formData.type === LICENSE_TYPE.Normal.id &&
            <Form.Item
              name="period"
              label="Thời gian"
              rules={[{required: true, message: 'Vui lòng chọn một giá trị'}]}
            >
              <Select disabled={isSubmitting}>
                <Select.Option value={1}>1 năm</Select.Option>
                <Select.Option value={2}>2 năm</Select.Option>
                <Select.Option value={3}>3 năm</Select.Option>
                <Select.Option value={4}>4 năm</Select.Option>
                <Select.Option value={5}>5 năm</Select.Option>
              </Select>
            </Form.Item>
          }
          <Form.Item
            label="Ghi chú"
            name="note"
          >
            <Input.TextArea disabled={isSubmitting}/>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

CreateLicenseModal.propTypes = {
  onSuccess: PropTypes.func,
};

export default CreateLicenseModal;
