import Requester from '../../common/network/Requester';
const API_URL = `${process.env.REACT_APP_MAIN_SERVICE_API_URL}/manage`;

export const getLicensesAPI = (params) => {
  return Requester.get(`${API_URL}/licenses`, params);
};

export const createLicenseAPI = (data) => {
  return Requester.post(`${API_URL}/licenses`, data);
};

export const deleteLicenseAPI = (id) => {
  return Requester.delete(`${API_URL}/licenses/${id}`);
};
