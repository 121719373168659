import createReducer from '../createReducer';
import {SET_LANGUAGE_AC} from '../../actions';

const initialState = {
  language: 'VI',
  settings: {},
};

const handlers = {
  [SET_LANGUAGE_AC]: (state, action) => {
    return {
      ...state,
      language: action.language
    };
  },
};

export default createReducer(initialState, handlers);
